import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { palette } from "../../theme";
import { LoanSliders } from "./LoanSliders";
import { LoanTermSelect } from "./LoanTermSelect";
import { PieChartResult } from "./PieChartResult";

export interface LoanData {
  projectValue: number;
  downPayment: number;
  loanAmount: number;
  loanTerm: number;
  interestRate: number;
  averageUtilityBill: number;
}

export type SetLoanData = React.Dispatch<React.SetStateAction<LoanData>>;

export const LoanCalculator = (props: { projectTotalEstimate: number }) => {
  const { projectTotalEstimate } = props;
  const [loanData, setLoanData] = useState({
    projectValue: projectTotalEstimate,
    downPayment: projectTotalEstimate * 0.2,
    loanAmount: projectTotalEstimate * 0.8,
    loanTerm: 30,
    interestRate: 5,
  } as LoanData);
  const [averageUtilityBill, setAverageUtilityBill] = useState(300);

  return (
    <Box
      sx={{
        backgroundColor: palette.backgroundGray,
        paddingX: 4,
        borderRadius: "30px",
        overflowY: "scroll",
        maxHeight: { sm: "calc(100vh - 150px)", xs: "100%" },
        maxWidth: "1500px",
      }}
    >
      <Typography variant="h4">Electrification Loan Calculator</Typography>
      <Typography variant="body1">
        This calculator is for simulation purposes only. Customers often use
        many sources of credit including home equity lines of credit and home
        equity loans.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <Box sx={{ width: { md: "50%", xs: "100%" } }}>
          <LoanSliders loanData={loanData} setLoanData={setLoanData} />
          <LoanTermSelect loanData={loanData} setLoanData={setLoanData} />
          <Box sx={{ marginY: 2 }}>
            <Typography variant="subtitle2">
              Average Monthly Utility Bill
            </Typography>
            <TextField
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={averageUtilityBill}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  backgroundColor: palette.backgroundGray,
                },
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAverageUtilityBill(Number(e.target.value));
              }}
            />
          </Box>
        </Box>
        <Box sx={{ width: { md: "50%", xs: "100%" } }}>
          <PieChartResult
            loanData={loanData}
            averageUtilityBill={averageUtilityBill}
          />
        </Box>
      </Box>
    </Box>
  );
};
