import React from "react";
import {
  FormGroupProps,
  styled,
  FormGroup as MUIFormGroup,
  Typography,
} from "@mui/material";
import { palette } from "../theme";

const CustomFormGroup = styled(MUIFormGroup)({
  backgroundColor: palette.white,
  display: "flex",
  flexDirection: "column",
  borderRadius: 20,
  padding: 16,
  height: "fit-content",
});

interface CustomFormGroupProps extends FormGroupProps {
  formLabel: string;
}

export function FormGroup(props: CustomFormGroupProps) {
  const { formLabel, children, ...otherProps } = props;
  return (
    <CustomFormGroup {...otherProps}>
      <Typography
        variant="h4"
        sx={{
          marginBottom: 1,
          color: palette.darkBlue,
        }}
      >
        {formLabel}
      </Typography>
      {children}
    </CustomFormGroup>
  );
}
