/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { palette } from "../../theme";
import { startCase } from "lodash";
import { useReactiveVar } from "@apollo/client";
import { addressVar } from "../../state/ReactiveVars";
import {
  BuildingTypeOptions,
  StoveFuelTypeOptions,
} from "../propertyProfileForm/fieldInputOptions";
import { HomeSummaryLayoutDataViewProps } from "./HomeSummary";
import EditIcon from "../../assets/edit_icon.svg";
import { useNavigate } from "react-router-dom";
import { paths } from "../../paths";

const HomeSummaryHr = () => {
  return (
    <Box>
      <hr
        css={{
          marginTop: 30,
          marginBottom: 30,
          width: 100,
          borderBottom: "none",
        }}
      />
    </Box>
  );
};

export function HomeSummarySidebar(props: HomeSummaryLayoutDataViewProps) {
  const navigate = useNavigate();
  const { buildingProfile } = props;
  const address = useReactiveVar(addressVar).address;
  const addressText = `${address?.addressLine1}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
  const {
    bedroomCount,
    bathroomCount,
    buildingType,
    conditionedFloorAreaSqFt,
    floorsAboveGrade,
    hasEvCharger,
    hasSolar,
    hasStorageBattery,
    homeOwnerAssessedLeakage = 0.5,
    homeOwnerAssessedTemp = 0.5,
    stoveTopType,
    yearBuilt,
    yearBuiltEffective,
  } = buildingProfile;

  var draftRating = "";

  switch (true) {
    case (homeOwnerAssessedLeakage || 0.5) < 0.5:
      draftRating = "Very Drafty";
      break;
    case (homeOwnerAssessedLeakage || 0.5) < 0.75:
      draftRating = "Some Draft";
      break;
    default:
      draftRating = "Sealed Up Tight";
      break;
  }

  var tempRating = "";
  switch (true) {
    case (homeOwnerAssessedTemp || 0.5) < 0.5:
      tempRating = "Varying Temperatures";
      break;
    case (homeOwnerAssessedTemp || 0.5) < 0.75:
      tempRating = "Some Variation";
      break;
    default:
      tempRating = "Same Temperature";
      break;
  }

  return (
    <Box
      sx={{
        color: palette.white,
        padding: { lg: 2, md: 2, s: 0, xs: 0 },
        display: "flex",
        flexDirection: "column",
        textAlign: { md: "start", sm: "center", xs: "center" },
        alignItems: {
          md: "flex-start",
          sm: "center",
          xs: "center",
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "Exo",
          fontSize: "20px",
          fontWeight: 600,
          marginTop: 5,
        }}
      >
        Your Current Home Data
        <span
          onClick={() => {
            navigate(paths.getStarted().yourHome());
          }}
          css={{ marginLeft: 10, ":hover": { cursor: "pointer" } }}
        >
          <img
            src={EditIcon}
            alt="Icon of a pencil and clipboard for editing home information"
          />
        </span>
      </Typography>
      <HomeSummaryHr />
      {addressText && (
        <Typography sx={{ fontWeight: 600 }} variant="body1">
          {addressText}
        </Typography>
      )}
      <HomeSummaryHr />
      <Typography variant="body1">
        {bedroomCount} Beds • {bathroomCount} Baths •{" "}
        {conditionedFloorAreaSqFt &&
          new Intl.NumberFormat().format(conditionedFloorAreaSqFt)}{" "}
        Sq. Ft
      </Typography>
      <Typography sx={{ marginY: 1 }} variant="body1">
        {buildingType &&
          `${floorsAboveGrade} Story ${startCase(
            BuildingTypeOptions[buildingType]
          )} Built in ${yearBuilt}`}
      </Typography>
      {!!yearBuiltEffective && yearBuilt !== yearBuiltEffective && (
        <Typography variant="body1">
          Remodeled in {yearBuiltEffective}
        </Typography>
      )}
      <HomeSummaryHr />
      <Typography variant="body1">
        {hasSolar ? "Has Solar Panels" : "No Solar Panels"}
      </Typography>
      <Typography sx={{ marginY: 1 }} variant="body1">
        {hasStorageBattery ? "Has Storage Batteries" : "No Storage Batteries"}
      </Typography>
      <Typography variant="body1">
        {hasEvCharger
          ? "Has Electric Vehicle Charger"
          : "No Electric Vehicle Charger"}
      </Typography>
      <HomeSummaryHr />
      {stoveTopType && (
        <Typography variant="body1">
          {startCase(StoveFuelTypeOptions[stoveTopType])} Stove
        </Typography>
      )}
      <Typography sx={{ marginY: 1 }} variant="body1">
        {draftRating}
      </Typography>
      <Typography variant="body1">{tempRating}</Typography>
    </Box>
  );
}
