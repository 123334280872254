import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  CustomerPortalDocument,
  CustomerPortalQuery,
  ProposalQuery,
} from "../generated/graphql";
import { paths } from "../paths";
import { addressVar } from "../state/ReactiveVars";
import { CustomerPortalLayout } from "./CustomerPortalLayout";
import { Box, Skeleton, Typography } from "@mui/material";
import CustomerTimeline from "./CustomerTimeline";
import { Card } from "../components/Card";
import OilBarrel from "../assets/oil_barrel.svg";

export function CustomerPortalView(props: { proposal?: ProposalQuery }) {
  const proposalData = props.proposal;
  const { customerPortalToken } = useParams();
  const { data, loading } = useQuery<CustomerPortalQuery>(
    CustomerPortalDocument,
    {
      variables: { input: { token: customerPortalToken } },
    }
  );
  const navigate = useNavigate();

  if (!loading && !data) {
    navigate(paths.getStarted().root());
    return <></>;
  }
  if (loading) {
    return <CustomerPortalLoadingView />;
  }
  if (data?.customerPortal?.visit.customer) {
    addressVar({
      address: data.customerPortal.visit.address,
      addressId: "",
    });
    return (
      <CustomerPortalDataView customerPortal={data} proposal={proposalData} />
    );
  }
}
export function CustomerPortalDataView(props: {
  proposal?: ProposalQuery;
  customerPortal: CustomerPortalQuery;
}) {
  const { proposal } = props;
  const co2Reduction = (
    proposal?.proposal?.selectedDesign?.retoolDesignEnergyMeasures?.baseline
      ?.co2Tons -
    proposal?.proposal?.selectedDesign?.retoolDesignEnergyMeasures?.upgrade
      ?.co2Tons
  ).toFixed(2);
  // 1 barrel of oil = 0.43 tons of CO2
  const barrelsOfOil = (parseFloat(co2Reduction) / 0.43).toFixed(2);

  return (
    <Box>
      <CustomerPortalLayout>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box>
            <Card
              sx={{
                marginBottom: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ width: "60%" }}>
                <Typography variant="h5">
                  {`With your new HVAC and solar system, you’ll soon be saving ${co2Reduction} tons of CO2 a year.`}
                </Typography>
                <Typography variant="h5" sx={{ marginTop: 2 }}>
                  {`That’s an equivalent of ${barrelsOfOil} barrels of oil!`}
                </Typography>
              </Box>
              <img
                style={{ height: 40, marginRight: 10 }}
                src={OilBarrel}
                alt={"Icon representing a black oil barrel"}
              />
            </Card>
            <Card>
              <Typography>
                Helio Home will handle permits, ordering equipment, coordinating
                installation, and filing for rebates.
              </Typography>
              <CustomerTimeline />
            </Card>
          </Box>
        </Box>
      </CustomerPortalLayout>
    </Box>
  );
}
export function CustomerPortalLoadingView() {
  return (
    <CustomerPortalLayout>
      <Card
        sx={{
          padding: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
      </Card>
    </CustomerPortalLayout>
  );
}
