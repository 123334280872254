/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { Card } from "../components/Card";
import { Document, pdfjs, Page } from "react-pdf";
import { ChevronLeft, ChevronRight, Download } from "@mui/icons-material";
import { Box, IconButton, Link, Typography } from "@mui/material";
import { palette } from "../theme";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export function ProposalDocumentCard(props: { url: string }) {
  const { url } = props;
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: any }): void {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Card
      sx={{
        padding: 2,
        height: "100%",
        width: { lg: "fit-content", md: "100%", sm: "100%", xs: "100%" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IconButton
        sx={{
          alignSelf: "flex-end",
          marginBottom: 1,
        }}
      >
        <Link
          href={url}
          download
          target="_blank"
          css={{ width: 24, height: 24, color: palette.blueOpaque }}
        >
          <Download />
        </Link>
      </IconButton>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<div css={{ width: 594, height: 841 }}></div>}
        css={{
          borderRadius: 20,
          borderColor: palette.grey1,
          borderWidth: 1,
          borderStyle: "solid",
          overflow: "scroll",
          maxWidth: "100%",
          "& canvas": {
            maxWidth: "100%",
            height: "auto !important",
          },
        }}
      >
        <Page
          pageNumber={pageNumber}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box sx={{ flexDirection: "column" }}>
            <IconButton
              type="button"
              disabled={pageNumber <= 1}
              onClick={previousPage}
              sx={{ color: palette.starfishOrange, marginX: 2 }}
            >
              <ChevronLeft />
            </IconButton>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Previous
            </Typography>
          </Box>
          <Box sx={{ flexDirection: "column" }}>
            <IconButton
              type="button"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
              sx={{ color: palette.starfishOrange, marginX: 2 }}
            >
              <ChevronRight />
            </IconButton>
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Next
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="h6"
        color={palette.blueOpaque}
        sx={{ textAlign: "center", marginTop: 2 }}
      >
        Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
      </Typography>
    </Card>
  );
}
