/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import PowerViewHomeIllustration from "../assets/power_view_home_illustration.svg";
import HomeIllustrationSolar from "../assets/home_illustration_solar.svg";
import HomeIllustrationBattery from "../assets/home_illustration_battery.svg";
import HomeIllustrationEvCharger from "../assets/home_illustration_ev_charger.svg";
import ActiveHomeIllustrationSolar from "../assets/home_illustration_solar_active.svg";
import ActiveHomeIllustrationBattery from "../assets/home_illustration_battery_active.svg";
import ActiveHomeIllustrationEvCharger from "../assets/home_illustration_ev_charger_active.svg";

export function PowerViewHomeIllustrationImage(props: {
  activeIcons: { solar: boolean; battery: boolean; evCharger: boolean };
}) {
  const { solar, battery, evCharger } = props.activeIcons;
  return (
    <Box sx={{ position: "relative" }}>
      <img
        alt="Solar icon"
        src={solar ? ActiveHomeIllustrationSolar : HomeIllustrationSolar}
        css={{
          zIndex: 1,
          position: "absolute",
          top: "30%",
          left: "20%",
          width: "20%",
        }}
      />
      <img
        alt="Battery icon"
        src={battery ? ActiveHomeIllustrationBattery : HomeIllustrationBattery}
        css={{
          zIndex: 1,
          position: "absolute",
          top: "70%",
          left: "14%",
          width: "20%",
        }}
      />
      <img
        alt="EV Charger icon"
        src={
          evCharger
            ? ActiveHomeIllustrationEvCharger
            : HomeIllustrationEvCharger
        }
        css={{
          zIndex: 1,
          position: "absolute",
          top: "50%",
          left: "40%",
          width: "20%",
        }}
      />
      <img
        css={{ zIndex: 0, width: "95%" }}
        alt="A home with interactive icons"
        src={PowerViewHomeIllustration}
      />
    </Box>
  );
}
