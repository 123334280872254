import { ComponentStory, DecoratorFn } from "@storybook/react";
import { MemoryRouter, Route, Routes } from "react-router-dom";

export const withRouter: DecoratorFn = (Story: ComponentStory<any>) => {
  return (
    <MemoryRouter>
      <Routes>
        <Route path="/*" element={<Story />} />
      </Routes>
    </MemoryRouter>
  );
};
