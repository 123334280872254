import { makeVar } from "@apollo/client";
import {
  Address,
  AddressInput,
  BuildingProfile,
  EnergyUsage,
  MutationSaveElectrificationEngagementArgs,
  UpgradeEnergyMeasures,
} from "../generated/graphql";
import { PropertyProfileFormValues } from "../pages/propertyProfileForm/PropertyProfileForm";

//type Environment = "development" | "dev" | "staging" | "production";

interface FeatureFlag {
  hideIn: string[];
}
export type UpgradeToggleStateKeys =
  | "heatPumpHvac"
  | "heatPumpWaterHeater"
  | "insulation"
  | "solar"
  | "storageBattery"
  | "vehicleCharger"
  | "homeVentilation"
  | "inductionStove"
  | "electricalServiceUpgrade";

export type UpgradeToggleState = {
  [key in UpgradeToggleStateKeys]: boolean;
};

interface FeatureFlags {
  shouldShowElectrification: boolean;
  hubspotChatBot: FeatureFlag;
}

interface UTMParams {
  utmCampaign: string | null;
  utmContent: string | null;
  utmId: string | null;
  utmMedium: string | null;
  utmSource: string | null;
  utmTerm: string | null;
}

export const validatedBuildingProfileIdVar = makeVar<string | null>(null);
export const visitVar = makeVar<string | null>(null);
export const electrificationEngagementVar = makeVar<string | null>(null);
export const visitFormValuesVar = makeVar<PropertyProfileFormValues | null>(
  null
);
export const addressVar = makeVar<
  Pick<Address, "addressId" | "locationDetails"> & {
    address?: AddressInput;
    buildingProfile?: BuildingProfile;
  }
>({
  addressId: "",
  locationDetails: { currentSalesTerritoryContainsLocation: false },
  address: {},
  buildingProfile: {},
});
export const leadSource = makeVar<string | null>(null);
export const utmParams = makeVar<UTMParams>({
  utmCampaign: null,
  utmTerm: null,
  utmContent: null,
  utmId: null,
  utmMedium: null,
  utmSource: null,
});

export const currentTogglesVar = makeVar<UpgradeToggleState>({
  heatPumpHvac: true,
  heatPumpWaterHeater: true,
  insulation: true,
  solar: true,
  storageBattery: true,
  vehicleCharger: true,
  homeVentilation: true,
  inductionStove: true,
  electricalServiceUpgrade: true,
});

export const featureFlags = makeVar<FeatureFlags>({
  shouldShowElectrification: false,
  hubspotChatBot: { hideIn: ["production", "dev", "development"] },
});

export const energyMeasures = makeVar<EnergyUsage>({
  co2Tons: 0,
  electrifiedEnergyUsageKwh: 0,
  energyEfficiencyImprovement: 0,
  energyUseIntensityScore: 0,
  solarPotential: {
    estimatedSolarProduction: 0,
    priceInCents: 0,
    systemSize: 0,
    totalSolarPriceInCents: 0,
    yearPayback: 0,
    firstYearSavings: 0,
  },
});

export async function setToggleReactiveVar(
  toggleName: keyof UpgradeToggleState,
  saveElectrificationEngagement: (variables: {
    variables: MutationSaveElectrificationEngagementArgs;
  }) => Promise<any>
) {
  const saveElectrificationEngagementInput = {
    electrificationEngagementId: electrificationEngagementVar(),
    [toggleName]: !currentTogglesVar()[toggleName],
  };

  const { data } = await saveElectrificationEngagement({
    variables: { input: saveElectrificationEngagementInput },
  });

  currentTogglesVar({
    ...currentTogglesVar(),
    [toggleName]: data.saveElectrificationEngagement[toggleName],
  });
}

export function changeToggledUpgradeEnergyMeasures(
  upgradeEnergyMeasures: UpgradeEnergyMeasures
) {
  const { yearBuilt, stoveTopType } = visitFormValuesVar()!;
  const {
    vehicleCharger,
    homeVentilation,
    inductionStove,
    heatPumpWaterHeater,
    heatPumpHvac,
  } = currentTogglesVar();
  let upgrade = upgradeEnergyMeasures.baseline;

  if (heatPumpHvac && heatPumpWaterHeater) {
    upgrade = upgradeEnergyMeasures?.heatPumpHvacAndWaterHeaterAndInsulation;
  } else if (heatPumpWaterHeater) {
    upgrade = upgradeEnergyMeasures?.heatPumpWaterHeater;
  } else if (heatPumpHvac) {
    upgrade = upgradeEnergyMeasures?.heatPumpHvac;
  }

  let adjustedElectrifiedEnergyUsageKwh =
    upgrade.electrifiedEnergyUsageKwh || 0;

  if (vehicleCharger) {
    adjustedElectrifiedEnergyUsageKwh += 1667;
  }
  if (homeVentilation && yearBuilt! >= 2000) {
    adjustedElectrifiedEnergyUsageKwh += 40;
  }
  if (homeVentilation && yearBuilt! < 2000) {
    adjustedElectrifiedEnergyUsageKwh += 900;
  }
  if (inductionStove && stoveTopType === "electricity") {
    adjustedElectrifiedEnergyUsageKwh += 403;
  }
  if (inductionStove && stoveTopType !== "electricity") {
    adjustedElectrifiedEnergyUsageKwh += 448;
  }
  energyMeasures({
    ...upgrade,
    electrifiedEnergyUsageKwh: adjustedElectrifiedEnergyUsageKwh,
  });
  return energyMeasures();
}
