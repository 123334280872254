/** @jsxImportSource @emotion/react */
import { Box, Container, Typography } from "@mui/material";
import helioMapPin from "../assets/helio_map_pin.svg";
import solarBackground from "../assets/solar_background.svg";
import { CustomerContactForm } from "../components/forms/CustomerContactForm";
import { Header } from "../components/Header";
import { RoadmapProgressBar } from "../components/RoadmapProgressBar";
import { palette } from "../theme";

export function InAreaContactPage() {
  return (
    <Container
      disableGutters
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        backgroundImage: `url(${solarBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header>
        <Box
          sx={{
            marginY: 3,
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <RoadmapProgressBar />
        </Box>
      </Header>
      <Container
        sx={{
          display: "flex",
          minWidth: "100vw",
          flexDirection: { sm: "row", xs: "column" },
          marginLeft: 0,
        }}
        disableGutters
      >
        <Container
          disableGutters
          sx={{ width: { xl: "50vw", sm: "10vw" }, zIndex: { sm: 2 } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              position: { xl: "absolute", xs: "absolute" },
              marginRight: { sm: -12 },
              marginTop: { xl: 1, sm: 10 },
              right: { xl: "50%", md: "50%", sm: "81%", xs: 5 },
              top: { xl: 130, md: 140, sm: 240, xs: 150 },
              height: { xl: 200, sm: 210, xs: 172 },
            }}
          >
            <img src={helioMapPin} alt="Helio Home logo" />
          </Box>
        </Container>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: {
              sm: "rgba(245,245,245, 1)",
              xs: "rgba(245,245,245, 0.75)",
            },
            borderRadius: { sm: "60px 0px 0px 60px", xs: "0px 60px 60px 0px" },
            height: "fit-content",
            marginTop: { xl: 0, xs: 10 },
            marginLeft: { xs: 0 },
            marginRight: 0,
            paddingLeft: { xl: 16, sm: 16 },
            paddingBottom: 3,
            width: { sm: "50vw", xs: "97vw" },
            minWidth: { xl: "50vw", md: "50vw", sm: "80vw" },
          }}
        >
          <Typography
            variant="h2"
            role="title"
            sx={{
              marginTop: 10,
              fontSize: 36,
              paddingRight: { xl: 30, xs: 0 },
              color: palette.darkBlue,
            }}
          >
            Ready to start your home electrification journey? 
          </Typography>
          <Typography
            variant="body1"
            role="body"
            sx={{
              marginY: 3,
              color: palette.blueOpaque,
              paddingRight: { xl: 40, xs: 0 },
            }}
          >
            Enter your information below to be contacted by one of our
            Electrification Consultants.
          </Typography>
          <CustomerContactForm />
        </Container>
      </Container>
    </Container>
  );
}
