import { useParams } from "react-router-dom";
import { PropertyProfileFormDataView } from "../propertyProfileForm/PropertyProfileForm";
import {
  SalesPropertyProfileDocument,
  SalesPropertyProfileQuery,
} from "../../generated/graphql";
import { useQuery } from "@apollo/client";

export function SalesPropertyProfileForm() {
  const { validatedProfileId } = useParams();
  const { data, loading, error } = useQuery<SalesPropertyProfileQuery>(
    SalesPropertyProfileDocument,
    { variables: { validatedBuildingProfileId: validatedProfileId } }
  );

  if (loading) return <>Loading...</>;
  if (error) return <></>;

  if (data) {
    return (
      <PropertyProfileFormDataView
        // @ts-ignore
        buildingProfile={data.validatedBuildingProfile}
      />
    );
  } else {
    return <></>;
  }
}
