/** @jsxImportSource @emotion/react */
import { Skeleton, Typography } from "@mui/material";
import { ProposalDocumentCard } from "./ProposalDocumentCard";
import { ProposalForm } from "./ProposalForm";
import { ProposalDocument, ProposalQuery } from "../generated/graphql";
import { useQuery } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { paths } from "../paths";
import { ProposalLayout } from "./ProposalLayout";
import { Card } from "../components/Card";

export function ProposalView() {
  const { proposalToken } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useQuery<ProposalQuery>(ProposalDocument, {
    variables: { input: { token: proposalToken } },
  });
  if (!loading && !data) {
    navigate(paths.getStarted().root());
    return <></>;
  }
  if (data?.proposal?.selectedDesign) {
    return <ProposalDataView proposal={data} />;
  }

  if (loading) {
    return <ProposalLoadingView />;
  }

  return <AwaitingProposal />;
}

export function ProposalDataView({ proposal }: { proposal: ProposalQuery }) {
  const { pdfUrl } = proposal.proposal.selectedDesign || { pdfUrl: null };
  const hasRetoolDesignJson =
    !!proposal.proposal.selectedDesign?.retoolDesignJson;
  // See HEL-778
  // Since we will only be able to preview this version of the proposal in prod
  // Use feature flag to control staging and dev
  // Only show it for test addresses on prod
  // And only show for designs that have a json blob design

  const proposalComponent = () => {
    if (hasRetoolDesignJson) {
      return <ProposalForm design={proposal.proposal.selectedDesign!} />;
    } else if (pdfUrl) {
      return <ProposalDocumentCard url={pdfUrl} />;
    } else {
      <AwaitingProposal />;
    }
  };

  return <ProposalLayout>{proposalComponent()}</ProposalLayout>;
}

export function AwaitingProposal() {
  return (
    <ProposalLayout>
      <Card
        sx={{
          padding: 4,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Typography variant="h4">
          Your Proposal is Not Quite Ready Yet
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 4 }}>
          We are working on your proposal now, please check back later for
          details.
        </Typography>
      </Card>
    </ProposalLayout>
  );
}

export function ProposalLoadingView() {
  return (
    <ProposalLayout>
      <Card
        sx={{
          padding: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
        <Skeleton variant="rounded" height={100} width="100%" sx={{ mb: 2 }} />
      </Card>
    </ProposalLayout>
  );
}
