import { render } from "@testing-library/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

export const renderWithRouter = (ui: React.ReactNode, { routes = ['/*'], atUrl = "/" } = {}) => {
    window.history.pushState({}, 'Test page', atUrl)
    const allRoutes = () => {
        return (
            <Routes>
                {routes.map((route) => <Route key={route} path={route} element={<div>{route}</div>}/>)}
            </Routes>
        )
    }

    return {
        ...render(
            <BrowserRouter>
                {ui}
                {allRoutes()}
            </BrowserRouter>
        )
    }
}
