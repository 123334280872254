/** @jsxImportSource @emotion/react */
import { Typography, Box } from "@mui/material";
import { EquipmentPricingForm } from "../../components/forms/EquipmentPricingForm";
import { palette } from "../../theme";

export function SalesIntakeCosts() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          marginY: 3,
          color: palette.darkBlue,
        }}
        variant="body1"
      >
        Estimate of your potential savings and costs for whole home
        electrification.
      </Typography>
      <EquipmentPricingForm />
    </Box>
  );
}
