import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Card } from "../../components/Card";
import { palette } from "../../theme";
import MoneyEnvelopeIcon from "../../assets/roadmap_money_envelope_icon.svg";
import RelaxIcon from "../../assets/roadmap_relax_icon.svg";
import { SolarIcon } from "../../assets/proposal_images/SolarIcon";
import { LightningIcon } from "../../assets/proposal_images/LightningIcon";
import { FanIcon } from "../../assets/proposal_images/FanIcon";
import { InsulationIcon } from "../../assets/proposal_images/InsulationIcon";

export const ProposalStep2Tab = () => {
  const [tradeIndex, setTradeIndex] = React.useState(0);
  const determineTradeModalCopy = (activeTradeButton: number) => {
    if (activeTradeButton === 0) {
      return `Once all the equipment has arrived, we can begin the mechanical install. Some mechanical equipment must wait for permitting.`;
    } else if (activeTradeButton === 1) {
      return `Electrical Upgrades begin after permits are complete. If applicable, Helio will try and schedule Electrical, HVAC and Plumbing for the same day.`;
    } else if (activeTradeButton === 2) {
      return `Solar panel installation will begin after all other electrical equipment has been installed in your home.`;
    } else if (activeTradeButton === 3) {
      return `Because of the work involved in the Solar Installation, Helio generally tries to install Insulation & Air Sealing after Solar is complete. Finding the perfect balance of cost effective home insulation is no easy task, but Helio experts have the tools and the knowhow to provide each house with it’s own unique insulation solution.`;
    } else {
      return `Helio handles all ordering and permitting as soon as your proposal
      is signed and payment has begun. Helio contracts specialized Trade
      Partners who will install equipment as soon as it arrives.`;
    }
  };
  const [activeJobTile, setAactiveJobTile] = React.useState("default");
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    activeJobTile: string
  ) => {
    setAactiveJobTile(activeJobTile);
  };
  const control = {
    value: activeJobTile,
    onChange: handleChange,
    exclusive: true,
  };

  const StyledToggleButton = styled(ToggleButton)({
    height: "120px",
    width: "120px",
    display: "flex",
    flexDirection: "column",
    "&.MuiToggleButtonGroup-grouped": {
      borderRadius: "16px !important",
      mx: 1,
      border: "1px solid lightgrey !important",
    },
    ":hover": {
      color: palette.darkBlue,
      backgroundColor: palette.backgroundGray,
    },
  });

  useEffect(() => {
    switch (activeJobTile) {
      case "hvac":
        setTradeIndex(0);
        break;
      case "electrical":
        setTradeIndex(1);
        break;
      case "solar":
        setTradeIndex(2);
        break;
      case "insulation":
        setTradeIndex(3);
        break;
      default:
        setTradeIndex(4);
    }
  }, [activeJobTile]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: { md: "40%", sm: "100%", xs: "100%" },
          m: { xl: 1, lg: 1, md: 1 },
          my: { md: 0, sm: 1, xs: 1 },
          height: { md: 470, xs: "fit-content" },
          display: "flex",
          flexDirection: "column",
          overflowY: { md: "scroll", xs: "hidden" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">What do YOU do?</Typography>
          <img src={RelaxIcon} alt="Relax Icon" css={{ height: "30px" }} />
        </Box>
        <Typography variant="h6" sx={{ pr: 5, marginTop: 1 }}>
          Sit Back & Relax
        </Typography>
        <Typography sx={{ marginY: 1 }} variant="body1">
          You don’t need to do anything. We have got it from here!
        </Typography>
        <Typography variant="body1">
          Your Electrification Consultant will be in contact to let you know
          about delivery and work schedules.
        </Typography>
      </Card>
      <Card
        sx={{
          width: { md: "60%", sm: "100%", xs: "100%" },
          m: { xl: 1, lg: 1, md: 1 },
          my: { md: 0, sm: 1, xs: 1 },
          height: { md: 470, xs: "fit-content" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          overflowY: { md: "scroll", xs: "hidden" },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">What do WE do?</Typography>
            <img
              src={MoneyEnvelopeIcon}
              alt="Money Envelope Icon"
              css={{ height: "30px" }}
            />
          </Box>
          <Typography variant="h6" sx={{ pr: 5, marginTop: 1 }}>
            • HVAC and Plumbing
          </Typography>
          <Typography variant="h6" sx={{ pr: 5 }}>
            • Electrical Upgrades
          </Typography>
          <Typography variant="h6" sx={{ pr: 5 }}>
            • Solar Install
          </Typography>
          <Typography variant="h6" sx={{ pr: 5 }}>
            • Insulation & Air Sealing
          </Typography>
          <Typography sx={{ marginY: 1, textAlign: "center" }} variant="body1">
            Click a Job Tile on the job button to see details, start date, and
            duration.
          </Typography>
          <ToggleButtonGroup
            sx={{
              display: { md: "flex", sm: "grid" },
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
              },
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              alignItems: "center",
              gridTemplateColumns: "auto auto",
              gridGap: "20px",
              marginY: 2,
            }}
            {...control}
          >
            <StyledToggleButton
              id={"hvacJobTile"}
              value="hvac"
              key="hvac"
              sx={{
                backgroundColor:
                  tradeIndex === 0
                    ? palette.backgroundGray
                    : palette.secondaryRed,
              }}
            >
              <FanIcon
                id={"hvacJobTile"}
                size={40}
                color={tradeIndex === 0 ? palette.darkBlue : palette.white}
              />

              <Typography
                sx={{
                  color: tradeIndex === 0 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                HVAC &
              </Typography>
              <Typography
                sx={{
                  color: tradeIndex === 0 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                Plumbing
              </Typography>
            </StyledToggleButton>
            <StyledToggleButton
              id={"electricalJobTile"}
              value="electrical"
              key="electrical"
              sx={{
                backgroundColor: palette.starfishOrange,
              }}
            >
              <LightningIcon
                id={"electricalJobTile"}
                size={40}
                color={tradeIndex === 1 ? palette.darkBlue : palette.white}
              />
              <Typography
                sx={{
                  color: tradeIndex === 1 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                Electrical
              </Typography>
              <Typography
                sx={{
                  color: tradeIndex === 1 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                Upgrades
              </Typography>
            </StyledToggleButton>
            <StyledToggleButton
              id={"solarJobTile"}
              value="solar"
              key="solar"
              sx={{
                backgroundColor: palette.amberOrange,
              }}
            >
              <SolarIcon
                id={"solarJobTile"}
                size={50}
                color={tradeIndex === 2 ? palette.darkBlue : palette.white}
              />
              <Typography
                sx={{
                  color: tradeIndex === 2 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                Solar Install
              </Typography>
            </StyledToggleButton>
            <StyledToggleButton
              id={"insulationJobTile"}
              value="insulation"
              key="insulation"
              sx={{
                backgroundColor: palette.polishedPine,
              }}
            >
              <InsulationIcon
                id={"insulationJobTile"}
                size={40}
                color={tradeIndex === 3 ? palette.darkBlue : palette.white}
              />
              <Typography
                sx={{
                  color: tradeIndex === 3 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                Insulation &
              </Typography>
              <Typography
                sx={{
                  color: tradeIndex === 3 ? palette.darkBlue : palette.white,
                }}
                variant="h6"
              >
                Air Sealing
              </Typography>
            </StyledToggleButton>
          </ToggleButtonGroup>
          <Typography
            sx={{ marginY: 1, textAlign: { md: "left", xs: "center" } }}
            variant="body1"
          >
            {determineTradeModalCopy(tradeIndex)}
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography variant="h6">
            <span css={{ color: palette.starfishOrange }}>Begins:</span> 1 - 3
            Months
          </Typography>
          <Typography variant="h6">
            <span css={{ color: palette.starfishOrange }}>Task Duration:</span>{" "}
            6 Months
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};
