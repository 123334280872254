import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Tab, Tabs, styled } from "@mui/material";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Layout } from "../../components/Layout";
import { CustomTabs, TabsProps } from "../../components/Tabs";
import {
  SalesIntakeFormDocument,
  SalesIntakeFormQuery,
  SaveSalesIntakeFormDocument,
  SaveSalesIntakeFormMutation,
  ValidatedBuildingProfile,
  Visit,
} from "../../generated/graphql";
import { paths } from "../../paths";
import {
  currentTogglesVar,
  electrificationEngagementVar,
  visitFormValuesVar,
  visitVar,
} from "../../state/ReactiveVars";
import { palette } from "../../theme";
import { BasementAndCrawlspace } from "./BasementAndCrawlspace";
import { CustomerPhotos } from "./CustomerPhotos";
import { Introduction } from "./Introduction";
import { MechanicalRoomAndGarage } from "./MechanicalRoomAndGarage";
import { NextSteps } from "./SalesIntakeNextSteps";
import { SalesIntakeCosts } from "./SalesIntakeCosts";
import { SalesIntakeSidebar } from "./SalesIntakeSidebar";
import { WhatWeInstall } from "./WhatWeInstall";

export type SalesIntakeFormValues = Pick<
  ValidatedBuildingProfile,
  | "yearBuilt"
  | "yearBuiltEffective"
  | "occupancy"
  | "conditionedFloorAreaSqFt"
  | "hasAttic"
  | "hasSolar"
  | "hasEvCharger"
  | "hasPool"
  | "hasHottub"
  | "hasStorageBattery"
  | "homeOwnerAssessedLeakage"
  | "homeOwnerAssessedTemp"
  | "hasCrawlspace"
  | "isCrawlspaceConditioned"
  | "isCrawlspaceVented"
  | "crawlspaceSqFt"
  | "hasBasement"
  | "isBasementConditioned"
  | "isBasementFinished"
  | "basementSqFt"
  | "hasGarage"
  | "garageLocation"
  | "garageSqFt"
  | "spaceAboveGarage"
  | "parkingSpaces"
> &
  Pick<Visit, "designNotes"> & {
    waterHeaterFuelType: string;
    waterHeaterYearInstalled: number;
    waterHeaterTankVolume: number;
  };

const headerHeight = 104;

export function SalesIntakeForm() {
  const { validatedProfileId } = useParams();
  const { data, loading, error } = useQuery<SalesIntakeFormQuery>(
    SalesIntakeFormDocument,
    { variables: { validatedBuildingProfileId: validatedProfileId } }
  );

  if (loading) return <>Loading...</>;
  if (error) return <></>;

  if (data) {
    if (data.validatedBuildingProfile.visit?.electrificationEngagement) {
      const { electrificationEngagementId, ...otherFields } =
        data.validatedBuildingProfile.visit?.electrificationEngagement!;
      electrificationEngagementVar(electrificationEngagementId);

      // only set currentToggles for fields that are not null
      const toggles = Object.keys(otherFields).reduce(function (obj, key) {
        // @ts-ignore
        if ([true, false].includes(otherFields[key])) {
          // @ts-ignore
          obj[key] = otherFields[key];
        }
        return obj;
      }, {});
      currentTogglesVar({ ...currentTogglesVar(), ...toggles });
    }

    return <SalesIntakeFormDataView buildingProfile={data} />;
  } else {
    return <></>;
  }
}

export function SalesIntakeTabs(props: TabsProps) {
  const { tabValue, tabHandleChange } = props;
  const CustomTab = styled(Tab)({
    color: palette.darkBlue,
    fontSize: 20,
    fontFamily: "Exo",
    fontWeight: 400,
    textTransform: "capitalize",
  });

  return (
    <Tabs
      sx={{ height: 70, width: "100%" }}
      value={tabValue}
      onChange={tabHandleChange}
      aria-label="sales intake tabs"
      indicatorColor="secondary"
      centered
      variant="scrollable"
      scrollButtons="auto"
    >
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>Your Helio Home</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>Images</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>Basement & Crawlspace</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>Mechanical Room & Garage</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>What We Install</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>Next Steps</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p>Costs</p>
          </Box>
        }
      />
    </Tabs>
  );
}

function SalesIntakeFormDataView(props: {
  buildingProfile: SalesIntakeFormQuery;
}) {
  const { buildingProfile } = props;
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const { dealId } = useParams();
  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [saveSalesIntakeForm] = useMutation<SaveSalesIntakeFormMutation>(
    SaveSalesIntakeFormDocument
  );

  const initialValues = mapProfileToValues(
    buildingProfile.validatedBuildingProfile
  );

  const photos =
    buildingProfile.validatedBuildingProfile.visit?.clonedFrom?.photos;

  const validationSchema = Yup.object({
    yearBuilt: Yup.number().required(),
    yearBuiltEffective: Yup.number().nullable(),
    occupancy: Yup.number(),
    homeOwnerAssessedTemp: Yup.number(),
    homeOwnerAssessedLeakage: Yup.number(),
    hasAttic: Yup.bool(),
    hasSolar: Yup.bool(),
    hasEvCharger: Yup.bool(),
    hasHottub: Yup.bool(),
    hasStorageBattery: Yup.bool(),
    hasPool: Yup.bool(),
    designNotes: Yup.string().nullable(),
    hasCrawlspace: Yup.bool(),
    isCrawlspaceVented: Yup.bool().nullable(),
    isCrawlspaceConditioned: Yup.bool(),
    hasBasement: Yup.bool(),
    isBasementConditioned: Yup.bool(),
    isBasementFinished: Yup.bool(),
    hasGarage: Yup.bool(),
    garageLocation: Yup.string().nullable(),
    spaceAboveGarage: Yup.string().nullable(),
    waterHeaterFuelType: Yup.string(),
    waterHeaterYearInstalled: Yup.number(),
    // conditionedFloorAreaSqFt: Yup.number().max(10000),
    // crawlspaceSqFt: Yup.number().max(10000),
    // basementSqFt: Yup.number().max(10000),
    // garageSqFt: Yup.number(),
    // parkingSpaces: Yup.number(),
    // waterHeaterTankVolume: Yup.number(),
  });

  async function onSubmit(values: SalesIntakeFormValues) {
    // Combine visitId (required)
    // form values from property profile form
    // and any values from this form
    const {
      confirmSqFt,
      foundationType,
      additionalHeatingSystem,
      basementDropdown,
      hasServiceUpgrade,
      updatedCoolingSystem,
      updatedHeatingSystem,
      updatedInsulation,
      updatedWaterHeater,
      ...otherProfileFormValues
    } = visitFormValuesVar()!;

    const {
      designNotes,
      waterHeaterFuelType,
      waterHeaterYearInstalled,
      waterHeaterTankVolume,
      ...otherIntakeFormValues
    } = values;
    const changedValues = Object.keys(
      otherIntakeFormValues as Omit<SalesIntakeFormValues, "designNotes">
    ).reduce((reducer: any, key: string) => {
      // @ts-ignore
      if (otherIntakeFormValues[key] !== initialValues[key]) {
        // @ts-ignore
        reducer[key] = otherIntakeFormValues[key];
      }
      return reducer;
    }, {});
    const waterHeater = {
      fuelType: waterHeaterFuelType,
      yearInstalled: waterHeaterYearInstalled,
    };
    const input = {
      ...otherProfileFormValues,
      ...changedValues,
      waterHeater,
      visitId: visitVar(),
    };
    const { data, errors } = await saveSalesIntakeForm({
      variables: {
        input: input,
        visitInput: {
          visitId: visitVar(),
          designNotes: designNotes,
        },
      },
    });
    if (!errors) {
      const profileId =
        data?.saveSalesIntakeForm.validatedBuildingProfile?.validatedProfileId;
      if (dealId && profileId) {
        navigate(paths.sales().profileConfirmation(dealId, profileId));
      }
    }
  }

  return (
    <Layout>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          height: { md: `calc(100% - ${headerHeight}px)`, sm: "fit-content" },
        }}
      >
        <Formik
          onSubmit={async (values) => {
            onSubmit(values);
          }}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {(formik) => {
            return (
              <>
                <Box
                  sx={{
                    alignItems: "center",
                    backgroundColor: palette.backgroundGray,
                    borderRadius: { md: "0px 60px 60px 0", sm: 0, xs: 0 },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    height: { md: "fit-content", sm: "fit-content" },
                    width: { md: "75%", sm: "100%", xs: "100%" },
                    minHeight: "100%",
                    maxWidth: "1600px",
                    paddingY: 3,
                    paddingX: { xs: 1, sm: 2, md: 3 },
                    color: palette.darkBlue,
                  }}
                >
                  <CustomTabs
                    views={[
                      <Introduction form={formik} />,
                      <CustomerPhotos photos={photos} />,
                      <BasementAndCrawlspace form={formik} />,
                      <MechanicalRoomAndGarage form={formik} />,
                      <WhatWeInstall />,
                      <NextSteps />,
                      <SalesIntakeCosts />,
                    ]}
                    tabIndex={tabValue}
                    labels={
                      <SalesIntakeTabs
                        tabValue={tabValue}
                        tabHandleChange={tabHandleChange}
                      />
                    }
                  />
                </Box>
                <Box
                  sx={{
                    color: palette.white,
                    marginLeft: { lg: 2, md: 2, sm: 0, xs: 0 },
                    width: { md: "25%", sm: "100%", xs: "100%" },
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                  }}
                >
                  <Button
                    disabled={
                      !isEmpty(formik.errors) || isEmpty(formik.touched)
                    }
                    onClick={formik.submitForm}
                    sx={{ marginY: 2, marginX: 2, alignSelf: "flex-start" }}
                    color={tabValue === 6 ? "primary" : "secondary"}
                  >
                    Submit Intake Form
                  </Button>
                  <SalesIntakeSidebar
                    buildingProfile={buildingProfile}
                    form={formik}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: { md: 8, xs: 0 },
                      marginRight: { md: 0, xs: 0 },
                      justifyContent: "center",
                      paddingBottom: 2,
                    }}
                  >
                    <Button
                      onClick={() => {
                        setTabValue(tabValue - 1);
                      }}
                      disabled={tabValue === 0}
                      sx={{
                        marginY: 2,
                        marginX: 2,
                        alignSelf: { md: "flex-start", xs: "center" },
                      }}
                      color="secondary"
                    >
                      Back
                    </Button>

                    <Button
                      onClick={() => setTabValue(tabValue + 1)}
                      sx={{ marginY: 2, marginX: 2, alignSelf: "flex-end" }}
                      color="primary"
                      disabled={tabValue === 6}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </>
            );
          }}
        </Formik>
      </Box>
    </Layout>
  );
}

function mapProfileToValues(
  validatedProfile: SalesIntakeFormQuery["validatedBuildingProfile"]
): SalesIntakeFormValues {
  const {
    yearBuilt,
    yearBuiltEffective,
    occupancy,
    conditionedFloorAreaSqFt,
    homeOwnerAssessedLeakage,
    homeOwnerAssessedTemp,
    hasAttic,
    hasSolar,
    hasEvCharger,
    hasHottub,
    hasPool,
    hasStorageBattery,
    hasCrawlspace,
    isCrawlspaceConditioned,
    isCrawlspaceVented,
    crawlspaceSqFt,
    hasBasement,
    isBasementConditioned,
    isBasementFinished,
    basementSqFt,
    hasGarage,
    garageLocation,
    garageSqFt,
    parkingSpaces,
    spaceAboveGarage,
    waterHeater,
  } = validatedProfile;
  return {
    occupancy: occupancy || 1,
    hasHottub: Boolean(hasHottub),
    hasPool: Boolean(hasPool),
    waterHeaterFuelType: waterHeater?.fuelType || "",
    waterHeaterYearInstalled: waterHeater?.yearInstalled || 0,
    waterHeaterTankVolume: waterHeater?.tankVolume || 0,
    hasCrawlspace: Boolean(hasCrawlspace),
    isCrawlspaceConditioned: Boolean(isCrawlspaceConditioned),
    isCrawlspaceVented: Boolean(isCrawlspaceVented),
    conditionedFloorAreaSqFt,
    crawlspaceSqFt,
    basementSqFt,
    yearBuilt,
    yearBuiltEffective,
    homeOwnerAssessedLeakage,
    homeOwnerAssessedTemp,
    hasAttic: Boolean(hasAttic),
    hasSolar: Boolean(hasSolar),
    hasEvCharger: Boolean(hasEvCharger),
    hasStorageBattery: Boolean(hasStorageBattery),
    designNotes: validatedProfile.visit?.designNotes,
    hasBasement: Boolean(hasBasement),
    isBasementConditioned: Boolean(isBasementConditioned),
    isBasementFinished: Boolean(isBasementFinished),
    hasGarage: Boolean(hasGarage),
    garageLocation,
    garageSqFt,
    parkingSpaces,
    spaceAboveGarage,
  };
}
