/** @jsxImportSource @emotion/react */
import { Typography, Box } from "@mui/material";

const products = [
  {
    name: "Electrical panel",
    description:
      "You may or may not need a new panel depending on what we install. Send us a picture and we will know.",
  },
  {
    name: "Insulation",
    description:
      'We want to make sure we are insulating your home - picking the "low hanging fruit" to keep your heat/cool inside. This will also improve comfort',
  },
  {
    name: "Air source heat pumps",
    description:
      "to meet all your load, even at cold temp, or to pair with existing or new furnace if needed.  Can be ducted, ductless, or combo if you have comfort issues in a particular room/area.  It's a reversible air conditioner and costs about the same to run as your gas furnace and AC, but uses electricity - which comes more and more from renewable sources - and cuts all or a significant part of your fossil fuel usage.",
  },
  {
    name: "Water heaters",
    description:
      "Air source heat pump water heaters, electric tankless water heaters, or super insulated electric tank water heaters - depends on your home",
  },
  {
    name: "Electrical circuits",
    description:
      "EV, Dryer, Stove - you pick your car, you pick your stove - when you're ready you'll be ready!",
  },
  {
    name: "IAQ",
    description:
      "You spend a lot of time indoors.  Research shows IAQ - even CO2 from people, let alone VOCs, PM 2.5... affects your health.  We install top notch filters on ducted and ductless systems, ventilation system to bring in fresh outside air in energy efficient manner - Panasonic fan or Broan ERV, and at least recommend AirThings monitor.",
  },
  {
    name: "Solar",
    description:
      "We'll provide you with a solar system sized for your NEW electric use, not your old one.  Bigger system.  You pay someone for electricity - might as well be yourself and save some money.  Also increases resiliance and increases value of your home.",
  },
  {
    name: "Battery",
    description:
      "Batteries can help shift usage from expensive to inexpensive times.  About $xxx for 10kw.  Not great payback yet, but will provides backup power as well, which can be priceless.",
  },
];

export function WhatWeInstall() {
  function productsList() {
    return products.map((p) => {
      return (
        <Box key={p.name}>
          <Typography variant="h5" sx={{ marginTop: 2 }}>
            {p.name}
          </Typography>
          <Typography variant="body1">{p.description}</Typography>
        </Box>
      );
    });
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column", margin: 2 }}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
          marginBottom: 2,
          height: "fit-content",
          maxHeight: "100%",
        }}
      >
        <Typography variant="h4">Here's what we install</Typography>
        {productsList()}
      </Box>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
          marginBottom: 2,
          height: "fit-content",
          maxHeight: "100%",
        }}
      >
        <Typography variant="h4">Any Questions?</Typography>
      </Box>
    </Box>
  );
}
