import {
  Box,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { FormikProps } from "formik";
import { palette } from "../../theme";
import { SalesIntakeFormValues } from "./SalesIntakeForm";

export function RadioRow(
  name: string,
  field: keyof SalesIntakeFormValues,
  form: FormikProps<SalesIntakeFormValues>,
  options?: string[]
) {
  function defaultOptions() {
    return [createOption(true, "Yes"), createOption(false, "No")];
  }

  function createOption(value: string | number | boolean, label: string) {
    return (
      <FormControlLabel
        sx={{ color: palette.darkBlue }}
        value={value}
        control={<Radio />}
        label={label}
        key={label}
      />
    );
  }
  return (
    <Box key={field} sx={{ marginTop: 3 }}>
      <FormLabel>{name}</FormLabel>
      <RadioGroup
        name={field}
        value={form.values[field]}
        onBlur={form.handleBlur}
        onChange={(_, value) => {
          const fieldValue = options ? value : JSON.parse(value);
          form.setFieldValue(field, fieldValue);
        }}
        row={true}
        aria-labelledby={`radio-${name}`}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {options ? options.map((o) => createOption(o, o)) : defaultOptions()}
      </RadioGroup>
    </Box>
  );
}
