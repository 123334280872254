/** @jsxImportSource @emotion/react */
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { Card } from "../components/Card";
import { palette } from "../theme";

export function ProposalCard(props: {
  title: string;
  description: string;
  modalBodyContent: EmotionJSX.Element;
  modalHeaderContent?: EmotionJSX.Element;
  icon: EmotionJSX.Element;
  id: string;
}) {
  const { title, description, icon, modalBodyContent, modalHeaderContent, id } =
    props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Card sx={{ mb: 2, width: "100%" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
          }}
        >
          <Typography variant="h4">{title}</Typography>
          <Typography variant="h6" sx={{ marginTop: 2, fontWeight: "light" }}>
            {description}
          </Typography>
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
              justifyContent: "space-between",
            }}
          >
            <Box>{icon}</Box>

            <Button
              id={id}
              onClick={handleOpen}
              sx={{ marginTop: 1 }}
              color="info"
            >
              View
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                justifySelf: "center",
                margin: "0 auto",
                minHeight: "fit-content",
                maxHeight: "100vh",
                paddingY: { md: 5, xs: 0 },
                overflowY: "scroll",
              }}
            >
              <Box
                id="modal"
                sx={{
                  backgroundColor: palette.backgroundGray,
                  borderRadius: "30px",
                  padding: 2,
                  margin: "0 auto",
                  height: { sm: "calc(100vh - 120px)", xs: "100%" },
                  maxHeight: "100vh",
                  width: { md: "90%", xs: "100%" },
                  overflowY: { md: "auto", xs: "scroll" },
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexFlow: "row-reverse",
                    justifyContent: "space-between",
                    position: "absolute",
                    right: 25,
                  }}
                >
                  <Box
                    sx={{
                      zIndex: "2",
                    }}
                  >
                    <CloseIcon sx={{ width: "25px" }} onClick={handleClose} />{" "}
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    flexGrow: 1,
                    marginTop: 6,
                    display: modalHeaderContent?.props?.children
                      ? "inherit"
                      : "none",
                  }}
                >
                  {modalHeaderContent}
                </Box>
                <Box
                  sx={{
                    height: "inherit",
                    overflowY: { md: "auto", sm: "scroll", xs: "scroll" },
                  }}
                >
                  {modalBodyContent}
                </Box>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </Card>
  );
}
