import {
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from "@mui/material";
import { PropertyProfileSubformProps } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { palette } from "../../theme";
import { FormGroup } from "../FormGroup";
import { Radio } from "../inputs/Radio";

export function EnergyImprovementsForm(props: PropertyProfileSubformProps) {
  const formValues = props.formik.values;
  const { formik } = props;

  return (
    <FormGroup
      sx={{ paddingBottom: 2, width: { sm: 450, xs: "100vw" } }}
      formLabel={"Energy Improvements:"}
    >
      <div>
        <Typography sx={{ color: palette.darkBlue }} variant="body1">
          Does your home currently have:
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: 3,
            rowGap: 1.5,
            alignItems: "center",
            marginBottom: 1,
            marginTop: 2,
          }}
        >
          <FormLabel>Solar Panels:</FormLabel>
          <RadioGroup
            name="hasSolar"
            onChange={(e, value) => {
              formik.setFieldValue("hasSolar", JSON.parse(value));
            }}
            onBlur={formik.handleBlur}
            value={formValues.hasSolar}
            aria-labelledby="demo-radio-buttons-group-label"
            row={true}
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormControlLabel
              sx={{ color: palette.darkBlue }}
              value={true}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              sx={{ color: palette.darkBlue }}
              value={false}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          <FormLabel
            sx={{
              width: "100%",
            }}
          >
            Storage Batteries:
          </FormLabel>
          <RadioGroup
            name="hasStorageBattery"
            onChange={(e, value) => {
              formik.setFieldValue("hasStorageBattery", JSON.parse(value));
            }}
            onBlur={formik.handleBlur}
            value={formValues.hasStorageBattery}
            row={true}
            aria-labelledby="demo-radio-buttons-group-label"
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormControlLabel
              sx={{ color: palette.darkBlue }}
              value={true}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              sx={{ color: palette.darkBlue }}
              value={false}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          <FormLabel>Electric Vehicle Charger:</FormLabel>
          <RadioGroup
            name="hasEvCharger"
            onChange={(e, value) => {
              formik.setFieldValue("hasEvCharger", JSON.parse(value));
            }}
            onBlur={formik.handleBlur}
            value={formValues.hasEvCharger}
            row={true}
            aria-labelledby="demo-radio-buttons-group-label"
            sx={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <FormControlLabel
              sx={{ color: palette.darkBlue }}
              value={true}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              sx={{ color: palette.darkBlue }}
              value={false}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
        </Box>
      </div>
    </FormGroup>
  );
}
