import { ApolloClient, InMemoryCache } from "@apollo/client";
import config from "./config/config";
import { addressVar } from "./state/ReactiveVars";

export function apolloClient() {
  return new ApolloClient({
    uri: config.apiUrl,
    cache: new InMemoryCache({
      //TODO: These need to be added to the Apollo schema. Currently these return the same value everytime.
      typePolicies: {
        Query: {
          fields: {
            addressLine1: {
              read() {
                return addressVar().address?.addressLine1;
              },
            },
            addressId: {
              read() {
                return addressVar().addressId;
              },
            },
          },
        },
        ValidatedBuildingProfile: {
          keyFields: ["validatedProfileId"],
        },
      },
      possibleTypes: {
        BaseBuildingProfile: ["ValidatedBuildingProfile", "BuildingProfile"],
      },
    }),
  });
}
