import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { throttle } from "lodash";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import { Grid, Typography } from "@mui/material";
import config from "../../config/config";
import { useReactiveVar } from "@apollo/client";
import { AddressInput } from "../../generated/graphql";
import { addressVar } from "../../state/ReactiveVars";

const API_KEY = config.googleApiKey;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const geocoderService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
  place_id: string;
  terms: Object[];
}

interface GoogleAutoCompleteProps {
  setAddress: (address: AddressInput) => void;
}

export default function GooglePlacesAutocomplete(
  props: GoogleAutoCompleteProps
) {
  const loaded = React.useRef(false);
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const inputValue = useReactiveVar(addressVar).address?.addressLine1;
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=Function.prototype`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle(
        (
          request: {
            input: string;
            types: string[];
            componentRestrictions: { country: String[] };
          },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          );
        },
        200
      ),
    []
  );

  const fetchGeocoderResponse = React.useMemo(
    () =>
      throttle(
        (
          request: { placeId: String },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (geocoderService.current as any).geocode(request, callback);
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!geocoderService.current && (window as any).google) {
      geocoderService.current = new (window as any).google.maps.Geocoder();
    }
    if (!geocoderService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch(
      {
        input: inputValue!,
        types: ["street_address", "premise"],
        componentRestrictions: { country: [ 'CA', 'US' ] },
      },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, fetch, inputValue, fetchGeocoderResponse]);

  return (
    <Autocomplete
      id="google-places-address-input"
      sx={{
        width: `100%`,
        input: { background: "white", borderColor: "white" },
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText="Enter address"
      value={value}
      onChange={(_, newValue: PlaceType | null) => {
        fetchGeocoderResponse(
          { placeId: newValue?.place_id! },
          (results?: any) => mapResultToAddress(results[0], props.setAddress)
        );
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        addressVar({
          addressId: "",
          address: { addressLine1: newInputValue },
        });
      }}
      renderInput={(params) => (
        <TextField {...params} hiddenLabel name="Enter your home address" />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = AutosuggestHighlightParse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );
        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item xs>
                {parts.map(
                  (
                    part: { highlight: any; text: React.ReactNode },
                    index: React.Key
                  ) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  )
                )}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

export function mapResultToAddress(
  result: any,
  callback: (address: AddressInput) => void
) {
  const streetNumber = result.address_components.find((c: any) =>
    c.types.includes("street_number")
  )?.long_name;
  const streetName = result.address_components.find((c: any) =>
    c.types.includes("route")
  )?.short_name;
  const city = result.address_components.find(
    (c: any) => c.types.includes("locality") || c.types.includes("sublocality")
  )?.short_name;
  const county = result.address_components.find((c: any) =>
    c.types.includes("administrative_area_level_2")
  )?.long_name;
  const state = result.address_components.find((c: any) =>
    c.types.includes("administrative_area_level_1")
  )?.short_name;
  const postalCode = result.address_components.find((c: any) =>
    c.types.includes("postal_code")
  )?.long_name;
  const latitude = result.geometry.location.lat();
  const longitude = result.geometry.location.lng();

  callback({
    addressLine1: [streetNumber, streetName].filter(String).join(" "),
    addressLine2: "",
    city,
    county,
    latitude,
    longitude,
    postalCode,
    state,
  });
}
