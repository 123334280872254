/** @jsxImportSource @emotion/react */
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { compact, map, startCase } from "lodash";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  CoolingForm,
  EnergyImprovementsForm,
  GeneralHomeInformationForm,
  HeatingForm,
  InsulationAndComfortForm,
  WaterHeaterForm,
} from "../../components/forms";
import {
  BaseBuildingProfile,
  Maybe,
  PropertyProfileFormDocument,
  PropertyProfileFormQuery,
  SaveValidatedBuildingProfileDocument,
  SaveValidatedBuildingProfileMutation,
  ValidatedBuildingProfileInput,
  Visit,
} from "../../generated/graphql";
import { paths } from "../../paths";
import {
  addressVar,
  featureFlags,
  validatedBuildingProfileIdVar,
  visitFormValuesVar,
  visitVar,
} from "../../state/ReactiveVars";
import { SalesProfileFormHeader } from "../salesPropertyProfile/SalesProfileFormHeader";
import { CustomerProfileFormHeader } from "./CustomerProfileFormHeader";
import { CoolingTypeOptions } from "./fieldInputOptions";

export type PropertyProfileFormValues = Pick<
  BaseBuildingProfile,
  | "buildingType"
  | "conditionedFloorAreaSqFt"
  | "floorsAboveGrade"
  | "foundationType"
  | "hasEvCharger"
  | "hasHottub"
  | "hasPool"
  | "hasSolar"
  | "hasStorageBattery"
  | "homeOwnerAssessedLeakage"
  | "homeOwnerAssessedTemp"
  | "stoveTopType"
  | "waterHeater"
  | "yearBuilt"
  | "hasAttic"
  | "hasCrawlspace"
  | "isBasementFinished"
> & {
  confirmSqFt: boolean;
  coolingSystems: {
    coolingSystemType: string;
    yearInstalled: number;
  }[];
  heatingSystems: {
    heatingSystemType: string;
    heatingSystemFuelType: string;
    yearInstalled: number;
  }[];
  additionalHeatingSystem: boolean;
  hasBasement: boolean;
  hasServiceUpgrade: boolean;
  servicePanelSize: number;
  updatedCoolingSystem: boolean;
  updatedHeatingSystem: boolean;
  updatedInsulation: boolean;
  updatedWaterHeater: boolean;
  basementDropdown: string;
};

export interface PropertyProfileSubformProps {
  formik: FormikProps<PropertyProfileFormValues>;
}

const CURRENT_YEAR = new Date().getFullYear();

const propertyProfileErrorMessages = {
  confirmSqft: "Please confirm square footage",
  minYear: "Please enter a year above 1950.",
  minHomeSize: "We do not support tiny homes yet.",
  minHomeYear: "We do not support homes this old yet.",
  maxHomeSize: "We do not support homes this large yet.",
  maxYear: "We currently don't support houses that have yet to be built.",
  nanError: "Please only enter numerical values.",
  required: "This field is required",
  valueNotOnHand: "We were not able to find this information on your home.",
  minAmperage: "Please enter a value higher than 100",
  maxAmperage: "Please enter a value lower than 400",
  selectOption: "Please Select An Option",
};

export const setServicePanelSize = (yearBuilt: number) => {
  if (yearBuilt < 2005) {
    return 100;
  }
  return 200;
};

export function PropertyProfileForm() {
  const addressId = useReactiveVar(addressVar).addressId;
  const visitId = useReactiveVar(visitVar);
  const visitFormValues = useReactiveVar(visitFormValuesVar);
  const navigate = useNavigate();

  useEffect(() => {
    if (!addressId) {
      // Return home if we don't have an addressId
      navigate(paths.getStarted().root());
    }
  }, [addressId, navigate]);

  const { data } = useQuery<PropertyProfileFormQuery>(
    PropertyProfileFormDocument,
    {
      variables: { addressId: addressId },
      onCompleted: (data) => {
        addressVar({
          ...addressVar(),
          buildingProfile: data.address.buildingProfile!,
        });
      },
    }
  );

  if (visitId && visitFormValues) {
    // We have a profile saved in a reactiveVar, use that
    return <PropertyProfileFormDataView buildingProfile={visitFormValues} />;
  } else if (data) {
    // Otherwise, use the profile returned from the query
    return (
      <PropertyProfileFormDataView
        buildingProfile={data.address.buildingProfile!}
      />
    );
  } else {
    return <></>;
  }
}

export function PropertyProfileFormDataView(props: {
  buildingProfile?:
    | (Partial<BaseBuildingProfile> & {
        visit?: Maybe<Partial<Visit>>;
      })
    | (PropertyProfileFormValues & {
        visit?: Maybe<Partial<Visit>>;
      })
    | undefined;
}) {
  const navigate = useNavigate();
  const { dealId } = useParams();
  const { buildingProfile } = props;
  const [saveValidatedBuildingProfile] =
    useMutation<SaveValidatedBuildingProfileMutation>(
      SaveValidatedBuildingProfileDocument
    );
  const visitId = useReactiveVar(visitVar);
  let addressId = useReactiveVar(addressVar).addressId;

  if (!addressId) {
    addressId = buildingProfile?.visit?.address?.addressId!;
  }

  const minimumConditionedFloorAreaSqFt =
    buildingProfile?.conditionedFloorAreaSqFt
      ? buildingProfile?.conditionedFloorAreaSqFt * 0.7
      : 1200 * 0.7;

  const maximumConditionedFloorAreaSqFt =
    buildingProfile?.conditionedFloorAreaSqFt
      ? buildingProfile?.conditionedFloorAreaSqFt * 1.3
      : 1200 * 1.3;

  const setBasementDropDownOptions = (
    buildingProfile:
      | (Partial<BaseBuildingProfile> & {
          visit?: Maybe<Partial<Visit>>;
        })
      | (PropertyProfileFormValues & {
          visit?: Maybe<Partial<Visit>>;
        })
      | undefined
  ) => {
    if (buildingProfile?.hasBasement === false) {
      return "no";
    } else if (
      buildingProfile?.isBasementFinished &&
      buildingProfile?.hasBasement
    ) {
      return "yesFinished";
    } else if (
      !buildingProfile?.isBasementFinished &&
      buildingProfile?.hasBasement
    ) {
      return "yesUnfinished";
    } else {
      return "";
    }
  };
  const PropertyProfileValidationSchema = Yup.object({
    buildingType: Yup.string()
      .matches(
        /(apartment unit|manufactured home|single-family attached|single-family detached)/,
        propertyProfileErrorMessages.selectOption
      )
      .required(propertyProfileErrorMessages.required),
    confirmSqFt: Yup.bool(),
    conditionedFloorAreaSqFt: Yup.number()
      .typeError(propertyProfileErrorMessages.nanError)
      .min(
        minimumConditionedFloorAreaSqFt,
        propertyProfileErrorMessages.confirmSqft
      )
      .max(
        maximumConditionedFloorAreaSqFt,
        propertyProfileErrorMessages.confirmSqft
      )
      .required(propertyProfileErrorMessages.required)
      .when("confirmSqFt", {
        is: true,
        then: (schema) =>
          schema.min(100, propertyProfileErrorMessages.minHomeSize),
      })
      .when("confirmSqFt", {
        is: true,
        then: (schema) =>
          schema.max(15000, propertyProfileErrorMessages.maxHomeSize),
      }),
    coolingSystems: Yup.array().of(
      Yup.object().shape({
        coolingSystemType: Yup.string()
          .matches(
            /(none|central air conditioner|evaporative cooler|mini-split|room air conditioner|packaged terminal air conditioner|chiller)/,
            propertyProfileErrorMessages.selectOption
          )
          .required(propertyProfileErrorMessages.required),
      })
    ),
    floorsAboveGrade: Yup.string().required(
      propertyProfileErrorMessages.required
    ),
    hasServiceUpgrade: Yup.boolean(),
    heatingSystems: Yup.array().of(
      Yup.object().shape({
        heatingSystemType: Yup.string()
          .matches(
            /(None|ElectricResistance|Furnace|WallFurnace|FloorFurnace|Boiler|Stove|PortableHeater|FixedHeater|Fireplace)/,
            propertyProfileErrorMessages.selectOption
          )
          .required(propertyProfileErrorMessages.required),
      })
    ),
    basementDropdown: Yup.string()
      .required(propertyProfileErrorMessages.required)
      .matches(
        /(no|yesFinished|yesUnfinished)/,
        propertyProfileErrorMessages.selectOption
      ),
    servicePanelSize: Yup.number()
      .required("* Required")
      .min(100, propertyProfileErrorMessages.minAmperage)
      .max(400, propertyProfileErrorMessages.maxAmperage),
    stoveTopType: Yup.string()
      .matches(
        /(electricity|natural gas|propane|wood)/,
        propertyProfileErrorMessages.selectOption
      )
      .required(propertyProfileErrorMessages.required)
      .typeError(propertyProfileErrorMessages.valueNotOnHand),
    waterHeater: Yup.object().shape({
      fuelType: Yup.string()
        .matches(
          /(electricity|natural gas|propane|fuel oil)/,
          propertyProfileErrorMessages.selectOption
        )
        .required(propertyProfileErrorMessages.required),
    }),
    yearBuilt: Yup.number()
      .typeError(propertyProfileErrorMessages.required)
      .max(CURRENT_YEAR, propertyProfileErrorMessages.maxYear)
      .min(1700, propertyProfileErrorMessages.minHomeYear),
  });
  const propertyProfileInitialValues: PropertyProfileFormValues = {
    additionalHeatingSystem:
      (buildingProfile?.heatingSystems?.length || 0) > 1 ||
      visitFormValuesVar()?.additionalHeatingSystem ||
      false,
    buildingType: buildingProfile?.buildingType || "",
    conditionedFloorAreaSqFt: buildingProfile?.conditionedFloorAreaSqFt || 0,
    coolingSystems: buildingProfile?.coolingSystems?.length
      ? [
          {
            coolingSystemType:
              buildingProfile?.coolingSystems[0]!.coolingSystemType,
            yearInstalled:
              buildingProfile?.coolingSystems[0]!.yearInstalled ||
              CURRENT_YEAR - 10,
          },
        ]
      : [
          {
            coolingSystemType: "",
            yearInstalled: CURRENT_YEAR - 10,
          },
        ],

    confirmSqFt: false,
    floorsAboveGrade: buildingProfile?.floorsAboveGrade || 0,
    hasEvCharger: buildingProfile?.hasEvCharger || false,
    hasHottub: buildingProfile?.hasHottub || false,
    hasPool: buildingProfile?.hasPool || false,
    hasSolar: buildingProfile?.hasSolar || false,
    hasStorageBattery: buildingProfile?.hasStorageBattery || false,
    hasAttic: buildingProfile?.hasAttic || false,
    hasCrawlspace: buildingProfile?.hasCrawlspace || false,
    hasBasement: buildingProfile?.hasBasement || false,
    basementDropdown: setBasementDropDownOptions(buildingProfile),
    hasServiceUpgrade: visitFormValuesVar()?.hasServiceUpgrade || false,
    heatingSystems: buildingProfile?.heatingSystems?.length
      ? [
          {
            heatingSystemType:
              buildingProfile?.heatingSystems[0]!.heatingSystemType,
            heatingSystemFuelType:
              buildingProfile?.heatingSystems[0]!.heatingSystemFuelType,
            yearInstalled:
              buildingProfile?.heatingSystems[0]!.yearInstalled ||
              CURRENT_YEAR - 10,
          },
        ]
      : [
          {
            heatingSystemType: "",
            heatingSystemFuelType: "",
            yearInstalled: CURRENT_YEAR - 10,
          },
        ],
    homeOwnerAssessedLeakage: buildingProfile?.homeOwnerAssessedLeakage || 0.5,
    homeOwnerAssessedTemp: buildingProfile?.homeOwnerAssessedTemp || 0.5,
    isBasementFinished: buildingProfile?.isBasementFinished || false,
    servicePanelSize:
      buildingProfile?.servicePanelSize ||
      (buildingProfile?.yearBuilt &&
        setServicePanelSize(buildingProfile.yearBuilt)) ||
      200,
    stoveTopType: buildingProfile?.stoveTopType || "",
    waterHeater: {
      fuelType: buildingProfile?.waterHeater?.fuelType || "",
      yearInstalled:
        buildingProfile?.waterHeater?.yearInstalled || CURRENT_YEAR - 10,
    },
    updatedInsulation: visitFormValuesVar()?.updatedInsulation || false,
    updatedHeatingSystem:
      (buildingProfile?.heatingSystems?.[0]!?.yearInstalled || 2000) > 2013 ||
      visitFormValuesVar()?.updatedHeatingSystem ||
      false,
    updatedCoolingSystem:
      (buildingProfile?.coolingSystems?.[0]!?.yearInstalled || 2000) > 2013 ||
      visitFormValuesVar()?.updatedCoolingSystem ||
      false,
    updatedWaterHeater:
      (buildingProfile?.waterHeater?.yearInstalled || 2000) > 2013 ||
      visitFormValuesVar()?.updatedWaterHeater ||
      false,
    yearBuilt: buildingProfile?.yearBuilt || 0,
  };
  function determinePath(dealId?: string, profileId?: string) {
    return dealId && profileId
      ? // if we are in the sales flow, go to the next sales screen
        paths.sales().intakeForm(dealId, profileId)
      : // otherwise we are in the customer flow and send the customer to home summary
        paths.getStarted().homeSummary();
  }

  function setElectrificationFlag(buildingType: Maybe<string> | undefined) {
    const shouldShowElectrification = buildingType === "single-family detached";
    return featureFlags({
      ...featureFlags(),
      shouldShowElectrification,
    });
  }
  return (
    <Formik
      validateOnMount
      initialValues={propertyProfileInitialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        visitFormValuesVar(values);
        const input = mapFormToInput(addressId, visitId!, values, dealId);
        const { data } = await saveValidatedBuildingProfile({
          variables: {
            input,
          },
        });

        const validatedBuildingProfile =
          data?.saveValidatedBuildingProfile.validatedBuildingProfile;

        let buildingType: Maybe<string> | undefined = "single-family detached";
        let validatedProfileId;
        if (validatedBuildingProfile) {
          buildingType = validatedBuildingProfile.buildingType;
          validatedProfileId = validatedBuildingProfile.validatedProfileId;
        }
        setElectrificationFlag(buildingType);
        validatedBuildingProfileIdVar(validatedProfileId);
        visitVar(data?.saveValidatedBuildingProfile.visit?.visitId);
        navigate(determinePath(dealId, validatedProfileId));
        resetForm();
        setSubmitting(false);
      }}
      validationSchema={PropertyProfileValidationSchema}
    >
      {(formik) => {
        return (
          <form aria-label="Property Profile Form">
            {dealId ? (
              // @ts-ignore
              <SalesProfileFormHeader visit={buildingProfile.visit} />
            ) : (
              <CustomerProfileFormHeader />
            )}
            <Box
              sx={{
                flex: 1,
                display: "grid",
                rowGap: 4,
                gridTemplateColumns: "repeat(auto-fit, min(100%, 550px))",
                placeContent: "center",
                marginTop: 3,
                paddingBottom: 6,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <GeneralHomeInformationForm formik={formik} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <EnergyImprovementsForm formik={formik} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gridRow: "span 2",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <HeatingForm formik={formik} />
                  <Box sx={{ marginY: 4 }}>
                    <CoolingForm formik={formik} />
                  </Box>
                  <WaterHeaterForm formik={formik} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <InsulationAndComfortForm formik={formik} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 3,
                    }}
                  >
                    <a href={paths.getStarted().root()}>
                      <Button color="secondary">Back</Button>
                    </a>
                    <Button
                      color="primary"
                      disabled={Object.keys(formik.errors).length > 0}
                      sx={{ marginLeft: 2 }}
                      onClick={formik.submitForm}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
}

export function mapFormToInput(
  addressId: string,
  visitId: string,
  formInput: PropertyProfileFormValues,
  dealId?: string
): ValidatedBuildingProfileInput {
  const {
    additionalHeatingSystem,
    coolingSystems: coolingSystemValues,
    confirmSqFt,
    hasServiceUpgrade,
    heatingSystems: heatingSystemValues,
    updatedHeatingSystem,
    updatedCoolingSystem,
    updatedWaterHeater,
    waterHeater,
    hasBasement,
    hasAttic,
    hasCrawlspace,
    updatedInsulation,
    isBasementFinished,
    basementDropdown,
    ...otherProps
  } = formInput;

  const electricHeatingSystems = ["PortableHeater", "ElectricResistance"];
  const heatingSystems = compact(
    map(heatingSystemValues, (h) => {
      if (
        h?.heatingSystemFuelType.toLowerCase() === "none" ||
        h?.heatingSystemType.toLowerCase() === "none"
      ) {
        return;
      } else {
        return {
          heatingSystemFuelType: electricHeatingSystems.includes(
            h?.heatingSystemType
          )
            ? "electricity"
            : "natural gas",
          heatingSystemType: h?.heatingSystemType,
          yearInstalled: h?.yearInstalled,
        };
      }
    })
  );

  if (additionalHeatingSystem) {
    heatingSystems.push(heatingSystems[0]);
  }

  const coolingSystems = compact(
    map(coolingSystemValues, (c) => {
      if (startCase(c.coolingSystemType).toLowerCase() === "none") {
        return;
      } else {
        return {
          coolingSystemType:
            CoolingTypeOptions[
              c.coolingSystemType as keyof typeof CoolingTypeOptions
            ],
          yearInstalled: c?.yearInstalled,
        };
      }
    })
  );
  return {
    addressId,
    coolingSystems,
    heatingSystems,
    hubspotDealId: dealId || null,
    hasBasement: hasBasement,
    isBasementFinished: isBasementFinished,
    // These values return an empty string in the event "unsure" is selected. In this event we want
    // to return false since a customer will likely know if they have these
    hasAttic: hasAttic ? true : false,
    hasCrawlspace: hasCrawlspace ? true : false,
    visitId,
    waterHeater,
    ...otherProps,
  };
}
