/** @jsxImportSource @emotion/react */
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { Box, Tabs, Typography, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTab, CustomTabs, TabsProps } from "../../components/Tabs";
import { TabsWithSidebar } from "../../components/TabsWithSidebar";
import {
  ElectrificationSummaryDocument,
  ElectrificationSummaryQuery,
  SaveElectrificationEngagementDocument,
  SaveElectrificationEngagementMutation,
} from "../../generated/graphql";
import { paths } from "../../paths";
import {
  UpgradeToggleState,
  changeToggledUpgradeEnergyMeasures,
  currentTogglesVar,
  validatedBuildingProfileIdVar,
} from "../../state/ReactiveVars";
import { palette } from "../../theme";
import {
  ElectrificationSummarySidebar,
  formatSegmentData,
} from "./ElectrificationSummarySidebar";
import { ElectrifyView } from "./ElectrifyView";
import { HealthView } from "./HealthView";
import { PowerView } from "./PowerView";

export type ElectrificationSummaryLayoutDataViewProps = {
  data: ElectrificationSummaryQuery;
};

export const LabelContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  marginLeft: 1,
  height: 30,
});
export const StyledElectrificationCardLabel = styled(Typography)({
  marginLeft: 10,
  height: "100%",
  display: "flex",
  alignItems: "center",
  fontFamily: "Exo",
  fontSize: 16,
  fontWeight: 600,
  color: palette.darkBlue,
});

export function ElectrificationLayout() {
  const navigate = useNavigate();
  const validatedBuildingProfileId = useReactiveVar(
    validatedBuildingProfileIdVar
  );

  if (!validatedBuildingProfileId) {
    navigate(paths.getStarted().root());
  }

  const { data } = useQuery<ElectrificationSummaryQuery>(
    ElectrificationSummaryDocument,
    {
      variables: { validatedBuildingProfileId },
    }
  );

  if (data) {
    if (!data.validatedBuildingProfile.upgradeEnergyMeasures) {
      navigate(paths.getStarted().emailConfirmation());
    } else {
      return <ElectrificationLayoutDataView data={data} />;
    }
  }

  return <></>;
}

export function ElectrificationTabs(props: TabsProps) {
  const { tabValue, tabHandleChange } = props;

  return (
    <Tabs
      sx={{ height: 70 }}
      value={tabValue}
      onChange={tabHandleChange}
      aria-label="basic tabs example"
      indicatorColor="secondary"
      centered
    >
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p css={{ fontWeight: 700, marginRight: 8 }}> Step 1:</p>
            <p>Electrify</p>
          </Box>
        }
      />
      <CustomTab
        sx={{ marginX: "8%" }}
        label={
          <Box sx={{ display: "flex" }}>
            <p css={{ fontWeight: 700, marginRight: 8 }}> Step 2:</p>
            <p>Power</p>
          </Box>
        }
      />
      <CustomTab
        label={
          <Box sx={{ display: "flex" }}>
            <p css={{ fontWeight: 700, marginRight: 8 }}> Step 3:</p>
            <p>Health</p>
          </Box>
        }
      />
    </Tabs>
  );
}

export function ElectrificationLayoutDataView(
  props: ElectrificationSummaryLayoutDataViewProps
) {
  const { data } = props;
  const headerHeight = 104;
  const [tabValue, setTabValue] = React.useState(0);
  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const [saveElectrificationEngagement] =
    useMutation<SaveElectrificationEngagementMutation>(
      SaveElectrificationEngagementDocument
    );
  const activeToggles = Object.keys(useReactiveVar(currentTogglesVar)).filter(
    (k, i) => currentTogglesVar()[k as keyof UpgradeToggleState] === true
  );
  const upgradeEnergyMeasures =
    data.validatedBuildingProfile!.upgradeEnergyMeasures!;

  useEffect(() => {
    changeToggledUpgradeEnergyMeasures(upgradeEnergyMeasures);
  }, [activeToggles, upgradeEnergyMeasures]);

  const navigate = useNavigate();

  const onNext = () => {
    if (tabValue === 2) {
      navigate(paths.getStarted().costs());
    } else {
      setTabValue(tabValue + 1);
    }
  };

  const onBack = () => {
    if (tabValue === 0) {
      navigate(paths.getStarted().contact());
    } else {
      setTabValue(tabValue - 1);
    }
  };

  return (
    <TabsWithSidebar
      tabValue={tabValue}
      mainContent={
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column" },
            height: { md: `calc(100% - ${headerHeight}px)`, sm: "fit-content" },
          }}
        >
          <CustomTabs
            views={[
              <ElectrifyView
                saveElectrificationEngagement={saveElectrificationEngagement}
                validatedBuildingProfile={props.data.validatedBuildingProfile}
              />,
              <PowerView
                saveElectrificationEngagement={saveElectrificationEngagement}
              />,
              <HealthView
                saveElectrificationEngagement={saveElectrificationEngagement}
              />,
            ]}
            tabIndex={tabValue}
            labels={
              <ElectrificationTabs
                tabValue={tabValue}
                tabHandleChange={tabHandleChange}
              />
            }
          />
        </Box>
      }
      sidebar={
        <ElectrificationSummarySidebar
          energyUsage={formatSegmentData(
            changeToggledUpgradeEnergyMeasures(upgradeEnergyMeasures)
          )}
          displayToggle={false}
          solar={activeToggles.includes("solar")}
        />
      }
      onNext={onNext}
      onBack={onBack}
    />
  );
}
