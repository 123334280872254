/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { palette } from "../theme";
import ElectrificationIcon from "./../assets/electrification_house_icon.svg";
import { ProposalCard } from "./ProposalCard";
import { ProposalElectrificationHomeIllustrationImage } from "./ProposalElectrificationHomeIllustration";

export function ElectrificationModalBody() {
  return (
    <Box
      sx={{
        color: palette.darkBlue,
        backgroundColor: palette.backgroundGray,
        height: "fit-content",
      }}
    >
      <Box>
        <ProposalElectrificationHomeIllustrationImage />
      </Box>
    </Box>
  );
}

export function ProposalElectrificationCard() {
  return (
    <ProposalCard
      id={"viewElectricalModal"}
      title="Electrification"
      description="Learn more about the benefits of a cleaner, more energy efficient home."
      icon={<img src={ElectrificationIcon} alt="Electrification House Icon" />}
      modalBodyContent={<ElectrificationModalBody />}
      modalHeaderContent={
        <>
          <Typography variant="h2">Electrification</Typography>
          <Typography variant="h5" sx={{ marginY: 2, fontWeight: "light" }}>
            Hover over the improvements to learn more about their benefits
          </Typography>
        </>
      }
    />
  );
}
