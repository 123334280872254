export const proposalElectrificationBenefits = {
  evCharger: {
    title: "Electric Vehicle Charger",
    benefits: [
      "Boost your home's value",
      "Ready your home for an electric vehicle",
    ],
  },
  homeVentilation: {
    title: "Home Ventilation",
    benefits: [
      "On average 20-30% reduciton of heat loss",
      "Reduced energy load to increase energy efficiency",
    ],
  },
  inductionStove: {
    title: "Induction Stove Benefits",
    benefits: [
      "Eliminate methane leaks, 75% of which on gas cooktops occurs when the stove is off",
      "Reduce time for boiling water to half the time of natural gas stoves",
    ],
  },
  solar: {
    title: "Solar Panels",
    benefits: [
      "Remove up to 10 tons of carbon emissions per year",
      "Run your home off the sun",
    ],
  },
  battery: {
    title: "Storage Batteries",
    benefits: ["Protection from blackouts", "Use your energy when you need it"],
  },
  insulation: {
    title: "Insulation & Air Sealing",
    benefits: [
      "On average 20-30% reduction of heat loss",
      "Reduced energy load to increase energy efficiency",
    ],
  },
  heatPump: {
    title: "Heat Pump HVAC",
    benefits: [
      "On average 3x more efficient than traditional gas alternatives",
      "Cool and heat your home efficiently with one appliance",
      "Equipment runs quiet, no loud blasts from your furnace",
    ],
  },
  waterHeater: {
    title: "Heat Pump Water Heater",
    benefits: [
      "On average 3x more efficient than any heating alternatives",
      "User-friendly display with vacation mode and IoT capabilities",
      "Monitor and control your water heater from anywhere",
    ],
  },
};
