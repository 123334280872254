/** @jsxImportSource @emotion/react */
import { Typography, Box, Slider } from "@mui/material";
import { SalesIntakeFormValues } from "./SalesIntakeForm";
import { Card } from "../../components/Card";
import { palette } from "../../theme";
import { FormikProps } from "formik";
import { RadioRow } from "./RadioRow";
import { FormTextField } from "./FormTextField";

export function Introduction(props: {
  form: FormikProps<SalesIntakeFormValues>;
}) {
  const { form } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
          margin: 2,
          height: "fit-content",
          maxHeight: "100%",
        }}
      >
        <Typography variant="h4">This should take about 30 minutes</Typography>
        <p>
          I will gather information about your home and present a proposal.
          Before we begin, do you have any questions?
        </p>
        <Typography variant="h4">Here's Helio's philosophy</Typography>
        <p>Electric is inevitable.</p>
        <p>
          We are a one-stop shop for all your electrification needs. Our goal is
          whole home design solution.
        </p>
        <p>
          We provide you with a full electrification roadmap, no matter your
          next stop. We understand that you might not drive to 100%
          "Electrictown" this month, but you will eventually - either when
          equipment dies, you win the lottery, or it's your next big investment.
          We have to stop burning dinosaur bones/fossil fuels which release all
          that stored carbon into our atmosphere and start transitioning to
          renewables solar/wind to make electricity to power our lives. It's
          better for you, it's better for the environment. There are also
          rebates now, energy costs are uncertain, you want cleaner healthier
          air inside your home.
        </p>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", marginX: 2 }}>
        <Box sx={{ marginRight: 1 }}>
          <Card>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
              Tell me about your home
            </Typography>
            <FormTextField
              label="Confirm when your home was built:*"
              form={form}
              fieldName="yearBuilt"
              fieldType="number"
              placeholder="0"
            />
            <FormTextField
              label="Has your home been remodeled? If so, when?"
              form={form}
              fieldName="yearBuiltEffective"
              fieldType="number"
              placeholder="0"
            />
            <FormTextField
              label="How many people live in your home?*"
              form={form}
              fieldName="occupancy"
              fieldType="number"
              placeholder="1"
            />
            <FormTextField
              label="Square Footage:*"
              form={form}
              fieldName="conditionedFloorAreaSqFt"
              fieldType="number"
              placeholder="1200"
            />
            <Typography
              sx={{
                color: palette.darkBlue,
                width: "100%",
                marginY: 2,
              }}
              variant="h5"
            >
              What is the temperature and airflow like in your home?
            </Typography>
            <TempSliders form={form} />
          </Card>
        </Box>
        <Box sx={{ marginLeft: 1 }}>
          <Card>
            <Typography variant="h4">
              I'd like to learn a little bit more about your home. Let's start
              at the bottom:
            </Typography>
            {[
              ["Does your home currently have solar panels?", "hasSolar"],
              ["Does your home currently have an EV charger?", "hasEvCharger"],
              [
                "Does your home currently have storage batteries?",
                "hasStorageBattery",
              ],
              ["Does your home have a hot tub?", "hasHottub"],
              ["Does your home have a heated pool?", "hasPool"],
            ].map(([name, field]) => RadioRow(name, field as keyof SalesIntakeFormValues, form))}
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

function TempSliders(props: { form: FormikProps<SalesIntakeFormValues> }) {
  const { form } = props;
  const tempMarks = [
    { label: "Varying Temperatures", value: 0 },
    { label: "Same Temperature", value: 1 },
  ];

  const insulationMarks = [
    { label: "Very Drafty", value: 0 },
    { label: "Sealed Up Tight", value: 1 },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gridTemplateColumns: "1fr 1fr",
        gap: 1.5,
        alignItems: "center",
        marginBottom: 1,
      }}
    >
      <Slider
        color={"secondary"}
        defaultValue={0}
        max={1}
        min={0}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        marks={insulationMarks}
        name="homeOwnerAssessedLeakage"
        step={0.01}
        value={form.values.homeOwnerAssessedLeakage || 0.5}
      />
      <Slider
        color={"secondary"}
        defaultValue={0}
        min={0}
        max={1}
        name="homeOwnerAssessedTemp"
        marks={tempMarks}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        step={0.01}
        sx={{ marginBottom: 3 }}
        value={form.values.homeOwnerAssessedTemp || 0.5}
      />
    </Box>
  );
}
