import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { palette } from "../../theme";

export function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; labelcolor: string }
) {
  const { labelcolor } = props;
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        size={100}
        sx={{
          zIndex: 1,
          "&.MuiCircularProgress-colorPrimary": {
            color: palette.success,
          },
        }}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        size={100}
        value={100}
        sx={{
          position: "absolute",
          zIndex: 0,
          "&.MuiCircularProgress-colorPrimary": {
            color: palette.grey1,
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h2"
          component="div"
          color={labelcolor}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function CircularStatic() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <CircularProgressWithLabel
      value={progress}
      labelcolor={`${palette.darkBlue}`}
    />
  );
}
