/** @jsxImportSource @emotion/react */
import { Tabs } from "@mui/material";
import React, { useEffect } from "react";
import { HomeSummarySidebar } from "./HomeSummarySidebar";
import { CustomTab, CustomTabs, TabsProps } from "../../components/Tabs";
import {
  CoolingSystem,
  HeatingSystem,
  HomeSummaryDocument,
  HomeSummaryQuery,
  Maybe,
  ValidatedBuildingProfile,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";
import { TabsWithSidebar } from "../../components/TabsWithSidebar";
import { validatedBuildingProfileIdVar } from "../../state/ReactiveVars";
import { useQuery } from "@apollo/client";
import { YourHomeView } from "./YourHomeView";
import { CurrentEnergyUsageView } from "./CurrentEnergyUsageView";
import { EfficiencyAndIncentivesView } from "./EfficiencyAndIncentivesView";
import { paths } from "../../paths";

export type HomeSummaryLayoutDataViewProps = {
  buildingProfile: Omit<
    Partial<ValidatedBuildingProfile>,
    "coolingSystems" | "heatingSystems"
  > & {
    coolingSystems?: Maybe<Partial<CoolingSystem>>[] | null;
    heatingSystems?: Maybe<Partial<HeatingSystem>>[] | null;
  };
};

export function HomeSummaryTabs(props: TabsProps) {
  const { tabValue, tabHandleChange } = props;

  return (
    <Tabs
      sx={{ height: 70 }}
      value={tabValue}
      onChange={tabHandleChange}
      aria-label="home summary tabs"
      indicatorColor="secondary"
      centered
    >
      <CustomTab label={<p>Your Home</p>} />
      <CustomTab sx={{ marginX: "8%" }} label={<p>Current Energy Usage</p>} />
      <CustomTab label={<p>Efficiency & Incentives</p>} />
    </Tabs>
  );
}

export function HomeSummaryLayout() {
  const validatedBuildingProfileId = validatedBuildingProfileIdVar();
  const navigate = useNavigate();

  useEffect(() => {
    if (!validatedBuildingProfileId) {
      navigate(paths.getStarted().root());
    }
  }, [navigate, validatedBuildingProfileId]);

  const { data, loading, error } = useQuery<HomeSummaryQuery>(
    HomeSummaryDocument,
    { variables: { validatedBuildingProfileId } }
  );
  const { validatedBuildingProfile } = data || {};

  if (loading) {
    return <>Loading...</>;
  }
  if (error) {
    navigate(paths.getStarted().systemError());
  }

  if (validatedBuildingProfile) {
    return (
      <HomeSummaryLayoutDataView buildingProfile={validatedBuildingProfile} />
    );
  } else {
    return <></>;
  }
}

export function HomeSummaryLayoutDataView(
  props: HomeSummaryLayoutDataViewProps
) {
  const { buildingProfile } = props;
  const [tabValue, setTabValue] = React.useState(0);
  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();

  const onNext = () => {
    setTabValue(tabValue + 1);
  };

  const onBack = () => {
    if (tabValue === 0) {
      navigate(paths.getStarted().yourHome());
    } else {
      setTabValue(tabValue - 1);
    }
  };

  return (
    <TabsWithSidebar
      mainContent={
        <CustomTabs
          views={[
            <YourHomeView buildingProfile={buildingProfile} />,
            <CurrentEnergyUsageView buildingProfile={buildingProfile} />,
            <EfficiencyAndIncentivesView buildingProfile={buildingProfile} />,
          ]}
          tabIndex={tabValue}
          labels={
            <HomeSummaryTabs
              tabValue={tabValue}
              tabHandleChange={tabHandleChange}
            />
          }
        />
      }
      sidebar={<HomeSummarySidebar buildingProfile={buildingProfile} />}
      onNext={tabValue > 1 ? null : onNext}
      onBack={onBack}
    />
  );
}
