export function FanIcon(props: { size: number; color: string; id?: string }) {
  const { size, color, id = "fanIcon" } = props;
  return (
    <svg
      id={id}
      width={size}
      height={size}
      viewBox="0 0 29 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5779 9.24203C15.1931 9.24203 15.7749 9.3648 16.3301 9.54616V8.28922C16.3301 5.66092 18.3627 3.51968 20.86 3.51968C23.3753 3.51968 25.4217 5.67363 25.4217 8.32136H28.8619C28.8619 4.0861 25.2709 0.638672 20.8585 0.638672C16.4654 0.638672 12.8926 4.07053 12.8926 8.28922V9.52523C13.4283 9.35782 13.9863 9.24203 14.5778 9.24203L14.5779 9.24203Z"
        fill={color}
      />
      <path
        d="M7.94185 16.6079H9.18068C9.01328 16.0722 8.89888 15.5142 8.89888 14.9241C8.89888 14.3074 9.02165 13.7257 9.203 13.1705H7.94189C5.31359 13.1705 3.17342 11.1379 3.17342 8.64057C3.17342 6.12527 5.32737 4.07888 7.9751 4.07888L7.97371 0.638672C3.73844 0.638672 0.291016 4.22966 0.291016 8.64207C0.291016 13.0352 3.72287 16.608 7.94156 16.608L7.94185 16.6079Z"
        fill={color}
      />
      <path
        d="M21.1487 13.171H19.9531C20.1345 13.7262 20.2572 14.3079 20.2572 14.9246C20.2572 15.5147 20.1415 16.0741 19.9754 16.6084H21.1501C23.77 16.6084 25.9825 18.7163 25.9825 21.2079C25.9825 23.7232 23.8285 25.7696 21.1822 25.7696L21.1808 29.2098C25.4161 29.2098 28.8635 25.6188 28.8635 21.2079C28.8635 16.7758 25.4024 13.1709 21.1487 13.1709L21.1487 13.171Z"
        fill={color}
      />
      <path
        d="M14.5777 20.6059C13.9862 20.6059 13.4282 20.4901 12.8925 20.3227V21.4945C12.8925 24.1144 10.7845 26.3269 8.29291 26.3269C5.77761 26.3269 3.73123 24.173 3.73123 21.5267H0.291016C0.291016 25.7619 3.88201 29.2093 8.29298 29.2093C12.725 29.2093 16.33 25.7482 16.33 21.4945V20.3018C15.7747 20.4831 15.1929 20.6059 14.5777 20.6059Z"
        fill={color}
      />
      <path
        d="M14.5775 9.92188C11.8153 9.92188 9.57617 12.1623 9.57617 14.9246C9.57617 17.6882 11.8152 19.9274 14.5775 19.9274C17.3398 19.9274 19.576 17.6883 19.576 14.9246C19.576 12.1624 17.3383 9.92188 14.5775 9.92188ZM14.5775 18.4974C12.6035 18.4974 11.0047 16.8987 11.0047 14.9246C11.0047 12.9506 12.6035 11.3518 14.5775 11.3518C16.5501 11.3518 18.1474 12.9506 18.1474 14.9246C18.1474 16.8987 16.5501 18.4974 14.5775 18.4974Z"
        fill={color}
      />
      <path
        d="M14.5747 13.1377C13.5884 13.1377 12.7891 13.9385 12.7891 14.9248C12.7891 15.9125 13.5884 16.7118 14.5747 16.7118C15.561 16.7118 16.3604 15.9125 16.3604 14.9248C16.3604 13.9385 15.561 13.1377 14.5747 13.1377Z"
        fill={color}
      />
    </svg>
  );
}
