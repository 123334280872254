import React from "react";
import { SliderComponent } from "./SliderComponent";
import { Box } from "@mui/material";
import { LoanData, SetLoanData } from "./LoanCalculator";

export const LoanSliders = (props: {
  loanData: LoanData;
  setLoanData: SetLoanData;
}) => {
  const { loanData, setLoanData } = props;

  return (
    <Box>
      <SliderComponent
        onChange={(e: Event, value: number) => {
          setLoanData({
            ...loanData,
            projectValue: Number(value.toFixed(0)),
            downPayment: Number((0.2 * value).toFixed(0)),
            loanAmount: Number((0.8 * value).toFixed(0)),
          });
        }}
        defaultValue={loanData.projectValue}
        min={1000}
        max={120000}
        steps={1000}
        unit="$"
        amount={loanData.projectValue}
        label="Project Value"
        value={loanData.projectValue}
      />

      <SliderComponent
        onChange={(e: Event, value: number) =>
          setLoanData({
            ...loanData,
            downPayment: Number(value.toFixed(0)),
            loanAmount: Number((loanData.projectValue - value).toFixed(0)),
          })
        }
        defaultValue={loanData.downPayment}
        min={0}
        max={loanData.projectValue}
        steps={1000}
        unit="$"
        amount={loanData.downPayment}
        label="Down Payment"
        value={loanData.downPayment}
      />

      <SliderComponent
        onChange={(e: Event, value: number) =>
          setLoanData({
            ...loanData,
            loanAmount: Number(value.toFixed(0)),
            downPayment: Number((loanData.projectValue - value).toFixed(0)),
          })
        }
        defaultValue={loanData.loanAmount}
        min={1000}
        max={loanData.projectValue}
        steps={1000}
        unit="$"
        amount={loanData.loanAmount}
        label="Loan Amount"
        value={loanData.loanAmount}
      />

      <SliderComponent
        onChange={(e: Event, value: number) =>
          setLoanData({
            ...loanData,
            interestRate: Number(value.toFixed(0)),
          })
        }
        defaultValue={loanData.interestRate}
        min={0}
        max={18}
        steps={0.5}
        unit="%"
        amount={loanData.interestRate}
        label="Interest Rate"
        value={loanData.interestRate}
      />
    </Box>
  );
};
