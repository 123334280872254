/** @jsxImportSource @emotion/react */
import { VictoryBar } from "victory-bar";
import { VictoryLabel } from "victory";
import { palette } from "../../theme";
import styled from "@emotion/styled";
import { Typography, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

type EnergyMix = {
  electric: number;
  naturalGas: number;
};

type EnergyType = {
  therms: number;
  electricUsage: number;
};

export function HomeEnergyMixBarChart(props: {
  energyMix: EnergyMix;
  energyType: EnergyType;
}) {
  const StyledTypography = styled(Typography)({
    color: palette.darkBlue,
    fontFamily: "Montserrat",
    fontSize: "16px",
    position: "relative",
    textAlign: "center",
    width: 110,
  });

  const { energyMix, energyType } = props;

  const data = [
    {
      x: `${energyMix.naturalGas}%\nNatural\n Gas`,
      y: energyMix.naturalGas,
      fontFill: `${palette.darkBlue}`,
      barFill: "#b3b3b3",
    },
    {
      x: `${energyMix.electric}%\nElectric`,
      y: energyMix.electric,
      fontFill: `${palette.darkBlue}`,
      barFill: "#ffffff",
    },
  ];

  return (
    <div css={{ width: 300, marginLeft: 35 }}>
      <Typography
        sx={{
          position: "relative",
          top: 20,
          marginBottom: -5,
          marginLeft: { md: "20px", xs: 0 },
        }}
        variant="h4"
      >
        Your Home Energy Mix
        <Tooltip
          placement="right-start"
          title="Take note of your home's energy mix, and where your power company sources their energy from."
        >
          <InfoIcon sx={{ width: "15px" }} />
        </Tooltip>
      </Typography>
      <StyledTypography
        css={{
          left: 25,
          top: 70,
          width: 100,
        }}
      >
        {energyType.therms} therms per year
      </StyledTypography>
      <StyledTypography
        css={{
          left: 175,
          top: 21,
          width: 100,
        }}
      >
        {energyType.electricUsage} kWh per year
      </StyledTypography>
      <VictoryBar
        barWidth={150}
        domainPadding={{ x: 50 }}
        width={400}
        cornerRadius={5}
        labels={({ datum }) => datum.x}
        labelComponent={
          <VictoryLabel
            style={{
              fill: (props: { datum?: { fontFill?: string } }) =>
                props?.datum?.fontFill,
              fontFamily: "Exo",
              fontSize: 20,
              fontWeight: 600,
            }}
            dy={(datum: { y?: number }) => {
              return 160 - datum?.y;
            }}
            verticalAnchor="start"
          />
        }
        style={{ data: { fill: ({ datum }) => datum.barFill } }}
        colorScale={[
          "#fdc800",
          "#1a2d51",
          "#f7931d",
          "#f76241",
          "#65c728",
          "#f62647",
        ]}
        data={data}
      />
    </div>
  );
}
