import { Box, Button, Link } from "@mui/material";
import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { palette } from "../theme";
import { Header } from "./Header";
import { RoadmapProgressBar } from "./RoadmapProgressBar";

export const scrollToTop = () => {
  document.getElementById("appContainer")?.scrollTo(0, 0);
};

export function TabsWithSidebar(props: {
  mainContent: ReactElement;
  sidebar: ReactElement;
  onNext?: () => void;
  onBack?: () => void;
  tabValue?: number;
  progressBar?: ReactElement;
}) {
  const { mainContent, sidebar, onBack, onNext, tabValue, progressBar } = props;

  const headerHeight = 104;
  const location = useLocation();
  const setNextButtonText = () => {
    if (location.pathname === "/nextsteps") {
      return "Return Home";
    } else if (location.pathname === "/electrification" && tabValue === 2) {
      return "View Summary";
    } else {
      return "Next";
    }
  };

  return (
    <div css={{ height: "100%" }}>
      <Header>
        <Box
          sx={{
            marginY: { xs: 2, sm: 3 },
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          {progressBar || <RoadmapProgressBar />}
        </Box>
      </Header>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          height: { md: `calc(100% - ${headerHeight}px)`, sm: "fit-content" },
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: palette.backgroundGray,
            borderRadius: { md: "0px 60px 60px 0", sm: 0, xs: 0 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: { md: "75%", sm: "100%", xs: "100%" },
            minHeight: "100%",
            maxWidth: "1600px",
            paddingY: 3,
            paddingX: { xs: 1, sm: 2, md: 3 },
            color: palette.darkBlue,
            flex: "1 1 auto",
          }}
        >
          {mainContent}
          <Link
            sx={{
              color: palette.starfishOrange,
              margin: 4,
              textDecoration: "underline",
            }}
            variant="body1"
            color="secondary"
            href="https://www.heliohome.io/faqs"
            target="_blank"
            rel="noreferrer"
          >
            Have Questions, Explore the FAQs
          </Link>
        </Box>
        <Box
          sx={{
            color: palette.white,
            marginLeft: { lg: 2, md: 2, sm: 0, xs: 0 },
            width: { md: "25%", sm: "100%", xs: "100%" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          {sidebar}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: { md: 8, xs: 0 },
              marginRight: { md: 0, xs: 0 },
              justifyContent: "center",
              paddingBottom: 2,
            }}
          >
            {onBack && (
              <Button
                onClick={() => {
                  onBack();
                  scrollToTop();
                }}
                sx={{
                  marginY: 2,
                  marginX: 2,
                  alignSelf: { md: "flex-start", xs: "center" },
                }}
                color="secondary"
              >
                Back
              </Button>
            )}
            {onNext && (
              <Button
                onClick={() => {
                  onNext();
                  scrollToTop();
                }}
                sx={{
                  marginY: 2,
                  marginX: 2,
                  alignSelf: "flex-end",
                  minWidth: "fit-content",
                }}
                color="primary"
              >
                {setNextButtonText()}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
