/** @jsxImportSource @emotion/react */
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip, Typography } from "@mui/material";
import { round } from "lodash";
import { VictoryLabel } from "victory";
import { VictoryPie } from "victory-pie";
import { palette } from "../../theme";
import { LoanData } from "./LoanCalculator";

export const PieChartResult = (props: {
  loanData: LoanData;
  averageUtilityBill: number;
}) => {
  const {
    loanData: { loanAmount, loanTerm, interestRate },
    averageUtilityBill,
  } = props;
  const totalLoanMonths = loanTerm * 12;
  const interestPerMonth = interestRate / 100 / 12;
  let monthlyPayment = 0;
  if (interestPerMonth !== 0) {
    monthlyPayment = Math.round(
      (loanAmount *
        interestPerMonth *
        (1 + interestPerMonth) ** totalLoanMonths) /
        ((1 + interestPerMonth) ** totalLoanMonths - 1)
    );
  } else {
    monthlyPayment = Math.round(loanAmount / totalLoanMonths);
  }
  const totalInterestAmount = Math.round(
    monthlyPayment * totalLoanMonths - loanAmount
  );

  const principalPercent = round(
    (loanAmount / (loanAmount + totalInterestAmount)) * 100
  );
  const interestPercent = round(
    (totalInterestAmount / (loanAmount + totalInterestAmount)) * 100
  );

  const pieChartData = [
    {
      x: `${principalPercent ? principalPercent : 0}%\nPrincipal`,
      y: loanAmount,
      fontFill: `${principalPercent ? palette.darkBlue : palette.white}`,
      labelPlacement: "parallel",
      labelRadius: `${principalPercent ? 50 : 0}`,
    },
    {
      x: `${interestPercent ? interestPercent : 0}%\nInterest`,
      y: totalInterestAmount,
      fontFill: `${interestPercent ? palette.darkBlue : palette.white}`,
      labelPlacement: "parallel",
      labelRadius: `${interestPercent ? 50 : 25}`,
    },
  ];

  const cashflowChange = Math.round(averageUtilityBill - monthlyPayment);

  return (
    <Box sx={{ position: "relative", bottom: { md: 60, xs: 20 } }}>
      <VictoryPie
        padAngle={0.5}
        startAngle={300}
        endAngle={-60}
        width={400}
        cornerRadius={5}
        labelComponent={
          <VictoryLabel
            renderInPortal
            style={{
              fill: (props: { datum?: { fontFill?: string } }) =>
                props.datum?.fontFill,
            }}
            angle={0}
          />
        }
        colorScale={[palette.darkBlue, palette.starfishOrange]}
        labelPosition={({ datum }) => {
          if (datum.x.includes("Other")) {
            return "startAngle";
          }
        }}
        labelPlacement={({ datum }) => datum.labelPlacement}
        labelRadius={({ datum }) => datum.labelRadius}
        data={pieChartData}
        innerRadius={100}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          bottom: { md: 60, xs: 30 },
          marginTop: 2,
        }}
      >
        <Typography textAlign="center" variant="h5">
          Loan Amount:{" "}
          <span css={{ color: "green" }}>
            {Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "usd",
            }).format(loanAmount)}
          </span>
        </Typography>
        <Typography textAlign="center" variant="h5" sx={{ marginTop: 1 }}>
          Total Interest:{" "}
          <span css={{ color: palette.starfishOrange }}>
            {Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "usd",
            }).format(totalInterestAmount)}
          </span>
        </Typography>
        <Typography textAlign="center" variant="h5" sx={{ marginTop: 1 }}>
          Monthly Loan Payment:{" "}
          <span css={{ color: palette.starfishOrange }}>
            {Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "usd",
            }).format(monthlyPayment)}
          </span>
        </Typography>
        <Typography textAlign="center" variant="h5">
          Monthly Cashflow Change:&nbsp;
          <span css={{ color: cashflowChange > 0 ? "green" : "orange" }}>
            {Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "usd",
            }).format(cashflowChange)}
          </span>
          <Tooltip
            placement="right-start"
            title="Assumes 100% solar production on an all-electric home to eliminate utility bill. Outside of utility bill savings, customers can factor in equipment replacement costs for modern, highly efficient all-electric appliances amortized over the life of the loan."
          >
            <InfoIcon sx={{ width: "15px" }} />
          </Tooltip>
        </Typography>
      </Box>
    </Box>
  );
};
