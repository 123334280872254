/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import HomeIllustration from "../assets/electrify_view_home_illustration.svg";
import HomeIllustrationWaterHeater from "../assets/home_illustration_water_heater.svg";
import HomeIllustrationHeatPump from "../assets/home_illustration_heat_pump.svg";
import HomeIllustrationInsulation from "../assets/home_illustration_insulation.svg";
import ActiveHomeIllustrationWaterHeater from "../assets/home_illustration_water_heater_active.svg";
import ActiveHomeIllustrationHeatPump from "../assets/home_illustration_heat_pump_active.svg";
import ActiveHomeIllustrationInsulation from "../assets/home_illustration_insulation_active.svg";

export function ElectrifyViewHomeIllustrationImage(props: {
  activeIcons: { heatPump: boolean; waterHeater: boolean; insulation: boolean };
}) {
  const { heatPump, waterHeater, insulation } = props.activeIcons;
  return (
    <Box sx={{ position: "relative" }}>
      <img
        alt="Heat pump icon"
        src={
          heatPump ? ActiveHomeIllustrationHeatPump : HomeIllustrationHeatPump
        }
        css={{
          zIndex: 1,
          position: "absolute",
          top: "48%",
          left: "82%",
          width: "20%",
        }}
      />
      <img
        alt="Water heater icon"
        src={
          waterHeater
            ? ActiveHomeIllustrationWaterHeater
            : HomeIllustrationWaterHeater
        }
        css={{
          zIndex: 1,
          position: "absolute",
          top: "82%",
          left: "50%",
          width: "20%",
        }}
      />
      <img
        alt="Insulation icon"
        src={
          insulation
            ? ActiveHomeIllustrationInsulation
            : HomeIllustrationInsulation
        }
        css={{
          zIndex: 1,
          position: "absolute",
          top: "10%",
          left: "52%",
          width: "20%",
        }}
      />
      <img
        css={{ zIndex: 0, width: "95%" }}
        alt="A home with interactive icons"
        src={HomeIllustration}
      />
    </Box>
  );
}
