/** @jsxImportSource @emotion/react */ import {
  Box,
  Button,
  styled,
  Tabs,
  Typography,
} from "@mui/material";
import React from "react";
import {
  CustomTab,
  CustomTabs,
  TabNavigatorButtonGroup,
  TabsProps,
} from "../../components/Tabs";
import RoadmapIcon from "../../assets/roadmap_icon.svg";
import { ProposalCard } from "../ProposalCard";
import NotepadWrenchIcon from "../../assets/roadmap_notepad_wrench_icon.svg";
import PersonMoneybagIcon from "../../assets/roadmap_person_moneybag.svg";
import ToolsIcon from "../../assets/roadmap_tools_icon.svg";
import { Card } from "../../components/Card";
import { ProposalStep1Tab } from "./ProposalStep1Tab";
import { ProposalStep2Tab } from "./ProposalStep2Tab";
import { ProposalStep3Tab } from "./ProposalStep3Tab";

export const StyledButtonContainer = styled(Box)({
  width: 200,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignContent: "center",
});

export function RoadmapTabs(props: TabsProps) {
  const { tabValue, tabHandleChange } = props;

  return (
    <Tabs
      sx={{
        ".MuiTabs-indicator": {
          top: { md: 50, xs: 55 },
        },
        ".MuiTab-root": {
          paddingX: { sm: 2, xs: 0.5 },
          paddingTop: 0,
          paddingBottom: 2,
        },
      }}
      value={tabValue}
      onChange={tabHandleChange}
      aria-label="proposal tabs"
      indicatorColor="secondary"
      centered
    >
      <CustomTab
        iconPosition="bottom"
        icon={
          <Card
            sx={{
              height: { sm: 120, xs: "100%" },
              width: { sm: 120, xs: 100 },
              padding: 2,
            }}
          >
            <img
              src={NotepadWrenchIcon}
              alt="Notepad Wrench Icon"
              css={{ height: "20px" }}
            />
            <Typography>Proposal &</Typography>
            <Typography>Payments</Typography>
          </Card>
        }
        label={
          <Box sx={{}}>
            <p>Step 1</p>
          </Box>
        }
      />
      <CustomTab
        iconPosition="bottom"
        icon={
          <Card
            sx={{
              height: { sm: 120, xs: "100%" },
              width: { sm: 120, xs: 100 },
              padding: 2,
            }}
          >
            <img
              src={ToolsIcon}
              alt="Notepad Wrench Icon"
              css={{ height: "20px" }}
            />
            <Typography>Work</Typography>
            <Typography>Begins</Typography>
          </Card>
        }
        label={<p>Step 2</p>}
      />
      <CustomTab
        iconPosition="bottom"
        icon={
          <Card
            sx={{
              height: { sm: 120, xs: "100%" },
              width: { sm: 120, xs: 100 },
              padding: 2,
            }}
          >
            <img
              src={PersonMoneybagIcon}
              alt="Notepad Wrench Icon"
              css={{ height: "20px" }}
            />
            <Typography>Rebates</Typography>
            <Typography>& Credits</Typography>
          </Card>
        }
        label={<p>Step 3</p>}
      />
    </Tabs>
  );
}

export function ProposalRoadmap() {
  const [tabValue, setTabValue] = React.useState(0);
  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          display: { md: "none" },
          width: "100%",
          textAlign: "center",
          marginY: 2,
        }}
        variant="h4"
      >
        Electrification Roadmap
      </Typography>
      <CustomTabs
        showLabelsOnMobile={true}
        views={[
          <ProposalStep1Tab />,
          <ProposalStep2Tab />,
          <ProposalStep3Tab />,
        ]}
        tabIndex={tabValue}
        labels={
          <RoadmapTabs tabValue={tabValue} tabHandleChange={tabHandleChange} />
        }
      />
      <Button
        onClick={() => {
          document.getElementById("modal")?.scrollTo(0, 0);
        }}
        sx={{ display: { sm: "none" } }}
        color="primary"
      >
        Return To Top
      </Button>
      <Box
        sx={{
          display: { sm: "flex", xs: "none" },
          width: "100%",
          justifyContent: "space-evenly",
          marginY: 2,
        }}
      >
        <TabNavigatorButtonGroup
          tabValue={tabValue}
          tabHandleChange={tabHandleChange}
          max={2}
          nextLabels={["Work Begins", "Rebates & Credits", "Next"]}
          backLabels={["Back", "Proposal & Payments", "Work Begins"]}
        />
      </Box>
    </Box>
  );
}

export function ProposalRoadmapCard() {
  return (
    <ProposalCard
      id={"viewRoadmapModal"}
      title="Roadmap"
      description="Learn more about the Helio process to electrify your home."
      icon={<img src={RoadmapIcon} alt="Roadmap Icon" />}
      modalBodyContent={<ProposalRoadmap />}
      modalHeaderContent={<></>}
    />
  );
}
