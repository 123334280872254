import { Box, Container } from "@mui/material";
import helioMapPin from "../assets/helio_map_pin.svg";
import solarBackground from "../assets/solar_background.svg";
import { Header } from "../components/Header";

interface ServiceAreaCheckPageProps {
  children: React.ReactNode;
}
export const ServiceAreaPageLayout = (props: ServiceAreaCheckPageProps) => {
  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        minHeight: "100%",
        minWidth: "100%",
        flexDirection: { sm: "row", xs: "column" },
        backgroundImage: `url(${solarBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container
        disableGutters
        sx={{ width: { sm: "50vw", xs: "100vw" }, zIndex: { sm: 2 } }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            marginTop: { xl: 1, sm: 0, xs: -2 },
            right: { xl: "45.5%", lg: "43%", md: "41%", sm: "55%", xs: 5 },
            top: { md: 50, xs: 87 },
            height: { xl: 200, sm: 210, xs: 172 },
          }}
        >
          <img src={helioMapPin} alt="Helio Home logo" />
        </Box>
      </Container>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: {
            sm: "rgba(245,245,245, 1)",
            xs: "rgba(245,245,245, 0.75)",
          },
          borderRadius: { sm: "60px 0px 0px 60px", xs: "0px 60px 60px 0px" },
          height: {
            lg: "100vh",
            md: "100vh",
            sm: "100vh",
            xs: "fit-content",
          },
          marginTop: { sm: 0, xs: 15 },
          marginLeft: { xs: 0 },
          paddingLeft: { sm: 16 },
          paddingBottom: { sm: 3, xs: 0 },
          width: { md: "50vw", sm: "90vw", xs: "97vw" },
          minWidth: { xl: "50vw" },
          marginRight: { xl: 0 },
        }}
      >
        {props.children}
      </Container>
    </Container>
  );
};
