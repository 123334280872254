/** @jsxImportSource @emotion/react */
import { useQuery } from "@apollo/client";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Card } from "../components/Card";
import { ProposalDocument, ProposalQuery, Visit } from "../generated/graphql";
import { palette } from "../theme";
import BlueHelioLogo from "./../assets/blue_helio_logo.svg";
import {
  deanProfile,
  millieProfile,
  peterProfile,
  stephenProfile,
} from "./../pages/nextsteps/EmployeeProfiles";
import { ProposalCard } from "./ProposalCard";
import { ProposalEmployeeSummary } from "./ProposalTeamEmployeeSummary";

export function TeamMemberModal() {
  const { proposalToken } = useParams();

  const { data } = useQuery<ProposalQuery>(ProposalDocument, {
    variables: { input: { token: proposalToken } },
  });

  const { hubspotOwnerId } = data?.proposal.visit || { hubspotOwnerId: null };
  const setRepresentativeInformation = (id: Visit["hubspotOwnerId"]) => {
    switch (id) {
      case "229745767":
        return deanProfile;
      case "192303958":
        return millieProfile;
      case "193963553":
        return stephenProfile;
      case "operationsManager":
        return peterProfile;
      default:
        return millieProfile;
    }
  };

  return (
    <Card
      sx={{
        color: palette.darkBlue,
        backgroundColor: palette.backgroundGray,
        padding: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
        }}
      >
        <ProposalEmployeeSummary
          employeeInformation={setRepresentativeInformation(
            "operationsManager"
          )}
        />
        <ProposalEmployeeSummary
          employeeInformation={setRepresentativeInformation(hubspotOwnerId)}
        />
      </Box>
    </Card>
  );
}

export function TeamMemberIcon() {
  return <img css={{ width: 60 }} src={BlueHelioLogo} alt="Helio logo" />;
}

export function ProposalTeamCard() {
  return (
    <ProposalCard
      id={"viewHelioTeamModal"}
      title="Your Helio Team"
      description="Learn more about the electrification experts who are here to help
    you."
      icon={<TeamMemberIcon />}
      modalBodyContent={<TeamMemberModal />}
      modalHeaderContent={
        <>
          {" "}
          <Typography variant="h2">Your Helio Team</Typography>
          <Typography variant="h5" sx={{ marginY: 2, fontWeight: "light" }}>
            These experts are here for you.
          </Typography>
        </>
      }
    />
  );
}
