import { Tabs } from "@mui/material";
import { TabsWithSidebar } from "../../components/TabsWithSidebar";
import {
  ElectrificationSummarySidebar,
  formatSegmentData,
} from "../electrification/ElectrificationSummarySidebar";
import { NextStepsView } from "./NextStepsView";
import { CustomTab, CustomTabs } from "../../components/Tabs";
import { useNavigate } from "react-router-dom";
import { paths } from "../../paths";
import { currentTogglesVar, energyMeasures } from "../../state/ReactiveVars";

export function NextStepsTabs(props: { tabValue: number }) {
  const { tabValue } = props;
  return (
    <Tabs
      value={tabValue}
      sx={{ height: 70 }}
      aria-label="next steps tabs"
      indicatorColor="secondary"
      centered
    >
      <CustomTab label={<p>Next Steps</p>} />
    </Tabs>
  );
}

export function NextStepsLayout() {
  const navigate = useNavigate();

  const onBack = () => {
    navigate(paths.getStarted().costs());
  };

  return (
    <TabsWithSidebar
      mainContent={
        <CustomTabs
          views={[<NextStepsView />]}
          tabIndex={0}
          labels={<NextStepsTabs tabValue={0} />}
        />
      }
      sidebar={
        <ElectrificationSummarySidebar
          energyUsage={formatSegmentData(energyMeasures())}
          displayToggle={false}
          solar={currentTogglesVar()["solar"]}
        />
      }
      onBack={onBack}
    />
  );
}
