/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Box, Container, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import progressBarArrow from "../assets/progress_bar_arrow.svg";
import ChangeHistoryTwoToneIcon from "@mui/icons-material/ChangeHistoryTwoTone";
import { palette } from "../theme";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: 0,
  background:
    "linear-gradient(90deg, #65C728 0%, #FFC800 48.44%, #F62447 100%)",
}));

export function LinearProgressBar(props: {
  progressPercent: number;
  labelText: string;
  subLabelText?: string;
}) {
  const { progressPercent, subLabelText, labelText } = props;
  return (
    <Container sx={{ display: "flex", flexDirection: "column" }}>
      <span
        css={{
          position: "relative",
          fontSize: 12,
          fontWeight: "bold",
          top: -7,
          left: `calc(100% * ${progressPercent}/100 - 36px)`,
        }}
      >
        {labelText}
      </span>
      <img
        css={{
          position: "relative",
          zIndex: 1,
          top: 30,
          left: `calc(100% * ${progressPercent}/100 - 50%)`,
          marginTop: -40,
          height: 40,
        }}
        src={progressBarArrow}
        alt="An arrow with a line marking a percentage"
      />
      <BorderLinearProgress variant="determinate" value={0} />
      {subLabelText && (
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "15px",
            textAlign: "left",
            marginTop: 1,
          }}
        >
          {subLabelText}
        </Typography>
      )}
    </Container>
  );
}

export function EnergyIntensityScoreLinearBar(props: {
  score: number;
  labelText: string;
  darkBackground?: boolean;
}) {
  const setMaxScore = () => {
    if (score >= 100) {
      return 100;
    }
    return score;
  };
  const { score, darkBackground } = props;
  const colors = ["#65C728", "#A3C319", "#FFC800", "#F9622C", "#F62447"];
  let labelColor = colors[Math.round(setMaxScore() / 25)];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginX: 0,
      }}
    >
      <Typography
        sx={{
          color: darkBackground ? palette.white : palette.blueOpaque,
          marginTop: 5,
          width: 0,
          top: 12,
          position: "relative",
          left: 4,
        }}
      >
        1
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginX: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            left: 20,
            top: 63,
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <Typography sx={{ fontSize: 13, marginBottom: 0.5 }}>⚪️</Typography>
          <Typography
            sx={{
              color: darkBackground ? palette.white : palette.darkBlue,
              textAlign: "center",
              fontSize: 10,
              fontFamily: "Exo",
            }}
          >
            Average Home
          </Typography>
        </Box>
        <ChangeHistoryTwoToneIcon
          sx={{
            position: "relative",
            rotate: "180deg",
            left: `calc(100% * ${setMaxScore()}/100 - 12px)`,
            color: labelColor,
          }}
        />
        <BorderLinearProgress
          css={{ height: 20 }}
          variant="determinate"
          value={0}
        />
        <Typography
          variant="h2"
          css={{
            position: "relative",
            left: `calc(100% * ${setMaxScore()}/100 - 15px)`,
            top: 16,
            color: labelColor,
            zIndex: 1,
          }}
        >
          {Math.round(score)}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: darkBackground ? palette.white : palette.blueOpaque,
          marginTop: 5,
          width: 0,
          right: "18px",
          position: "relative",
          top: 12,
        }}
      >
        100
      </Typography>
    </Box>
  );
}
