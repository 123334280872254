/** @jsxImportSource @emotion/react */
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import BatteryIcon from "../../assets/icons/battery_orange_icon.svg";
import EvChargerIcon from "../../assets/icons/ev_charger_orange_icon.svg";
import SolarIcon from "../../assets/icons/solar_orange_icon.svg";
import { Card } from "../../components/Card";
import { PowerViewHomeIllustrationImage } from "../../components/PowerViewHomeIllustration";
import { palette } from "../../theme";
import { StyledSwitch as Switch } from "./Slider";
import {
  currentTogglesVar,
  energyMeasures,
  setToggleReactiveVar,
} from "../../state/ReactiveVars";
import { useReactiveVar } from "@apollo/client";
import { MutationSaveElectrificationEngagementArgs } from "../../generated/graphql";
import { renderCurrencyNumber } from "../homeSummary/homeSummaryData";
import {
  LabelContainer,
  StyledElectrificationCardLabel,
} from "./Electrification";

export function PowerView(props: {
  saveElectrificationEngagement: (variables: {
    variables: MutationSaveElectrificationEngagementArgs;
  }) => Promise<any>;
}) {
  const { saveElectrificationEngagement } = props;
  const [homeIllustrationActiveIcons, setHomeIllustrationActiveIcons] =
    React.useState({ solar: false, battery: false, evCharger: false });

  const CopyTextBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    margin: 8,
  });

  const { solar, storageBattery, vehicleCharger } =
    useReactiveVar(currentTogglesVar);

  const {
    yearPayback,
    systemSize,
    firstYearSavings,
    estimatedSolarProduction,
  } = useReactiveVar(energyMeasures).solarPotential || {};
  const { electrifiedEnergyUsageKwh } = useReactiveVar(energyMeasures);

  const energyGenerated = Math.min(
    100,
    Math.round(
      Math.abs((estimatedSolarProduction * 100) / electrifiedEnergyUsageKwh)
    )
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Power Your Equipment:
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        Electrification can make your home cleaner, healthier, and more
        efficient. Below, you can learn about the benefits of how switching to
        high-efficiency electric systems can improve your home. See the figures
        to the right to discover the potential improvement of your carbon
        footprint and Energy Use Intensity score.
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Box>
          <PowerViewHomeIllustrationImage
            activeIcons={homeIllustrationActiveIcons}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginY: 3,
            alignItems: "center",
            width: { md: "500px", sm: "420px", xs: "350px" },
          }}
        >
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                solar: true,
                battery: false,
                evCharger: false,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                solar: false,
                battery: false,
                evCharger: false,
              });
            }}
            css={{ width: "100%", marginBottom: 32 }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ margin: 1 }}
                          checked={solar}
                          onChange={() =>
                            setToggleReactiveVar(
                              "solar",
                              saveElectrificationEngagement
                            )
                          }
                        />
                      }
                      label={
                        <LabelContainer>
                          <img
                            src={SolarIcon}
                            alt={"Icon representing a solar PV panel system"}
                          />
                          <StyledElectrificationCardLabel>
                            Solar PV Panel System
                          </StyledElectrificationCardLabel>
                          <Tooltip
                            placement="right-start"
                            title="Data from Google Project Sunroof, Energy Sage, and NREL"
                          >
                            <InfoIcon sx={{ width: "15px" }} />
                          </Tooltip>
                        </LabelContainer>
                      }
                    />
                  </FormGroup>
                  { estimatedSolarProduction &&
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 14, marginTop: 1 }}
                  >
                    Solar potential for your home:
                  </Typography> }
                </Box>
                { estimatedSolarProduction &&
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          margin: 1,
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 12,
                          color: palette.darkBlue,
                          weight: 600,
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{ color: palette.green, marginRight: 1 }}
                        >
                          {energyGenerated}%
                        </Typography>
                        Energy <br /> Generated
                      </Box>
                      <Divider variant="middle" />
                      <Box
                        sx={{
                          margin: 1,
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 12,
                          color: palette.darkBlue,
                          weight: 600,
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{ color: palette.green, marginRight: 1 }}
                        >
                          {renderCurrencyNumber(firstYearSavings)}
                        </Typography>
                        First Year <br /> Savings
                      </Box>
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          margin: 1,
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 12,
                          color: palette.darkBlue,
                          weight: 600,
                        }}
                      >
                        <Typography
                          variant="h2"
                          sx={{ color: palette.green, marginRight: 1 }}
                        >
                          {Intl.NumberFormat("en-us", {
                            maximumSignificantDigits: 3,
                          }).format(systemSize / 1000)}
                        </Typography>
                        <Typography fontSize={12} sx={{ whiteSpace: "nowrap" }}>
                          Kilowatt DC
                          <br /> Capacity
                        </Typography>
                      </Box>
                      <Divider variant="middle" />
                      <Box
                        sx={{
                          margin: 1,
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 12,
                          color: palette.darkBlue,
                          weight: 600,
                        }}
                      >
                        {yearPayback !== -1 && (
                          <>
                            <Typography
                              variant="h2"
                              sx={{ color: palette.green, marginRight: 1 }}
                            >
                              {yearPayback}
                            </Typography>
                            <Typography fontSize={12} sx={{ whiteSpace: "wrap" }}>
                              Year Payback
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </> }
              </div>
            </Card>
          </div>
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                solar: false,
                battery: true,
                evCharger: false,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                solar: false,
                battery: false,
                evCharger: false,
              });
            }}
            css={{ width: "100%", marginBottom: 32 }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ margin: 1 }}
                          checked={storageBattery}
                          onChange={() =>
                            setToggleReactiveVar(
                              "storageBattery",
                              saveElectrificationEngagement
                            )
                          }
                        />
                      }
                      label={
                        <LabelContainer>
                          <img
                            src={BatteryIcon}
                            alt={"Icon representing storage batteries"}
                          />
                          <StyledElectrificationCardLabel>
                            Storage Batteries
                          </StyledElectrificationCardLabel>
                        </LabelContainer>
                      }
                    />
                  </FormGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginLeft: 10 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "75%",
                    }}
                  >
                    <CopyTextBox>
                      <Typography variant="h4">
                        Protection from Blackouts
                      </Typography>
                      keep your lights, appliances, and more going
                    </CopyTextBox>
                    <CopyTextBox>
                      <Typography variant="h4">Your Energy</Typography> when you
                      need it
                    </CopyTextBox>
                  </Box>
                </Box>
              </div>
            </Card>
          </div>
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                solar: false,
                battery: false,
                evCharger: true,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                solar: false,
                battery: false,
                evCharger: false,
              });
            }}
            css={{ width: "100%" }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ margin: 1 }}
                          checked={vehicleCharger}
                          onChange={() =>
                            setToggleReactiveVar(
                              "vehicleCharger",
                              saveElectrificationEngagement
                            )
                          }
                        />
                      }
                      label={
                        <LabelContainer>
                          <img
                            src={EvChargerIcon}
                            alt={"Icon representing an EV charger"}
                          />
                          <StyledElectrificationCardLabel>
                            Electric Vehicle Charger
                          </StyledElectrificationCardLabel>
                        </LabelContainer>
                      }
                    />
                  </FormGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginLeft: 10 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "75%",
                    }}
                  >
                    <CopyTextBox>
                      <Typography variant="h4">
                        Boost Your Home's Value
                      </Typography>
                      through modern equipment and improvements
                    </CopyTextBox>
                    <CopyTextBox>
                      <Typography variant="h4">Be Ready</Typography> for an
                      electric vehicle
                    </CopyTextBox>
                  </Box>
                </Box>
              </div>
            </Card>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
