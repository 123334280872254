import { styled, Radio as MUIRadio, RadioProps } from "@mui/material";
import { palette } from "../../theme";
import React from "react";

const StyledRadio = styled(MUIRadio)(({ theme }) => ({
  color: palette.grey1,
  "&.Mui-checked": {
    color: palette.starfishOrange,
  },
  "&.Mui-disabled": {
    color: palette.lightGrey,
  },
  fontFamily: "body1",
}));

export function Radio(props: RadioProps) {
  return <StyledRadio {...props} />;
}
