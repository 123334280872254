/** @jsxImportSource @emotion/react */
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabsWithSidebar } from "../components/TabsWithSidebar";
import { ProposalDocument, ProposalQuery } from "../generated/graphql";
import { ElectrificationSummarySidebar } from "../pages/electrification/ElectrificationSummarySidebar";
import { paths } from "../paths";
import { addressVar } from "../state/ReactiveVars";
import { ProposalView } from "./ProposalView";

export function Proposal() {
  const [checked, setChecked] = React.useState(true);
  const { proposalToken } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery<ProposalQuery>(ProposalDocument, {
    variables: { input: { token: proposalToken } },
  });
  if (!loading && !data) {
    navigate(paths.getStarted().root());
    return <></>;
  }

  if (data?.proposal?.selectedDesign?.retoolDesignEnergyMeasures) {
    addressVar({
      address: data.proposal.visit.address,
      addressId: data.proposal.visit.address.addressId,
    });

    const { retoolDesignEnergyMeasures } = data.proposal.selectedDesign;
    const { upgrade, baseline } = retoolDesignEnergyMeasures || {
      upgrade: null,
      baseline: null,
    };

    return (
      <TabsWithSidebar
        sidebar={
          upgrade?.electrifiedEnergyUsageKwh > 0 &&
          baseline?.electrifiedEnergyUsageKwh > 0 ? (
            <ElectrificationSummarySidebar
              displayToggle={true}
              energyUsage={checked ? upgrade : baseline}
              solar={checked}
              checked={checked}
              setChecked={setChecked}
            />
          ) : (
            <></>
          )
        }
        mainContent={<ProposalView />}
        progressBar={<Box sx={{ height: "50px" }}></Box>}
      />
    );
  }

  return (
    <TabsWithSidebar
      sidebar={<></>}
      mainContent={<ProposalView />}
      progressBar={<Box sx={{ height: "50px" }}></Box>}
    />
  );
}
