/** @jsxImportSource @emotion/react */
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OilBarrel from "../../assets/oil_barrel.svg";
import { Card } from "../../components/Card";
import { RebateAndIncentivesCard } from "../../components/RebateAndIncentivesCard";
import {
  CoolingSystem,
  HeatingSystem,
  Maybe,
  ValidatedBuildingProfile,
} from "../../generated/graphql";
import { palette } from "../../theme";
import { CircularProgressWithLabel } from "./CircularProgressWithLabel";
import {
  convertTonsToBarrels,
  potentialCO2Reduction,
  potentialEnergyEfficiencyImprovement,
} from "./homeSummaryData";
import { round } from "lodash";
import { paths } from "../../paths";

interface EfficiencyAndIncentivesViewProps {
  buildingProfile: Omit<
    Partial<ValidatedBuildingProfile>,
    "coolingSystems" | "heatingSystems"
  > & {
    coolingSystems?: Maybe<Partial<CoolingSystem>>[] | null;
    heatingSystems?: Maybe<Partial<HeatingSystem>>[] | null;
  };
}

export function EfficiencyAndIncentivesView(
  props: EfficiencyAndIncentivesViewProps
) {
  const { buildingProfile } = props;
  const {
    waterHeater,
    heatingSystems,
    coolingSystems,
    stoveTopType,
    upgradeEnergyMeasures,
  } = buildingProfile;
  const waterHeaterFuelType = waterHeater?.fuelType || "natural gas";
  const heatingSystemFuelType =
    heatingSystems[0]?.heatingSystemFuelType || "natural gas";
  const hasCooling = !!coolingSystems[0];
  const navigate = useNavigate();
  const improvedUpgradeEnergyMeasuresCO2Reduction =
    upgradeEnergyMeasures?.baseline?.co2Tons -
    upgradeEnergyMeasures?.heatPumpHvacAndWaterHeaterAndInsulation?.co2Tons;
  const improvedUpgradeEnergyMeasuresEnergyEfficiencyImprovement =
    upgradeEnergyMeasures?.heatPumpHvacAndWaterHeaterAndInsulation
      .energyEfficiencyImprovement * 100;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Improve Your Home's Energy Efficiency
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        Our experts will lead you through the entire process of electrifying
        your home. Our goal is to help you navigate your incentives and create
        an energy efficient home that will improve your carbon footprint and
        health.
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
          width: "100%",
          justifyContent: "center",
          marginTop: 3,
          flex: "1 1 auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { md: "50%", sm: "420px", xs: "350px" },
          }}
        >
          <Box
            css={{
              textAlign: "center",
            }}
          >
            <RebateAndIncentivesCard />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: { md: "50%", sm: "420px", xs: "350px" },
            marginLeft: { lg: 4 },
          }}
        >
          <Box sx={{ marginTop: { lg: 0, xs: 4 }, marginBottom: 4 }}>
            <Card>
              <Tooltip title="Your home’s electrification journey could reduce your CO2 emissions by this much.">
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginRight: 2,
                    }}
                  >
                    <h3>
                      Potential CO<sub>2</sub> Emissions Reduction (yearly)
                    </h3>
                    <Box sx={{ display: "flex", marginTop: 2 }}>
                      <img
                        src={OilBarrel}
                        alt={"Icon representing a black oil barrel"}
                      />
                      <Typography sx={{ marginLeft: 1 }}>
                        Equal to{" "}
                        {convertTonsToBarrels(
                          improvedUpgradeEnergyMeasuresCO2Reduction ||
                            potentialCO2Reduction(
                              heatingSystemFuelType,
                              waterHeaterFuelType,
                              stoveTopType,
                              hasCooling,
                              false
                            )
                        )}{" "}
                        barrels of oil
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h2" sx={{ color: "#65C728" }}>
                      {round(improvedUpgradeEnergyMeasuresCO2Reduction, 1) ||
                        potentialCO2Reduction(
                          heatingSystemFuelType,
                          waterHeaterFuelType,
                          stoveTopType,
                          hasCooling,
                          false
                        )}
                    </Typography>{" "}
                    TONS
                  </Box>
                </div>
              </Tooltip>
            </Card>
          </Box>
          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h3>Potential Energy Efficiency Improvement</h3>
              </div>
              <div>
                <CircularProgressWithLabel
                  variant="determinate"
                  value={
                    improvedUpgradeEnergyMeasuresEnergyEfficiencyImprovement ||
                    potentialEnergyEfficiencyImprovement(
                      heatingSystemFuelType,
                      waterHeaterFuelType,
                      stoveTopType,
                      hasCooling,
                      false
                    ) * 100
                  }
                  labelcolor={`${palette.darkBlue}`}
                />
              </div>
            </Box>
          </Card>
        </Box>
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          marginTop: 10,
          marginBottom: 5,
        }}
      >
        <Button
          color="primary"
          onClick={() => navigate(paths.getStarted().contact())}
        >
          Start Your Electrification Journey
        </Button>
      </Box>
    </Box>
  );
}
