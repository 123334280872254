/** @jsxImportSource @emotion/react */
import { CssBaseline, styled, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { EmailConfirmation } from "./components/EmailConfirmation";
import { Layout } from "./components/Layout";
import { OutOfAreaGetStarted } from "./components/OutOfAreaGetStarted";
import { SystemError } from "./components/SystemError";
import { NextStepsLayout } from "./pages/nextsteps/NextSteps";
import { ElectrificationLayout } from "./pages/electrification/Electrification";
import { HomeSummaryLayout } from "./pages/homeSummary/HomeSummary";
import { InAreaContactPage } from "./pages/InAreaCustomContactPage";
import { PricingAndIncentivesLayout } from "./pages/pricingAndIncentives/PricingAndIncentives";
import { SalesPropertyConfirmation } from "./pages/salesPropertyProfile/SalesPropertyConfirmation";
import { SalesPropertyProfileForm } from "./pages/salesPropertyProfile/SalesPropertyProfile";
import { ServiceAreaPageLayout } from "./pages/ServiceAreaPageLayout";
import { WelcomePage } from "./pages/welcomePage/WelcomePage";
import { theme } from "./theme";
import { SalesIntakeForm } from "./pages/salesIntakeForm/SalesIntakeForm";
import { PropertyProfileForm } from "./pages/propertyProfileForm/PropertyProfileForm";
import { Proposal } from "./proposal/Proposal";
import { CustomerPortal } from "./customerPortal/CustomerPortal";

function App() {
  const AppContainer = styled("div")({
    overflowX: "hidden",
    width: "100vw",
    height: "100vh",
    position: "absolute",
  });

  return (
    <AppContainer id="appContainer">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="getstarted">
            <Route path="" element={<WelcomePage />} />

            <Route path="yourhome">
              <Route
                index
                element={
                  <Layout>
                    <PropertyProfileForm />
                  </Layout>
                }
              />
              <Route
                path="deal/:dealId/profile/:validatedProfileId"
                element={
                  <Layout>
                    <SalesPropertyProfileForm />
                  </Layout>
                }
              />
            </Route>
            <Route path="contact" element={<InAreaContactPage />} />
            <Route
              path="emailconfirmation"
              element={
                <ServiceAreaPageLayout>
                  <EmailConfirmation />
                </ServiceAreaPageLayout>
              }
            />
            <Route
              path="outofservicearea"
              element={
                <ServiceAreaPageLayout>
                  <OutOfAreaGetStarted />
                </ServiceAreaPageLayout>
              }
            />
            <Route path="homesummary" element={<HomeSummaryLayout />} />
            <Route
              path="salesprofileconfirmation/deal/:dealId/profile/:validatedProfileId"
              element={
                <ServiceAreaPageLayout>
                  <SalesPropertyConfirmation />
                </ServiceAreaPageLayout>
              }
            />
            <Route path="electrification" element={<ElectrificationLayout />} />
            <Route path="costs" element={<PricingAndIncentivesLayout />} />
            <Route
              path="systemerror"
              element={
                <ServiceAreaPageLayout>
                  <SystemError />
                </ServiceAreaPageLayout>
              }
            />
            <Route path="nextsteps" element={<NextStepsLayout />} />
            <Route path="sales">
              <Route
                path="profile/deal/:dealId/profile/:validatedProfileId"
                element={
                  <Layout>
                    <SalesPropertyProfileForm />
                  </Layout>
                }
              />
              <Route
                path="intakeform/deal/:dealId/profile/:validatedProfileId"
                element={<SalesIntakeForm />}
              />
              <Route
                path="profileconfirmation/deal/:dealId/profile/:validatedProfileId"
                element={
                  <ServiceAreaPageLayout>
                    <SalesPropertyConfirmation />
                  </ServiceAreaPageLayout>
                }
              />
            </Route>
          </Route>
          <Route path="proposal">
            <Route path=":proposalToken" element={<Proposal />} />
            <Route path="" element={<Navigate to="/getstarted" replace />} />
          </Route>
          <Route path="portal">
            <Route path=":customerPortalToken" element={<CustomerPortal />} />
            <Route path="" element={<Navigate to="/getstarted" replace />} />
          </Route>
          <Route path="*" element={<Navigate to="/getstarted" replace />} />
        </Routes>
      </ThemeProvider>
    </AppContainer>
  );
}

export default App;
