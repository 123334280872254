import BradPlunkettPhoto from "../../assets/brad_plunkett_cropped.png";
import DeanPhoto from "../../assets/dean_cropped.svg";
import DefaultHelioTeammate from "../../assets/default_helio_teammate.svg";
import ErinPhoto from "../../assets/erin_cropped.png";
import MilliePhoto from "../../assets/millie_cropped.svg";
import PeterPhoto from "../../assets/peter_cropped.png";
import StephenPhoto from "../../assets/stephen_cropped.svg";

export const millieProfile = {
  employeeImage: MilliePhoto,
  name: "Millie Marsh",
  email: "millie@heliohome.io",
  phoneNumber: "(720) 755-3339",
  title: "Electrification Consultant",
  calendlyLink: "https://calendly.com/millie-electrify/30min",
  description: "The expert for any questions about your proposal.",
};
export const erinProfile = {
  employeeImage: ErinPhoto,
  name: "Erin Witt",
  email: "erin@heliohome.io",
  phoneNumber: "(865) 454-7833",
  title: "Operations Manager",
  description: "The expert for any questions about scheduling.",
};
export const deanProfile = {
  employeeImage: DeanPhoto,
  name: "Dean Howard",
  email: "dean@heliohome.io",
  phoneNumber: "(970) 805-0553",
  title: "Electrification Consultant",
  calendlyLink: "https://calendly.com/dean-heliohome/30min",
  description: "The expert for any questions about your proposal.",
};
export const stephenProfile = {
  employeeImage: StephenPhoto,
  name: "Stephen Hong",
  email: "stephen@heliohome.io",
  phoneNumber: "(513) 205-5024",
  title: "Electrification Consultant",
  calendlyLink: "https://calendly.com/helio-hong/30min",
  description: "The expert for any questions about your proposal.",
};
export const bradJohnsonProfile = {
  employeeImage: DefaultHelioTeammate,
  name: "Brad Johnson",
  email: "brad@heliohome.io",
  phoneNumber: "(720) 295-9134",
  title: "Field Supervisor",
  description: "The expert for any questions about your installation.",
};
export const peterProfile = {
  employeeImage: PeterPhoto,
  name: "Peter Hylton-Hinga",
  email: "peter@heliohome.io",
  phoneNumber: "(720) 277-9368",
  title: "Customer Service Manager",
  description:
    "The expert for any questions about your project's scheduling and installation.",
};
export const bradPlunkettProfile = {
  employeeImage: BradPlunkettPhoto,
  name: "Brad Plunkett",
  email: "bradley.plunkett@heliohome.io",
  phoneNumber: "(720) 263-1439",
  title: "Electrification Consultant",
  description: "The expert for any questions about your proposal.",
  calendlyLink: "https://calendly.com/bradplunkett/30min",
};
