import { Checkbox as MuiCheckbox, CheckboxProps } from "@mui/material";
import React from "react";
import { ReactComponent as Unchecked } from "../../assets/unchecked.svg";
import { ReactComponent as Checked } from "../../assets/checked.svg";

export function Checkbox(props: CheckboxProps) {
  return (
    <MuiCheckbox {...props} icon={<Unchecked />} checkedIcon={<Checked />} />
  );
}
