import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, Typography } from "@mui/material";
import { pickBy } from "lodash";
import {
  potentialDiscounts,
  potentialTaxCredits,
  renderCurrencyNumber,
} from "../pages/homeSummary/homeSummaryData";
import { currentTogglesVar } from "../state/ReactiveVars";
import { palette } from "../theme";
import { Card } from "./Card";

export function RebateAndIncentivesCard() {
  const toggledUpgrades = pickBy(currentTogglesVar(), (k, _v) => k === true);
  const chosenToggles = Object.keys(toggledUpgrades);

  return (
    <Card>
      <Typography variant="h4" sx={{ textAlign: "left", marginBottom: 2 }}>
        Potential Incentives
        <Tooltip
          placement="right-start"
          title="Helio will help you take advantage of as many local, regional, and federal incentives as possible."
        >
          <InfoIcon sx={{ width: "15px" }} />
        </Tooltip>
      </Typography>
      <Typography
        sx={{
          marginBottom: 1,
          fontSize: 14,
          fontStyle: "italic",
          textAlign: "left",
        }}
        variant="body1"
      >
        Incentive estimates assume a greater than $13,600 heat pump (36-48k
        BTU), $7,500 heat pump water heater, more than $4,000 in insulation,
        $25,000 in solar, $19,000 in storage battery, $1500 in vehicle charger,
        and $4000 in electrical service upgrade project costs on average for a
        median-sized home.
      </Typography>
      <div>
        <p>
          Upfront Discounts
          <Tooltip
            placement="right-start"
            title="Any upfront discounts from the City of Denver or other localities with similar incentives will be subtracted from the total cost of your project and paid directly to Helio on your behalf."
          >
            <InfoIcon sx={{ width: "15px" }} />
          </Tooltip>
        </p>
        <Typography variant="h2" sx={{ color: "#65C728" }}>
          {renderCurrencyNumber(potentialDiscounts(chosenToggles))}
        </Typography>
      </div>
      <div>
        <p>
          Tax Credits
          <Tooltip
            placement="right-start"
            title="Tax credits are taken off of state and federal income taxes. Credit can be claimed once the customer files for taxes the year the work was completed. Helio will provide you with all the necessary paperwork for filing."
          >
            <InfoIcon sx={{ width: "15px" }} />
          </Tooltip>
        </p>
        <Typography variant="h2" sx={{ color: "#65C728" }}>
          {renderCurrencyNumber(potentialTaxCredits(chosenToggles))}
        </Typography>
      </div>
      <Typography
        sx={{
          color: palette.starfishOrange,
          marginTop: 2,
          textDecoration: "underline",
        }}
        variant="body1"
      >
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.heliohome.io/inflation-reduction-act"
        >
          Check out our Incentives FAQ
        </a>
      </Typography>
    </Card>
  );
}
