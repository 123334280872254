import { Box, Typography } from "@mui/material";
import { Card } from "../../components/Card";
import { palette } from "../../theme";
import JournalIcon from "../../assets/roadmap_journal_icon.svg";
import NotepadWrenchIcon from "../../assets/roadmap_notepad_wrench_icon.svg";

export const ProposalStep1Tab = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
        width: "100%",
        height: "100%",
      }}
    >
      <Card
        sx={{
          width: { md: "40%", sm: "100%", xs: "100%" },
          m: { xl: 1, lg: 1, md: 1 },
          my: { md: 0, sm: 1, xs: 1 },
          height: { md: 470, xs: "fit-content" },
          overflowY: { md: "scroll", xs: "hidden" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">What do YOU do?</Typography>
          <img src={JournalIcon} alt="Journal Icon" css={{ height: "30px" }} />
        </Box>
        <Typography variant="h6" sx={{ pr: 5, marginY: 1 }}>
          Decide on a payment option & sign your proposal
        </Typography>
        <Typography variant="body1">
          There's a lot of factors to take into consideration, but our loan
          calculator and financing FAQ will help you find the most practical
          payment plan for your needs.
        </Typography>
      </Card>
      <Card
        sx={{
          width: { md: "60%", sm: "100%", xs: "100%" },
          m: { xl: 1, lg: 1, md: 1 },
          my: { md: 0, sm: 1, xs: 1 },
          height: { md: 470, xs: "fit-content" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          overflowY: { md: "scroll", xs: "hidden" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">What do WE do?</Typography>
          <img
            src={NotepadWrenchIcon}
            alt="Notepad Wrench Icon"
            css={{ height: "30px" }}
          />
        </Box>
        <Box>
          <Typography variant="h6" sx={{ pr: 5, marginY: 1 }}>
            All the paperwork, contracting, permitting, and rebates.
          </Typography>
          <Typography variant="body1">
            Once the proposal is signed and the loan process has begun, we can
            move forward with your Net-Zero Roadmap. Whether it’s financial
            terms or a heat pump thermodynamics question, an answer is just a
            phone call away. Your Electrification Consultant is there for you,
            no matter what questions you have with your proposal.
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ marginTop: 1 }} variant="h6">
            Permitting & Ordering
          </Typography>
          <Typography sx={{ marginY: 1 }} variant="body1">
            Helio will file for permits and begin ordering equipment the day
            your proposal is signed and submitted. Most permitting is quick, but
            solar permitting can take up to 2-3 months because it requires
            permitting from both your city and energy provider. Because of
            supply chain volatility, equipment source times can vary, but it
            will usually take 2-4 weeks for your products to arrive. We’ll keep
            you updated on ship dates and arrivals.
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography variant="h6">
            <span css={{ color: palette.starfishOrange }}>Begins:</span> After
            Signing
          </Typography>
          <Typography variant="h6">
            <span css={{ color: palette.starfishOrange }}>Task Duration:</span>{" "}
            3-12 Weeks
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};
