/** @jsxImportSource @emotion/react */
import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";
import { identity, pickBy } from "lodash";
import React from "react";
import { Card } from "../components/Card";
import HomeIllustrationBattery from "./../assets/home_illustration_battery.svg";
import ActiveHomeIllustrationBattery from "./../assets/home_illustration_battery_active.svg";
import HomeIllustrationEvCharger from "./../assets/home_illustration_ev_charger.svg";
import ActiveHomeIllustrationEvCharger from "./../assets/home_illustration_ev_charger_active.svg";
import HomeIllustrationHeatPump from "./../assets/home_illustration_heat_pump.svg";
import ActiveHomeIllustrationHeatPump from "./../assets/home_illustration_heat_pump_active.svg";
import HomeIllustrationHomeVentilation from "./../assets/home_illustration_home_ventilation.svg";
import ActiveHomeIllustrationHomeVentilation from "./../assets/home_illustration_home_ventilation_active.svg";
import HomeIllustrationInductionStove from "./../assets/home_illustration_induction_stove.svg";
import ActiveHomeIllustrationInductionStove from "./../assets/home_illustration_induction_stove_active.svg";
import HomeIllustrationInsulation from "./../assets/home_illustration_insulation.svg";
import ActiveHomeIllustrationInsulation from "./../assets/home_illustration_insulation_active.svg";
import HomeIllustrationSolar from "./../assets/home_illustration_solar.svg";
import ActiveHomeIllustrationSolar from "./../assets/home_illustration_solar_active.svg";
import HomeIllustrationWaterHeater from "./../assets/home_illustration_water_heater.svg";
import ActiveHomeIllustrationWaterHeater from "./../assets/home_illustration_water_heater_active.svg";
import HomeIllustration from "./../assets/proposal_electrification_home_illustration.svg";
import { proposalElectrificationBenefits } from "./ProposalElectrificationBenefits";

export type activeIcon =
  | "evCharger"
  | "homeVentilation"
  | "inductionStove"
  | "solar"
  | "battery"
  | "insulation"
  | "heatPump"
  | "waterHeater";

export function ProposalElectrificationHomeIllustrationImage() {
  const initialIconState: {[ICON in activeIcon]: boolean} = {
    heatPump: false,
    waterHeater: false,
    insulation: false,
    solar: false,
    battery: false,
    evCharger: false,
    inductionStove: false,
    homeVentilation: false,
  };

  const [homeIllustrationActiveIcons, setHomeIllustrationActiveIcons] =
    React.useState<{ [ICON in activeIcon]: boolean }>(initialIconState);

  const {
    heatPump,
    waterHeater,
    insulation,
    solar,
    battery,
    evCharger,
    inductionStove,
    homeVentilation,
  } = homeIllustrationActiveIcons;

  const setActiveEquipmentCardIcon = (
    activeIcon: activeIcon
  ) => {
    switch (activeIcon) {
      case "heatPump":
        return ActiveHomeIllustrationHeatPump;
      case "waterHeater":
        return ActiveHomeIllustrationWaterHeater;
      case "insulation":
        return ActiveHomeIllustrationInsulation;
      case "solar":
        return ActiveHomeIllustrationSolar;
      case "battery":
        return ActiveHomeIllustrationBattery;
      case "evCharger":
        return ActiveHomeIllustrationEvCharger;
      case "inductionStove":
        return ActiveHomeIllustrationInductionStove;
      default:
        return ActiveHomeIllustrationHomeVentilation;
    }
  };

  const activeEquipmentIcon = Object.keys(
    pickBy(homeIllustrationActiveIcons, identity)
  )[0] as activeIcon;

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {activeEquipmentIcon && (
        <ActiveEquipmentCard
          title={proposalElectrificationBenefits[activeEquipmentIcon].title}
          benefits={
            proposalElectrificationBenefits[activeEquipmentIcon].benefits
          }
          icon={setActiveEquipmentCardIcon(activeEquipmentIcon)}
        />
      )}
      <Box
        sx={{
          position: "relative",
          width: { xl: "70%", lg: "70%", md: "70%", sm: "100%", xs: "100%" },
        }}
      >
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              heatPump: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Heat pump icon"
            src={
              heatPump
                ? ActiveHomeIllustrationHeatPump
                : HomeIllustrationHeatPump
            }
            css={{
              zIndex: 1,
              position: "absolute",
              top: "48%",
              left: "80%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              waterHeater: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Water heater icon"
            src={
              waterHeater
                ? ActiveHomeIllustrationWaterHeater
                : HomeIllustrationWaterHeater
            }
            css={{
              zIndex: 1,
              position: "absolute",
              top: "82%",
              left: "60%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              insulation: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Insulation icon"
            src={
              insulation
                ? ActiveHomeIllustrationInsulation
                : HomeIllustrationInsulation
            }
            css={{
              zIndex: 1,
              position: "absolute",
              top: "16%",
              left: "60%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              solar: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Solar icon"
            src={solar ? ActiveHomeIllustrationSolar : HomeIllustrationSolar}
            css={{
              zIndex: 1,
              position: "absolute",
              top: "25%",
              left: "20%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              battery: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Battery icon"
            src={
              battery ? ActiveHomeIllustrationBattery : HomeIllustrationBattery
            }
            css={{
              zIndex: 1,
              position: "absolute",
              top: "55%",
              left: "0%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              evCharger: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="EV Charger icon"
            src={
              evCharger
                ? ActiveHomeIllustrationEvCharger
                : HomeIllustrationEvCharger
            }
            css={{
              zIndex: 1,
              position: "absolute",
              top: "40%",
              left: "10%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              homeVentilation: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Home Ventilation icon"
            src={
              homeVentilation
                ? ActiveHomeIllustrationHomeVentilation
                : HomeIllustrationHomeVentilation
            }
            css={{
              zIndex: 1,
              position: "absolute",
              top: "80%",
              left: "35%",
              width: "20%",
            }}
          />
        </div>
        <div
          onMouseOver={() => {
            setHomeIllustrationActiveIcons({
              ...initialIconState,
              inductionStove: true,
            });
          }}
          onMouseLeave={() => {
            setHomeIllustrationActiveIcons(initialIconState);
          }}
          css={{ width: "100%", marginBottom: 32 }}
        >
          <img
            alt="Induction Stove icon"
            src={
              inductionStove
                ? ActiveHomeIllustrationInductionStove
                : HomeIllustrationInductionStove
            }
            css={{
              position: "absolute",
              top: "60%",
              left: "60%",
              width: "20%",
            }}
          />
        </div>
        <img
          css={{ zIndex: 0, width: "95%" }}
          alt="A home with interactive icons"
          src={HomeIllustration}
        />
      </Box>
    </Box>
  );
}

function ActiveEquipmentCard(props: {
  title: string;
  benefits: string[];
  icon: string;
}) {
  const { title, benefits, icon } = props;

  return (
    <Card
      sx={{
        border: 1,
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
        zIndex: 2,
        position: "absolute",
        width: { xl: "50%", lg: "50%", md: "50%", sm: "70%", xs: "70%" },
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
        }}
      >
        <img alt={`${title} icon`} src={icon} css={{ width: "100px" }} />
        <Typography variant="h4" sx={{ alignSelf: "center" }}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1">Benefits</Typography>
        {benefits.map((benefit) => {
          return (
            <Typography variant="body2" sx={{ marginLeft: 2 }}>
              <CheckIcon sx={{ color: "green", width: "25px", mr: 1 }} />
              {benefit}
            </Typography>
          );
        })}
      </Box>
    </Card>
  );
}
