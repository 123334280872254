/** @jsxImportSource @emotion/react */
import SouthIcon from "@mui/icons-material/South";
import { Box, Button, Typography } from "@mui/material";
import { EmployeeImage, EmployeeInfo } from "../components/EmployeesCard";
import { palette } from "../theme";

export function ProposalEmployeeSummary(props: {
  employeeInformation: EmployeeInfo;
}) {
  const { employeeInformation } = props;

  return (
    <Box sx={{ width: "100%", alignItems: "center" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ margin: 2, textAlign: "center", height: 60, width: 250 }}
        >
          {employeeInformation.description}
        </Typography>
        <SouthIcon sx={{ marginBottom: 4 }} fontSize="large" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <EmployeeImage
            employeeName={employeeInformation.name}
            employeeImage={employeeInformation.employeeImage}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { lg: "inherit", xs: "center" },
          }}
        >
          <Typography variant="h4">{employeeInformation.name}</Typography>
          <Typography variant="body1">{employeeInformation.title}</Typography>
          <Typography variant="body1" sx={{ color: palette.starfishOrange }}>
            {employeeInformation.email}
          </Typography>
          <Typography variant="body1">
            {employeeInformation.phoneNumber}
          </Typography>
          {employeeInformation?.calendlyLink && (
            <a
              href={employeeInformation.calendlyLink}
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="primary"
                sx={{
                  paddingX: 2,
                  marginTop: 1,
                  marginBottom: 4,
                }}
              >
                Schedule Appointment
              </Button>
            </a>
          )}
        </Box>
      </Box>
    </Box>
  );
}
