/* eslint-disable @typescript-eslint/no-use-before-define */
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bodySmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodySmall?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodySmall: true;
  }
}

export const palette = {
  amberOrange: "#F7931E",
  backgroundGray: "#E8EAEE",
  blueOpaque: "#54627C",
  darkBlue: "#1B2D51",
  gold: "#F4B321",
  green: "#65C728",
  grey1: "#E5E5E5",
  grey2: "#F9F9F9",
  grey3: "#E0E7ED",
  gray5: "#1A1A1A",
  lightGrey: "#E1E1E1 ",
  polishedPine: "#5F9582",
  secondaryRed: "#D63641",
  starfishOrange: "#F76241",
  success: "#65C728",
  white: "#FFFF",
} as const;

export const theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 1100,
  //     md: 1200,
  //     lg: 1400,
  //     xl: 1536,
  //   },
  // },
  palette: {
    primary: {
      main: palette.darkBlue,
    },
    secondary: {
      main: palette.starfishOrange,
    },
    background: {
      default: palette.darkBlue,
    },
  },
  typography: {
    fontFamily: "Exo",
    bodySmall: {
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: 400,
      color: palette.darkBlue,
    },
    body1: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontFamily: "Exo",
      fontSize: 18,
      fontWeight: 400,
      color: palette.darkBlue,
    },
    h2: {
      fontSize: 32,
      fontWeight: "bold",
    },
    h4: {
      fontFamily: "Exo",
      fontSize: 20,
      fontWeight: 600,
      color: palette.darkBlue,
    },
    h5: {
      fontFamily: "Exo",
      fontSize: 16,
      fontWeight: 600,
      color: palette.darkBlue,
    },
    h6: {
      fontFamily: "Exo",
      fontSize: 14,
      color: palette.darkBlue,
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: palette.white,
          borderRadius: 60,
          backgroundImage: `linear-gradient(218.12deg, ${palette.starfishOrange} 25.8%, ${palette.amberOrange} 133.27%)`,
          textTransform: "none",
          fontWeight: 600,
          padding: `10px 50px`,
          "&:hover": {
            color: palette.darkBlue,
          },
          "&.Mui-disabled": {
            background: palette.lightGrey,
            color: palette.white,
          },
        },
      },
      variants: [
        {
          props: { color: "primary" },
          style: {
            color: palette.white,
          },
        },
        {
          props: { color: "secondary" },
          style: {
            color: palette.starfishOrange,
            background: `linear-gradient(white, white) padding-box,
            linear-gradient(218.12deg, ${palette.starfishOrange} 25.8%, ${palette.amberOrange} 133.27%) border-box`,
            border: "2px solid transparent",
            transition: "background 0.25s ease",
            "&:hover": {
              background: `linear-gradient(white, white) padding-box,
              ${palette.darkBlue} border-box`,
              transition: "background 200ms ease-in-out",
              color: palette.darkBlue,
            },
          },
        },
        {
          props: { color: "info" },
          style: {
            background: `linear-gradient(white, white) padding-box,
            linear-gradient(218.12deg, ${palette.darkBlue} 25.8%, ${palette.darkBlue} 133.27%) border-box`,
            color: palette.darkBlue,
            transition: "background 0.25s ease",
            "&:hover": {
              background: `linear-gradient(white, white) padding-box,
                    ${palette.starfishOrange} border-box`,
              transition: "background 2ms ease-in-out",
              color: palette.starfishOrange,
            },
            padding: "2px 20px",
            border: "2px solid transparent",
          },
        },
      ],
    },
    MuiCssBaseline: {
      styleOverrides: {
        // "&::-webkit-scrollbar": {
        //   width: "12px",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //   webkitBorderRadius: "10px",
        //   borderRadius: "10px",
        //   background: "rgba(0,0,0,0.15)",
        //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
        // },
        body: {
          background: `linear-gradient(300.7deg, #1D3970 43.2%, #0B1529 119.28%)`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          color: palette.darkBlue,
          fontSize: 16,
        },
        a: {
          textDecoration: "none",
          color: palette.starfishOrange,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.darkBlue,
          fontFamily: "Exo",
          fontWeight: 600,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          fontWeight: 400,
          fontSize: 12,
          fontFamily: "Montserrat",
          color: palette.darkBlue,
          transform: "translateX(-100%)",
          '&[data-index="0"]': {
            transform: "none",
          },
        },
        rail: {
          color: palette.grey1,
        },
      },
    },
    // Custom Stepper
    MuiStepLabel: {
      styleOverrides: {
        // Lower step labels
        label: {
          color: palette.white,
          fontSize: 12,
          fontWeight: "bold",
          textAlign: "center",
          "&.Mui-active, &.Mui-completed": {
            color: palette.white,
            fontSize: 12,
            fontWeight: "bold",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Montserrat",
        },
      },
    },
  },
});

// orange gradient
// background: linear-gradient(218.12deg, #F76241 25.8%, #F7931E 133.27%);

// blue gradient
// background: linear-gradient(300.7deg, #1D3970 43.2%, #0B1529 119.28%);

// header fade
// background: linear-gradient(180deg, #1B2D51 0%, rgba(27, 45, 81, 0.61) 44.79%, rgba(27, 45, 81, 0) 100%);

// green to red gradient
// background: linear-gradient(90deg, #65C728 0%, #FFC800 48.44%, #F62447 100%);
