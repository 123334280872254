const environments = [
  'development',  // Local development environment
  'staging',      // Deployed staging environment
  'production'    // Deployed production environment
];

export type Environment = typeof environments[number];

if (!environments.includes(process.env.REACT_APP_ENVIRONMENT)) {
  //
  // TODO: what's the right way to surface an error here?
  //
  console.log(`ERROR: Invalid environment '${process.env.REACT_APP_ENVIRONMENT}'`);
}

const config: {
  environment: Environment,
  apiUrl: string,
  googleApiKey: string,
  googleAnalyticsCode?: string
} = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  apiUrl: process.env.REACT_APP_BACKEND_URL,
  googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  googleAnalyticsCode: process.env.REACT_APP_GOOGLE_ANALYTICS_CODE || ""
};

export default config;
