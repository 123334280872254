/** @jsxImportSource @emotion/react */
import { Box, FormLabel } from "@mui/material";
import { WaterHeaterFuelType } from "../../pages/propertyProfileForm/fieldInputOptions";
import { PropertyProfileSubformProps } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { FormGroup } from "../FormGroup";
import { RadioGroupWithUnsure } from "../inputs/RadioGroupWithUnsure";
import { TextSingleSelectDropdown } from "../inputs/TextSingleSelectDropdown";
import { FormErrorMsg } from "./FormErrorMessage";

export function WaterHeaterForm(props: PropertyProfileSubformProps) {
  const formValues = props.formik.values;
  const { formik } = props;
  return (
    <FormGroup
      sx={{ width: { lg: 550, sm: 450, xs: "100vw" } }}
      formLabel="Water Heater:"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: 1,
        }}
      >
        <FormLabel>Type of Water Heater:*</FormLabel>
        <Box
          sx={{
            gridColumn: "2/2",
            width: { lg: "50%", xs: "100%" },
          }}
        >
          <TextSingleSelectDropdown
            name="waterHeater.fuelType"
            error={
              formik?.touched?.waterHeater &&
              formik?.errors?.waterHeater?.fuelType
                ? true
                : false
            }
            value={formValues.waterHeater.fuelType}
            options={WaterHeaterFuelType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{
              width: "100%",
              gridColumn: "2/4",
            }}
          />
          {formik?.touched?.waterHeater && formik?.errors?.waterHeater && (
            <FormErrorMsg>{formik?.errors?.waterHeater?.fuelType}</FormErrorMsg>
          )}
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, gridColumn: "span 3" }}>
        <RadioGroupWithUnsure
          value={formValues.updatedWaterHeater}
          label={"Has your water heater been updated in the last 5 years?"}
          formik={formik}
          name={"updatedWaterHeater"}
        />
      </Box>
    </FormGroup>
  );
}
