export function LightningIcon(props: {
  size: number;
  color: string;
  id?: string;
}) {
  const { size, color, id = "lightningIcon" } = props;
  return (
    <svg
      id={id}
      width={size}
      height={size}
      viewBox="0 0 29 30"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6075 6.82086C14.0905 6.82086 13.6719 6.40217 13.6719 5.88522V1.71787C13.6719 1.20089 14.0906 0.782227 14.6075 0.782227C15.1233 0.782227 15.542 1.20092 15.542 1.71787V5.88522C15.542 6.4022 15.1233 6.82086 14.6075 6.82086Z"
        fill={color}
      />
      <path
        d="M21.118 9.50919C20.8705 9.50919 20.6326 9.41089 20.4567 9.23492C20.0926 8.86964 20.0926 8.27865 20.4567 7.91336L22.4493 5.91826C22.8146 5.55419 23.4068 5.55419 23.7721 5.92069C24.1374 6.28598 24.1362 6.87818 23.7709 7.24346L21.7661 9.24462C21.5926 9.4133 21.3596 9.50796 21.1181 9.50917L21.118 9.50919Z"
        fill={color}
      />
      <path
        d="M27.9821 16.0302H23.8172C23.3015 16.0302 22.8828 15.6115 22.8828 15.0958C22.8828 14.5788 23.3015 14.1602 23.8172 14.1602H27.9821C28.4979 14.1602 28.9165 14.5788 28.9165 15.0958C28.9165 15.6116 28.4978 16.0302 27.9821 16.0302Z"
        fill={color}
      />
      <path
        d="M23.1232 24.549C22.872 24.5526 22.628 24.4531 22.4508 24.2747L20.4582 22.2894C20.0929 21.9241 20.0917 21.3319 20.457 20.9666C20.8222 20.6001 21.4144 20.6001 21.7797 20.9642L23.7845 22.9689C24.0515 23.2359 24.1316 23.6376 23.9872 23.9871C23.8416 24.3366 23.5018 24.5648 23.1231 24.5648L23.1232 24.549Z"
        fill={color}
      />
      <path
        d="M6.08736 24.5494C5.70871 24.5494 5.36893 24.3213 5.22332 23.9717C5.07891 23.6234 5.159 23.2205 5.42598 22.9536L7.42714 20.9488C7.60068 20.7631 7.84095 20.6563 8.09458 20.6515C8.34822 20.6478 8.59337 20.7461 8.77296 20.9257C8.95257 21.1053 9.05087 21.3493 9.04602 21.6029C9.04238 21.8565 8.93437 22.098 8.74869 22.2703L6.74754 24.2751C6.57279 24.4511 6.33494 24.5494 6.08736 24.5494L6.08736 24.5494Z"
        fill={color}
      />
      <path
        d="M5.39205 16.0302H1.22471C0.707723 16.0302 0.289062 15.6115 0.289062 15.0958C0.289062 14.5788 0.707754 14.1602 1.22471 14.1602H5.39205C5.90783 14.1602 6.32649 14.5788 6.32649 15.0958C6.32649 15.6116 5.9078 16.0302 5.39205 16.0302Z"
        fill={color}
      />
      <path
        d="M8.08946 9.50999C7.84068 9.50999 7.60283 9.41169 7.42807 9.23573L5.42692 7.23821C5.06163 6.87292 5.06042 6.28072 5.4257 5.91422C5.79099 5.54893 6.38319 5.54772 6.74848 5.91301L8.74963 7.91416C9.11492 8.27945 9.11492 8.87044 8.74963 9.23573C8.57488 9.41169 8.33703 9.50999 8.08946 9.50999L8.08946 9.50999Z"
        fill={color}
      />
      <path
        d="M14.6063 29.3533C14.0905 29.3533 13.6719 28.9346 13.6719 28.4176V24.254C13.6719 23.737 14.0906 23.3184 14.6063 23.3184C15.1233 23.3184 15.542 23.7371 15.542 24.254V28.4176C15.542 28.9346 15.1233 29.3533 14.6063 29.3533Z"
        fill={color}
      />
      <path
        d="M18.2307 15.0675C18.2307 15.2823 18.1554 15.4898 18.0195 15.6561C18.0025 15.6828 17.9807 15.7071 17.9564 15.7277L12.5768 21.111C12.2079 21.4411 11.646 21.4253 11.2953 21.077C10.9446 20.7287 10.9252 20.1668 11.2528 19.7955L15.0393 16.0019H11.9229C11.5443 16.0019 11.2045 15.7737 11.0601 15.4242C10.9145 15.0759 10.9946 14.6742 11.2616 14.4061L16.6352 9.02275C17.0017 8.65868 17.5939 8.65868 17.9592 9.02518C18.3233 9.39047 18.3233 9.98267 17.9568 10.348L14.1728 14.1319H17.2904C17.3499 14.1331 17.4093 14.138 17.4676 14.1477C17.9105 14.2302 18.2321 14.6173 18.2309 15.0676L18.2307 15.0675Z"
        fill={color}
      />
    </svg>
  );
}
