export function getThermsAndKwh(
  gasHeat: boolean,
  gasWaterHeater: boolean,
  gasStove: boolean,
  hasCooling: boolean,
  hasHeatPump: boolean
) {
  // start with a base of 8000 kWh of electricity
  let electricUsage = 8000;
  // and a base consumption of 0 therms of natural gas
  let therms = 0;

  // Are you adding a heat pump? then this goes down to
  if (gasHeat) {
    // if gas heating add to therms
    therms += 553;
  } else if (hasHeatPump) {
    // else assume electric heat pump
    electricUsage += 2500;
  } else {
    // assume inefficient electric heat
    electricUsage += 16000;
  }

  if (gasWaterHeater) {
    // if gas water heater add to therms
    therms += 225;
  } else if (hasHeatPump) {
    // heat pump hot water heater
    electricUsage += 1100;
  } else {
    // else assume electric water heater
    electricUsage += 4000;
  }

  // Does not take induction into account right now
  if (gasStove) {
    // if gas stove add to therms
    therms += 33;
  } else {
    // else assume electric stovetop
    electricUsage += 500;
  }

  if (hasHeatPump) {
    // cooling with heatPump
    electricUsage += 550;
  } else if (hasCooling) {
    electricUsage += 1800; // central air
  }

  return { therms, electricUsage };
}

export function yearlyElectrifiedEnergyUsage() {
  const { electricUsage } = getThermsAndKwh(false, false, false, true, true);

  return electricUsage;
}

export function convertTonsToBarrels(tons: number) {
  return Math.round(tons / 0.35);
}

export function calculateEnergyIntensityScore(
  heatingSystemFuelType: string,
  waterHeaterFuelType: string,
  stoveTopFuelType: string,
  hasCooling: boolean,
  hasHeatPump: boolean,
  sqft: number
) {
  // start with 8000 kWh
  const gasHeat = heatingSystemFuelType === "natural gas";
  const gasWaterHeater = waterHeaterFuelType === "natural gas";
  const gasStove = stoveTopFuelType === "natural gas";
  let { therms, electricUsage } = getThermsAndKwh(
    gasHeat,
    gasWaterHeater,
    gasStove,
    hasCooling,
    hasHeatPump
  );

  electricUsage += therms * 29.3;

  // convert kWh to kBTU
  // divide by square footage
  const result = (electricUsage * 3.4128) / sqft;
  return Math.round(result);
}

export function calculateHomeEnergyMix(
  heatingSystemFuelType: string,
  waterHeaterFuelType: string,
  stoveTopFuelType: string,
  hasCooling: boolean,
  hasHeatPump: boolean
) {
  const gasHeat = heatingSystemFuelType === "natural gas";
  const gasWaterHeater = waterHeaterFuelType === "natural gas";
  const gasStove = stoveTopFuelType === "natural gas";
  const { therms, electricUsage } = getThermsAndKwh(
    gasHeat,
    gasWaterHeater,
    gasStove,
    hasCooling,
    hasHeatPump
  );

  const thermkWh = therms * 29.3;
  const totalkWh = electricUsage + thermkWh;
  return {
    naturalGas: Math.round((thermkWh / totalkWh) * 100),
    electric: Math.round((electricUsage / totalkWh) * 100),
  };
}

export function yearlyCO2ProductionInTons(
  heatingSystemFuelType: string,
  waterHeaterFuelType: string,
  stoveTopFuelType: string,
  hasCooling: boolean,
  hasHeatPump: boolean
) {
  const gasHeat = heatingSystemFuelType === "natural gas";
  const gasWaterHeater = waterHeaterFuelType === "natural gas";
  const gasStove = stoveTopFuelType === "natural gas";

  const { therms, electricUsage } = getThermsAndKwh(
    gasHeat,
    gasWaterHeater,
    gasStove,
    hasCooling,
    hasHeatPump
  );
  // convert kWh to lbs and convert therms to lbs
  // add both then divide by 2000
  const result = (electricUsage * 1.045 + therms * 11.7) / 2000;
  return Math.round(result);
}

export function potentialCO2Reduction(
  heatingSystemFuelType: string,
  waterHeaterFuelType: string,
  stoveTopFuelType: string,
  hasCooling: boolean,
  hasHeatPump: boolean
) {
  const electricOnly = yearlyCO2ProductionInTons(
    "electricity",
    "electricity",
    "electricity",
    true,
    true
  );
  const current = yearlyCO2ProductionInTons(
    heatingSystemFuelType,
    waterHeaterFuelType,
    stoveTopFuelType,
    hasCooling,
    hasHeatPump
  );

  return Math.round(current - electricOnly);
}

export function potentialEnergyEfficiencyImprovement(
  heatingSystemFuelType: string,
  waterHeaterFuelType: string,
  stoveTopFuelType: string,
  hasCooling: boolean,
  hasHeatPump: boolean
) {
  const { electricUsage: electricOnlyUsage } = getThermsAndKwh(
    false,
    false,
    false,
    true,
    true
  );

  const gasHeat = heatingSystemFuelType === "natural gas";
  const gasWaterHeater = waterHeaterFuelType === "natural gas";
  const gasStove = stoveTopFuelType === "natural gas";
  let { therms, electricUsage } = getThermsAndKwh(
    gasHeat,
    gasWaterHeater,
    gasStove,
    hasCooling,
    hasHeatPump
  );
  const currentUseage = electricUsage + therms * 29.3;

  return (
    Math.round((100 * (currentUseage - electricOnlyUsage)) / currentUseage) /
    100
  );
}

export function potentialDiscounts(chosenToggles: string[]) {
  let totalDiscounts = 0;

  chosenToggles.includes("heatPumpHvac") && (totalDiscounts += 5700); // Denver Discount - Heat Pump HVAC and Xcel ASHP Discount
  chosenToggles.includes("heatPumpWaterHeater") && (totalDiscounts += 2550); // Denver Discount - Heat Pump Water Heater and Xcel HPWH Discount
  chosenToggles.includes("solar") &&
    (chosenToggles.includes("heatPumpHvac") ||
      chosenToggles.includes("heatPumpWaterHeater") ||
      chosenToggles.includes("vehicleCharger")) &&
    (totalDiscounts += 4000); // Denver Discount - Solar
  chosenToggles.includes("storageBattery") &&
    !chosenToggles.includes("heatPumpHvac") &&
    !chosenToggles.includes("heatPumpWaterHeater") &&
    (totalDiscounts += 500); // Denver Discount - Smaller Battery Rebate
  chosenToggles.includes("storageBattery") &&
    (chosenToggles.includes("heatPumpHvac") ||
      chosenToggles.includes("heatPumpWaterHeater")) &&
    (totalDiscounts += 2750); // Denver Discount - Larger Battery Rebate
  chosenToggles.includes("vehicleCharger") && (totalDiscounts += 1000); // Denver Discount = EV Charger
  chosenToggles.includes("electricalServiceUpgrade") &&
    (totalDiscounts += 2000); // Denver Discount - Electrical Service Upgrade

  return totalDiscounts;
}

export function potentialRebates(chosenToggles: string[]) {
  return 0;
}

export function heatPumpHvacAndHpwhTaxCredits(chosenToggles: string[]) {
  let totalHeatPumpHvacAndHpwhTaxCredits = 0;

  if (
    chosenToggles.includes("heatPumpHvac") &&
    chosenToggles.includes("heatPumpWaterHeater")
  ) {
    // 30% up to $2000 maximum from Federal Tax Credit on a $9,700 HP and $4310 HPWH equipment cost assumptions plus max state tax credit of $1240 (10% of project cost)
    totalHeatPumpHvacAndHpwhTaxCredits += 3240;
  } else if (chosenToggles.includes("heatPumpHvac")) {
    totalHeatPumpHvacAndHpwhTaxCredits += 2970;
  } else if (chosenToggles.includes("heatPumpWaterHeater")) {
    // 30% up to $2000 maximum from Federal Tax Credit on a $7515 project cost and $4314 equipment cost assumption with max state tax credit of $1240 (10% of project cost)
    totalHeatPumpHvacAndHpwhTaxCredits += 1921;
  }
  return totalHeatPumpHvacAndHpwhTaxCredits;
}

export function insulationTaxCredits(chosenToggles: string[]) {
  let totalInsulationTaxCredits = 0;
  // 30% up to $1200 maximum from Federal Tax Credit on a >$4000 project cost assumption
  chosenToggles.includes("insulation") && (totalInsulationTaxCredits += 1200);
  return totalInsulationTaxCredits;
}

export function solarTaxCredits(chosenToggles: string[]) {
  let totalSolarTaxCredits = 0;
  if (
    chosenToggles.includes("heatPumpHvac") ||
    chosenToggles.includes("heatPumpWaterHeater")
  ) {
    chosenToggles.includes("solar") && (totalSolarTaxCredits += 6300); // 30% from Federal Tax Credit on a $25,000 project cost assumption. Also assuming Denver incentive is applied.
  } else {
    chosenToggles.includes("solar") && (totalSolarTaxCredits += 7500); // 30% from Federal Tax Credit on a $25,000 project cost assumption without Denver incentive qualifications
  }
  return totalSolarTaxCredits;
}

export function electricalServiceUpgradeTaxCredits(chosenToggles: string[]) {
  let totalElectricalServiceUpgradeTaxCredits = 0;
  chosenToggles.includes("solar") &&
    chosenToggles.includes("electricalServiceUpgrade") &&
    (totalElectricalServiceUpgradeTaxCredits += 600); // 30% from Federal Tax Credit on a $4000 project cost assumption when solar is chosen, also assumes Denver resident
  return totalElectricalServiceUpgradeTaxCredits;
}

export function batteryStorageTaxCredits(chosenToggles: string[]) {
  let totalBatteryStorageTaxCredits = 0;
  if (
    chosenToggles.includes("heatPumpHvac") ||
    chosenToggles.includes("heatPumpWaterHeater")
  ) {
    if (chosenToggles.includes("solar")) {
      chosenToggles.includes("storageBattery") &&
        (totalBatteryStorageTaxCredits += 6490); // 30% from Federal Tax Credit and 10% State Tax Credit on a $18,975 project cost assumption
    } else {
      chosenToggles.includes("storageBattery") &&
        (totalBatteryStorageTaxCredits += 1623); // 10% State Tax Credit on a $18,975 project cost assumption
    }
  } else {
    if (chosenToggles.includes("solar")) {
      chosenToggles.includes("storageBattery") &&
        (totalBatteryStorageTaxCredits += 7390); // 30% from Federal Tax Credit and 10% State Tax Credit on a $18,975 project cost assumption
    } else {
      chosenToggles.includes("storageBattery") &&
        (totalBatteryStorageTaxCredits += 1848); // 10% State Tax Credit on a $18,975 project cost assumption
    }
  }
  return totalBatteryStorageTaxCredits;
}
export function potentialTaxCredits(chosenToggles: string[]) {
  let totalTaxCredits = 0;
  let totalHeatPumpHvacAndHpwhTaxCredits =
    heatPumpHvacAndHpwhTaxCredits(chosenToggles);
  let totalInsulationTaxCredits = insulationTaxCredits(chosenToggles);
  let totalSolarTaxCredits = solarTaxCredits(chosenToggles);
  let totalElectricalServiceUpgradeTaxCredits =
    electricalServiceUpgradeTaxCredits(chosenToggles);
  let totalBatteryStorageTaxCredits = batteryStorageTaxCredits(chosenToggles);
  // These tax credit estimates combine federal and state tax incentives with average project cost assumptions
  totalTaxCredits +=
    totalHeatPumpHvacAndHpwhTaxCredits +
    totalInsulationTaxCredits +
    totalSolarTaxCredits +
    totalElectricalServiceUpgradeTaxCredits +
    totalBatteryStorageTaxCredits;
  // chosenToggles.includes("vehicleCharger") && (totalTaxCredits += 600); // 30% up to $1000 maximum from Federal Tax Credit

  return totalTaxCredits;
}

export function renderCurrencyNumber(number: number) {
  return Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "usd",
    maximumSignificantDigits: 2,
  }).format(number);
}

export function renderDetailedCurrencyNumber(number: number) {
  return Intl.NumberFormat("en-us", {
    style: "currency",
    currency: "usd",
  }).format(number);
}

export function totalPotentialIncentives(
  potentialDiscounts: number,
  potentialRebates: number,
  potentialTaxCredits: number
) {
  return potentialDiscounts + potentialRebates + potentialTaxCredits;
}
