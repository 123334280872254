/** @jsxImportSource @emotion/react */
import { Typography, Box } from "@mui/material";
import { Card } from "../../components/Card";
import { SalesIntakeFormValues } from "./SalesIntakeForm";
import { FormikProps } from "formik";
import { FormTextField } from "./FormTextField";
import { RadioRow } from "./RadioRow";

export function MechanicalRoomAndGarage(props: {
  form: FormikProps<SalesIntakeFormValues>;
}) {
  const { form } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", margin: 2 }}>
      <Box sx={{ marginRight: 1 }}>
        <Card>
          <Typography variant="h4">Garage</Typography>
          {RadioRow("Do you have a garage?", "hasGarage", form)}
          {RadioRow("Is your garage attached?", "garageLocation", form, [
            "attached",
            "detached",
          ])}
          <FormTextField
            label="How many parking spaces are in the garage"
            fieldName="parkingSpaces"
            fieldType="number"
            form={form}
            placeholder="1"
          />
          <FormTextField
            label="Garage square footage"
            fieldName="garageSqFt"
            fieldType="number"
            form={form}
            placeholder="200"
          />
          {RadioRow(
            "Describe the space above the garage",
            "spaceAboveGarage",
            form,
            ["unconditioned attic", "conditioned_area"]
          )}
        </Card>
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        <Card>
          <Typography variant="h4">Water heater</Typography>
          {RadioRow("Water heater fuel type", "waterHeaterFuelType", form, [
            "natural gas",
            "electricity",
          ])}
          <FormTextField
            label="Water heater tank volume"
            fieldName="waterHeaterTankVolume"
            fieldType="number"
            form={form}
            placeholder="68"
          />
          <FormTextField
            label="Water heater year installed"
            fieldName="waterHeaterYearInstalled"
            fieldType="number"
            form={form}
            placeholder="0"
          />
        </Card>
      </Box>
    </Box>
  );
}
