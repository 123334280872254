/** @jsxImportSource @emotion/react */
import { Box, FormLabel } from "@mui/material";
import { FormikErrors } from "formik";
import { PropertyProfileSubformProps } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { CoolingTypeOptions } from "../../pages/propertyProfileForm/fieldInputOptions";
import { FormGroup } from "../FormGroup";
import { RadioGroupWithUnsure } from "../inputs/RadioGroupWithUnsure";
import { TextSingleSelectDropdown } from "../inputs/TextSingleSelectDropdown";
import { FormErrorMsg } from "./FormErrorMessage";

export function CoolingForm(props: PropertyProfileSubformProps) {
  const formValues = props.formik.values;
  const { formik } = props;

  return (
    <FormGroup
      sx={{ width: { lg: 550, sm: 450, xs: "100vw" } }}
      formLabel="Cooling:"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: 1,
        }}
      >
        <FormLabel>Main Cooling System:*</FormLabel>
        <Box
          sx={{
            gridColumn: "2/2",
            width: { lg: "50%", xs: "100%" },
          }}
        >
          <TextSingleSelectDropdown
            name={`coolingSystems[${0}].coolingSystemType`}
            error={!!formik?.errors?.coolingSystems}
            value={formValues.coolingSystems[0].coolingSystemType}
            options={CoolingTypeOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{
              width: "100%",
              gridColumn: "2/4",
            }}
          />
          {!!formik?.errors?.coolingSystems && (
            <FormErrorMsg>
              {
                (
                  formik.errors?.coolingSystems as FormikErrors<{
                    coolingSystemType: string;
                    yearInstalled: number;
                  }>[]
                )?.[0]?.["coolingSystemType"]
              }
            </FormErrorMsg>
          )}
        </Box>
      </Box>
      {formValues.coolingSystems[0].coolingSystemType !== "none" && (
        <Box sx={{ marginTop: 2, gridColumn: "span 3" }}>
          <RadioGroupWithUnsure
            value={formValues.updatedCoolingSystem}
            label={"Has your cooling system been updated in the last 5 years?"}
            formik={formik}
            name={"updatedCoolingSystem"}
          />
        </Box>
      )}
    </FormGroup>
  );
}
