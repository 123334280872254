/** @jsxImportSource @emotion/react */
import { Download, InfoOutlined } from "@mui/icons-material";
import { Box, Button, Link, Tooltip, Typography } from "@mui/material";
import { Card } from "../components/Card";
import { Design } from "../generated/graphql";
import { renderDetailedCurrencyNumber } from "../pages/homeSummary/homeSummaryData";
import { palette } from "../theme";
import { ProposalCollapsibleCard } from "./ProposalCollapsibleCard";

export function ProposalForm(props: {
  design: Pick<
    Design,
    "retoolDesignJson" | "designId" | "pdfUrl" | "solarDesignImageUrl"
  >;
}) {
  const { retoolDesignJson, pdfUrl = "#", solarDesignImageUrl } = props.design;
  return (
    <Card
      sx={{
        padding: 2,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Link
        id={"downloadProposal"}
        href={pdfUrl!}
        download
        target="_blank"
        css={{
          color: "inherit",
          paddingBottom: 0,
          marginRight: 2,
          textDecoration: "none",
          alignSelf: "flex-end",
        }}
      >
        <Button
          id={"downloadProposal"}
          color="secondary"
          size="small"
          variant="text"
          sx={{
            marginBottom: 1,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Download id={"downloadProposal"} />
          Download PDF
        </Button>
      </Link>
      <Box
        sx={{
          overflowY: "scroll",
          width: "100%",
          borderColor: palette.backgroundGray,
          borderStyle: "solid",
          borderRadius: 5,
          borderWidth: 1,
          flexGrow: 1,
        }}
      >
        {retoolDesignJson &&
          retoolDesignJson.map((category: any, i: number) => {
            const hasBundles = category.bundles.length > 0;
            const hasIncentives = category.incentives.length > 0;
            if (
              (hasBundles || hasIncentives) &&
              category.productCategory.name === "Solar"
            ) {
              return (
                <ProposalCollapsibleCard
                  category={category}
                  key={category.productCategory.productCategoryType}
                  solarDesignImageUrl={solarDesignImageUrl}
                />
              );
            } else if (hasBundles || hasIncentives) {
              return (
                <ProposalCollapsibleCard
                  category={category}
                  key={category.productCategory.productCategoryType}
                />
              );
            } else {
              return null;
            }
          })}
        <Pricing design={props.design} />
      </Box>
    </Card>
  );
}

export function Pricing(props: {
  design: Pick<Design, "retoolDesignJson" | "designId" | "pdfUrl">;
}) {
  const total = props.design.retoolDesignJson
    .map((c: any) =>
      c.bundles
        .map((b: any) =>
          parseFloat(b.bundle_price.replace("$", "").replace(",", ""))
        )
        .flat()
    )
    .flat()
    .reduce((sum: number, price: number) => sum + price, 0);

  const credits = props.design.retoolDesignJson
    .map((c: any) => c.incentives)
    .flat()
    .filter((i: any) => i.incentive_type === "tax credit")
    .reduce(
      (sum: number, incentive: any) =>
        sum + parseFloat(incentive.amount.replace("$", "").replace(",", "")),
      0
    );

  const rebates = props.design.retoolDesignJson
    .map((c: any) => c.incentives)
    .flat()
    .filter((i: any) => i.incentive_type === "rebate")
    .reduce(
      (sum: number, incentive: any) =>
        sum + parseFloat(incentive.amount.replace("$", "").replace(",", "")),
      0
    );

  const discounts = props.design.retoolDesignJson
    .map((c: any) => c.incentives)
    .flat()
    .filter((i: any) => i.incentive_type === "discount")
    .reduce(
      (sum: number, incentive: any) =>
        sum + parseFloat(incentive.amount.replace("$", "").replace(",", "")),
      0
    );

  const potentialUpfrontDiscounts = renderDetailedCurrencyNumber(discounts);
  const potentialRebates = renderDetailedCurrencyNumber(rebates);
  const potentialTaxCredits = renderDetailedCurrencyNumber(credits);
  const totalPotentialIncentives = renderDetailedCurrencyNumber(
    credits + rebates + discounts
  );
  const totalBeforeIncentives = renderDetailedCurrencyNumber(total);
  const totalAfterIncentives = renderDetailedCurrencyNumber(
    total - credits - rebates - discounts
  );
  return (
    <Box sx={{ p: 2, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Tooltip
            placement="right-start"
            title="Any upfront discounts will be subtracted from the total cost of your project and paid directly to Helio on your behalf."
          >
            <Typography variant="h5">
              Potential Upfront Discounts
              <InfoOutlined
                sx={{ width: "16px", marginLeft: 1, alignSelf: "baseline" }}
              />
            </Typography>
          </Tooltip>
        </Box>
        <Typography variant="h5" sx={{ color: "#65C728" }}>
          {potentialUpfrontDiscounts}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginY: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Potential Rebates</Typography>
          <Tooltip
            placement="right-start"
            title="Rebates are paid directly to the customer once the project has been completed. Don't worry-we'll file the paperwork for you!"
          >
            <InfoOutlined sx={{ width: "16px", marginLeft: 1 }} />
          </Tooltip>
        </Box>
        <Typography variant="h5" sx={{ color: "#65C728" }}>
          {potentialRebates}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          marginY: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Potential Tax Credits</Typography>
          <Tooltip
            placement="right-start"
            title="Tax credits are taken off of state and federal income taxes. Credit can be claimed once the customer files for taxes the year the work was completed. Helio will provide you with all the necessary paperwork for filing."
          >
            <InfoOutlined sx={{ width: "16px", marginLeft: 1 }} />
          </Tooltip>
        </Box>
        <Typography variant="h5" sx={{ color: "#65C728" }}>
          {potentialTaxCredits}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginY: 3,
        }}
      >
        <Typography variant="h5">Total Potential Incentives</Typography>
        <Typography variant="h5" sx={{ color: "#65C728" }}>
          {totalPotentialIncentives}
        </Typography>
      </Box>

      <hr
        css={{
          borderSize: 1,
          borderStyle: "solid",
          borderColor: palette.grey3,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginY: 3,
        }}
      >
        <Typography variant="h5">Total Before Incentives:</Typography>
        <Typography variant="h5" sx={{ color: palette.starfishOrange }}>
          {totalBeforeIncentives}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "end",
          marginY: 3,
        }}
      >
        <Typography variant="h5">Total After Incentives:</Typography>
        <Typography variant="h2" sx={{ color: palette.starfishOrange }}>
          {totalAfterIncentives}
        </Typography>
      </Box>
    </Box>
  );
}
