import * as React from "react";
import {
  Step,
  StepLabel,
  Stepper,
  Stack,
  StepConnector as MUIStepConnector,
  stepConnectorClasses,
  StepperProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import { palette } from "../theme";
import { Check } from "@mui/icons-material";

const StepConnector = styled(MUIStepConnector)(() => ({
  top: 8,
  left: "calc(-50% + 10px)",
  right: "calc(50% + 10px)",
  [`& .${stepConnectorClasses.line}`]: {
    height: 8,
    backgroundColor: "#fff",
    border: "none",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: `linear-gradient(218.12deg, ${palette.starfishOrange} 25.8%, ${palette.amberOrange} 133.27%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: palette.starfishOrange,
    },
  },
}));

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  zIndex: 1,
  width: 24,
  height: 24,
  borderRadius: "50%",
  borderColor: "#fff",
  borderWidth: 2,
  borderStyle: "solid",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: `linear-gradient(218.12deg, ${palette.starfishOrange} 25.8%, ${palette.amberOrange} 133.27%)`,
    borderStyle: "none",
  }),
  ...(ownerState.completed && {
    backgroundImage: `linear-gradient(218.12deg, ${palette.starfishOrange} 25.8%, ${palette.amberOrange} 133.27%)`,
    borderStyle: "none",
  }),
}));

const StyledCheck = styled(Check)`
  height: 18px;
  width: 18px;
`;

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ color: "white" }}
    >
      {(completed || active) && <StyledCheck />}
    </StepIconRoot>
  );
}

interface ProgressBarProps extends StepperProps {
  steps: String[];
}

export function ProgressBar(props: ProgressBarProps) {
  const { activeStep, steps } = props;

  return (
    <Stack sx={{ width: { md: "40%", sm: "80%", xs: "95%" } }} spacing={4}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<StepConnector />}
      >
        {steps.map((label, i) => (
          <Step key={i} sx={{ padding: 0, minWidth: 20 }}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
