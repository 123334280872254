/** @jsxImportSource @emotion/react */
import { useQuery } from "@apollo/client";
import { Box, Button, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import BillIcon from "../../assets/bill_icon.svg";
import BlueHelioLogo from "../../assets/blue_helio_logo.svg";
import { Card } from "../../components/Card";
import { EmployeesCard } from "../../components/EmployeesCard";
import {
  CustomTab,
  CustomTabs,
  TabNavigatorButtonGroup,
  TabsProps,
} from "../../components/Tabs";
import {
  NextStepsDocument,
  NextStepsQuery,
  Visit,
} from "../../generated/graphql";
import { visitVar } from "../../state/ReactiveVars";
import { openNewTab } from "../../utils";
import {
  bradPlunkettProfile,
  deanProfile,
  millieProfile,
  peterProfile,
  stephenProfile,
} from "./EmployeeProfiles";
import { PhotoUploadView } from "./PhotoUploadView";
import { VideoUploadView } from "./VideoUploadView";

export function MediaUploadTabs(props: TabsProps) {
  const { tabValue, tabHandleChange } = props;

  return (
    <Tabs
      sx={{ height: 70 }}
      value={tabValue}
      aria-label="basic tabs example"
      indicatorColor="secondary"
      onChange={tabHandleChange}
      centered
    >
      <CustomTab
        value={0}
        label={<p css={{ fontWeight: 700, fontSize: 20 }}>Photos</p>}
      />
      <CustomTab
        value={1}
        label={<p css={{ fontWeight: 700, fontSize: 20 }}>Videos</p>}
      />
    </Tabs>
  );
}

export function NextStepsView() {
  const [tabValue, setTabValue] = React.useState(0);
  const [videos, setVideos] = React.useState<string[]>([]);
  const [photos, setPhotos] = React.useState<string[]>([]);
  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const visitId = visitVar();
  const { data } = useQuery<NextStepsQuery>(NextStepsDocument, {
    variables: { visitId },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    setVideos(data?.visit?.videos?.map((video) => video!.cloudinaryUrl) || []);
    setPhotos(data?.visit?.photos?.map((photo) => photo!.cloudinaryUrl) || []);
  }, [data]);

  const setOwnerId = () => {
    let electrificationConsultant;
    if (data) {
      electrificationConsultant = data.visit.hubspotOwnerId;
      return electrificationConsultant;
    }
  };

  const setRepresentativeInformation = (id: Visit["hubspotOwnerId"]) => {
    switch (id) {
      case "229745767":
        return deanProfile;
      case "192303958":
        return millieProfile;
      case "193963553":
        return stephenProfile;
      case "543060904":
        return bradPlunkettProfile;
      case "operationsManager":
        return peterProfile;
      default:
        return millieProfile;
    }
  };
  if (!data) {
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Thanks for the introduction!
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        You’ll be hearing from us soon. But in the meantime, you can keep things
        moving along by providing us with your utility bill info.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: { lg: 1, md: 1, sm: 0, xs: 0 },
            marginBottom: 2,
            maxWidth: 500,
            alignItems: "center",
          }}
        >
          <Card>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">Your Helio Team</Typography>
              <img css={{ width: 60 }} src={BlueHelioLogo} alt="Helio logo" />
            </Box>
            <EmployeesCard
              salesEmployee={setRepresentativeInformation(setOwnerId())}
              projectManager={setRepresentativeInformation("operationsManager")}
            />
          </Card>
          <Card sx={{ marginY: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: 1,
                width: "100%",
                margin: 1,
              }}
            >
              <Typography sx={{ marginTop: 1 }} variant="h4">
                Utility Bills
              </Typography>
              <img src={BillIcon} alt="An Icon representing a receipt" />
            </Box>
            <Typography
              sx={{
                paddingRight: { lg: 20, md: 8, sm: 16, xs: 7 },
                marginBottom: 8,
                paddingLeft: 1,
              }}
              variant="body1"
            >
              Submitting your utility bills can help our experts size your solar
              PV system properly and maximize your upfront discounts and
              rebates.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                onClick={() => {
                  openNewTab(
                    "https://utilityapi.com/authorize/helio-home?utility=XCEL"
                  );
                }}
                color="info"
              >
                Sync Utility Bills
              </Button>
            </Box>
          </Card>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            marginLeft: { lg: 1, md: 1, sm: 0, xs: 0 },
            maxWidth: 500,
          }}
        >
          <Card>
            <CustomTabs
              showLabelsOnMobile={true}
              views={[
                <PhotoUploadView
                  visit={data.visit}
                  photos={photos}
                  setPhotos={setPhotos}
                />,
                <VideoUploadView
                  visit={data.visit}
                  videos={videos}
                  setVideos={setVideos}
                />,
              ]}
              tabIndex={tabValue}
              labels={
                <>
                  <MediaUploadTabs
                    tabValue={tabValue}
                    tabHandleChange={tabHandleChange}
                  />
                  <Typography
                    variant="body1"
                    sx={{ marginTop: 2, marginBottom: 4 }}
                  >
                    Our electrification experts will often ask customers for the
                    following photos and videos. If you have a few minutes, you
                    can save time by providing them now.
                  </Typography>
                </>
              }
            />
            <TabNavigatorButtonGroup
              max={1}
              tabValue={tabValue}
              tabHandleChange={tabHandleChange}
              nextLabels={["Videos", "Videos"]}
              backLabels={["Photos", "Photos"]}
            />
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
