/** @jsxImportSource @emotion/react */
import { useMutation } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GooglePlacesAutocomplete from "../../components/inputs/GooglePlacesAutocomplete";
import { Layout } from "../../components/Layout";
import {
  AddressInput,
  SubmitNewAddressDocument,
  SubmitNewAddressMutation,
} from "../../generated/graphql";
import { addressVar, leadSource, utmParams } from "../../state/ReactiveVars";
import { LoadingPage } from "../LoadingPage";
import { FooterLogos } from "./FooterLogos";
import { MainContent } from "./WelcomePageContent";
import { paths } from "../../paths";

export function WelcomePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // source is used for the legacy lead source feature
    const source = searchParams?.get("source");
    // prefer utm parameters instead (saved on the visit)
    const utmCampaign = searchParams?.get("utm_campaign");
    const utmTerm = searchParams?.get("utm_term");
    const utmId = searchParams?.get("utm_id");
    const utmMedium = searchParams?.get("utm_medium");
    const utmContent = searchParams?.get("utm_content");
    const utmSource = searchParams?.get("utm_source");

    leadSource(source);
    utmParams({
      utmCampaign,
      utmContent,
      utmId,
      utmMedium,
      utmSource,
      utmTerm,
    });
  }, [searchParams]);

  const [address, setAddress] = React.useState<AddressInput | null>(null);
  const [saveAddresses, { loading, data, error }] =
    useMutation<SubmitNewAddressMutation>(SubmitNewAddressDocument, {
      onCompleted: (data) => {
        addressVar({
          addressId: data.saveAddresses.addresses[0]?.addressId!,
          address: address!,
          locationDetails: data.saveAddresses.addresses[0]?.locationDetails,
        });
      },
    });
  if (error) {
    navigate(paths.getStarted().systemError());
  }

  function submitForm() {
    addressVar({
      ...addressVar(),
      addressId: "",
      address: address!,
    });
    saveAddresses({ variables: { input: [address] } });
  }

  if (data) {
    const path = data?.saveAddresses.addresses[0]?.locationDetails
      ?.currentSalesTerritoryContainsLocation
      ? paths.getStarted().yourHome()
      : paths.getStarted().outOfServiceArea();
    setTimeout(() => {
      navigate(path);
    }, 1500);
  }

  if (loading || data) {
    return <LoadingPage />;
  }

  const headerHeight = 104;

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          height: { md: `calc(100% - ${headerHeight}px)` },
          alignItems: "center",
        }}
      >
        <MainContent />
        <Box
          sx={{
            marginX: 8,
            marginY: 4,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            maxWidth: "40%",
            height: "fit-content",
            marginTop: { lg: 40, md: 20, xs: 2 },
          }}
        >
          <div
            css={{
              maxWidth: 500,
              alignSelf: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "white", marginBottom: 3 }}
            >
              Enter your address to start your home electrification journey.
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: "white", marginBottom: 2 }}
            >
              Your home address
            </Typography>
            <AddressForm
              onSubmit={submitForm}
              address={address}
              setAddress={setAddress}
            />
            <FooterLogos />
          </div>
        </Box>
      </Box>
    </Layout>
  );
}

export function AddressForm({
  setAddress,
  address,
  onSubmit,
}: {
  setAddress: (address: AddressInput) => void;
  address: AddressInput | null;
  onSubmit: () => void;
}) {
  return (
    <form onSubmit={onSubmit}>
      <GooglePlacesAutocomplete setAddress={setAddress} />
      <Box
        sx={{
          marginY: 4,
          display: "flex",
          justifyContent: {
            md: "flex-start",
            sm: "center",
            xs: "center",
          },
        }}
      >
        <Button
          color="primary"
          title="Submit"
          sx={{ paddingX: 6 }}
          disabled={!address}
          onClick={onSubmit}
          type="submit"
        >
          Submit
        </Button>
      </Box>
    </form>
  );
}
