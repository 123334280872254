/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { isEmpty, startCase } from "lodash";
import React from "react";
import HeatPumpIcon from "../../assets/heat_pump_icon.svg";
import WaterHeaterIcon from "../../assets/water_heater_icon.svg";
import { Card } from "../../components/Card";
import { HomeIllustrationImage } from "../../components/HomeIllustration";
import { LinearProgressBar } from "../../components/LinearProgressBar";
import {
  CoolingSystem,
  HeatingSystem,
  Maybe,
  ValidatedBuildingProfile,
} from "../../generated/graphql";

// We can develop a mapping of "old" ages per equipment type and situation
// But, frankly above 10 is replacement in all of our areas, 15 is my assumption of oldest here
function equipmentAgeProgressBarValue(age: number, lifespan: number) {
  if (age < lifespan) {
    return (age / lifespan) * 100;
  } else {
    return 100;
  }
}

const CURRENT_YEAR = new Date().getFullYear();

const determineEquipmentSummaryCopy = (
  heatingSystemAge: number,
  waterHeaterAge: number
) => {
  if (heatingSystemAge < 5 && waterHeaterAge < 5) {
    return `Improve the efficiency and comfort of your home by enhancing your current equipment with the addition of insulation & air sealing, solar, storage batteries, EV chargers, or by updating your home’s ventilation. Click next to learn more about how Helio can help.`;
  } else if (heatingSystemAge > 5 && waterHeaterAge < 5) {
    return `Your HVAC is aging and will need to be replaced soon. Click next to learn more about how Helio can help you improve your home electrification journey.`;
  } else if (heatingSystemAge < 5 && waterHeaterAge > 5) {
    return `Your Water Heater is aging and will need to be replaced soon. Click next to learn more about how Helio can help you improve your home electrification journey.`;
  } else {
    return `Your equipment is aging and should be replaced soon. Click next to learn more about how Helio can help you improve your home electrification journey.`;
  }
};

interface YourHomeViewProps {
  buildingProfile: Omit<
    Partial<ValidatedBuildingProfile>,
    "coolingSystems" | "heatingSystems"
  > & {
    coolingSystems?: Maybe<Partial<CoolingSystem>>[] | null;
    heatingSystems?: Maybe<Partial<HeatingSystem>>[] | null;
  };
}

export function YourHomeView(props: YourHomeViewProps) {
  const { buildingProfile } = props;
  const { waterHeater, heatingSystems, coolingSystems } = buildingProfile;
  const [homeIllustrationActiveIcons, setHomeIllustrationActiveIcons] =
    React.useState({ heatPump: false, waterHeater: false });
  const heatingSystemAge =
    CURRENT_YEAR - (heatingSystems?.[0]?.yearInstalled || CURRENT_YEAR - 10);
  const coolingSystemAge =
    CURRENT_YEAR - (coolingSystems?.[0]?.yearInstalled || CURRENT_YEAR - 10);
  const waterHeaterAge =
    CURRENT_YEAR - (waterHeater?.yearInstalled || CURRENT_YEAR - 10);
  const heatingSystemFuelType = heatingSystems?.[0]?.heatingSystemFuelType;
  const coolingSystemType = coolingSystems?.[0]?.coolingSystemType;
  const waterHeaterFuelType = waterHeater?.fuelType;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Your Home's Current Equipment
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        {determineEquipmentSummaryCopy(heatingSystemAge, waterHeaterAge)}
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Box>
          <HomeIllustrationImage activeIcons={homeIllustrationActiveIcons} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginY: 3,
            alignItems: "center",
            width: { md: "50%", sm: "420px", xs: "350px" },
          }}
        >
          {!isEmpty(heatingSystems) && (
            <div
              onMouseOver={() => {
                setHomeIllustrationActiveIcons({
                  heatPump: true,
                  waterHeater: false,
                });
              }}
              onMouseLeave={() => {
                setHomeIllustrationActiveIcons({
                  heatPump: false,
                  waterHeater: false,
                });
              }}
              css={{ width: "100%", marginBottom: 32 }}
            >
              <Card>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="h4">Current Heating System</Typography>
                    {heatingSystemFuelType && (
                      <p css={{ marginBottom: 5 }}>
                        Energy Source: {startCase(heatingSystemFuelType)}
                      </p>
                    )}
                  </div>
                  <Box>
                    <img src={HeatPumpIcon} alt="Heat Pump Icon" />
                  </Box>
                </div>
                <LinearProgressBar
                  labelText={`If ${heatingSystemAge} years old`}
                  subLabelText={"Expected Lifespan of 15 Years"}
                  progressPercent={equipmentAgeProgressBarValue(
                    heatingSystemAge,
                    15
                  )}
                />
                <Typography variant="h5" sx={{ marginTop: 2 }}>
                  Cost to replace with similar gas system: $10,000
                </Typography>
              </Card>
            </div>
          )}
          {!isEmpty(coolingSystems) && (
            <div
              onMouseOver={() => {
                setHomeIllustrationActiveIcons({
                  heatPump: true,
                  waterHeater: false,
                });
              }}
              onMouseLeave={() => {
                setHomeIllustrationActiveIcons({
                  heatPump: false,
                  waterHeater: false,
                });
              }}
              css={{ width: "100%", marginBottom: 32 }}
            >
              <Card>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="h4">Current Cooling System</Typography>
                    {coolingSystemType && (
                      <p css={{ marginBottom: 0 }}>
                        System Type: {startCase(coolingSystemType)}
                      </p>
                    )}
                  </div>
                  <Box>
                    <img src={HeatPumpIcon} alt="Heat Pump Icon" />
                  </Box>
                </div>
                <LinearProgressBar
                  labelText={`If ${coolingSystemAge} years old`}
                  subLabelText={"Expected Lifespan of 15 Years"}
                  progressPercent={equipmentAgeProgressBarValue(
                    coolingSystemAge,
                    15
                  )}
                />
                <Typography variant="h5" sx={{ marginTop: 2 }}>
                  Cost to replace with similar system: $10,000
                </Typography>
              </Card>
            </div>
          )}
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: true,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: false,
              });
            }}
            css={{ width: "100%" }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography variant="h4">Current Water Heater</Typography>
                  {waterHeaterFuelType && (
                    <p css={{ marginBottom: 5 }}>
                      Energy Source: {startCase(waterHeaterFuelType)}
                    </p>
                  )}
                </div>
                <Box>
                  <img src={WaterHeaterIcon} alt="Water Heater Icon" />
                </Box>
              </div>
              <LinearProgressBar
                labelText={`If ${waterHeaterAge} years old`}
                subLabelText={"Expected Lifespan of 10 Years"}
                progressPercent={equipmentAgeProgressBarValue(
                  waterHeaterAge,
                  10
                )}
              />
              <Typography variant="h5" sx={{ marginTop: 2 }}>
                Cost to replace gas equipment: $5,000
              </Typography>
            </Card>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
