/** @jsxImportSource @emotion/react */
import { Button, Typography } from "@mui/material";
import { palette } from "../../theme";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../paths";

export function SalesPropertyConfirmation() {
  const { dealId, validatedProfileId } = useParams();
  const navigate = useNavigate();

  return (
    <div css={{ marginBottom: 40 }}>
      <Typography
        variant="h2"
        role="title"
        sx={{
          marginTop: { sm: -10, xs: 6 },
          marginBottom: { xs: 4 },
          paddingRight: { sm: 0, xs: 8 },
          fontSize: { sm: 36, xs: 38 },
          color: palette.darkBlue,
        }}
      >
        The property profile has been updated
      </Typography>
      <Typography
        variant="body1"
        role="body"
        sx={{
          marginBottom: 7,
          marginTop: { sm: 4 },
          color: palette.blueOpaque,
        }}
      >
        Your changes will sync to Hubspot automatically.
      </Typography>
      <Button
        color="primary"
        css={{ marginTop: 16, padding: `10px 30px` }}
        onClick={() => {
          navigate(paths.sales().profile(dealId, validatedProfileId));
        }}
      >
        Make another change to the profile
      </Button>
    </div>
  );
}
