/** @jsxImportSource @emotion/react */
import { Box, FormControlLabel, Typography, styled } from "@mui/material";
import { StyledSwitch as Switch } from "../../pages/electrification/Slider";
import { UpgradeToggleState } from "../../state/ReactiveVars";

const LabelContainer = styled(Box)((props) => ({
  display: "flex",
  width: "100%",

  alignItems: "center",
  [props.theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [props.theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

interface ToggleSectionProps {
  // The location of the icon used for this section
  icon?: string;
  // The name of the toggle
  toggle?: keyof UpgradeToggleState;
  // Callback for handling toggle change
  onChange: () => void;
  // The price for this section
  price?: string;
  // The longer text explaination for this section
  label?: string;
  // The short name of this section
  name: string;
  // The current state of the toggle
  checked: boolean;
}

export function ToggleSection(props: ToggleSectionProps) {
  const { icon, toggle, onChange, price, label, name, checked } = props;
  return (
    <>
      <FormControlLabel
        sx={{
          marginBottom: 0,
          alignContent: "flex-start",
          width: "100%",
          ".MuiFormControlLabel-label": {
            width: "100%",
          },
        }}
        name={toggle}
        control={
          <Switch onChange={onChange} checked={checked} sx={{ margin: 1 }} />
        }
        label={
          <LabelContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginLeft: 1,
              }}
            >
              {icon && (
                <img
                  css={{ height: "25px" }}
                  src={icon}
                  alt={`Icon for ${name}`}
                />
              )}
              <Typography
                variant="h5"
                sx={{
                  marginLeft: 1,
                  whiteSpace: "nowrap",
                  paddingRight: 1,
                }}
              >
                {name}
              </Typography>
            </Box>
            <Typography
              variant="h6"
              sx={{
                textAlign: { lg: "right", md: "right", xs: "center" },
                width: "100%",
                fontWeight: "light",
              }}
            >
              {price}
            </Typography>
          </LabelContainer>
        }
      />
      <Typography
        sx={{ marginBottom: 3, fontSize: 14, fontStyle: "italic" }}
        variant="body1"
      >
        {label}
      </Typography>
    </>
  );
}
