/** @jsxImportSource @emotion/react */

import { Box, FormLabel, TextField, Typography } from "@mui/material";
import { SalesIntakeFormQuery } from "../../generated/graphql";
import { palette } from "../../theme";
import {
  BuildingTypeOptions,
} from "../propertyProfileForm/fieldInputOptions";
import { startCase } from "lodash";
import { SalesIntakeFormValues } from "./SalesIntakeForm";
import { FormikProps } from "formik";

const HomeSummaryHr = () => {
  return (
    <Box sx={{ width: 100, marginY: 2 }}>
      <hr
        css={{
          border: "solid 1px",
          borderColor: palette.blueOpaque,
        }}
      />
    </Box>
  );
};

export function SalesIntakeSidebar(props: {
  buildingProfile: SalesIntakeFormQuery;
  form: FormikProps<SalesIntakeFormValues>;
}) {
  const { form } = props;
  const buildingProfile = props.buildingProfile.validatedBuildingProfile;
  const address = props.buildingProfile.validatedBuildingProfile.visit?.address;
  const customer =
    props.buildingProfile.validatedBuildingProfile.visit?.customer;
  const addressText = `${address?.addressLine1}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
  const {
    bedroomCount,
    bathroomCount,
    buildingType,
    conditionedFloorAreaSqFt,
    floorsAboveGrade,
    yearBuilt,
    yearBuiltEffective,
  } = buildingProfile;

  return (
    <Box
      sx={{
        color: palette.white,
        padding: { lg: 2, md: 2, s: 0, xs: 0 },
        display: "flex",
        flexDirection: "column",
        textAlign: { md: "start", sm: "center", xs: "center" },
        width: "100%",
        alignItems: {
          md: "flex-start",
          sm: "center",
          xs: "center",
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "Exo",
          fontSize: "20px",
          fontWeight: 600,
          marginTop: 3,
        }}
      >
        {customer?.firstName} {customer?.lastName}
      </Typography>
      <HomeSummaryHr />
      {addressText && (
        <Typography sx={{ fontWeight: 600 }} variant="body1">
          {addressText}
        </Typography>
      )}
      <HomeSummaryHr />
      <Typography variant="body1">
        {bedroomCount} Beds • {bathroomCount} Baths •{" "}
        {conditionedFloorAreaSqFt &&
          new Intl.NumberFormat().format(conditionedFloorAreaSqFt)}{" "}
        Sq. Ft
      </Typography>
      <Typography sx={{ marginY: 1 }} variant="body1">
        {buildingType &&
          `${floorsAboveGrade} Story ${startCase(
            BuildingTypeOptions[buildingType]
          )} Built in ${yearBuilt}`}
      </Typography>
      {!!yearBuiltEffective && yearBuilt !== yearBuiltEffective && (
        <Typography variant="body1">
          Remodeled in {yearBuiltEffective}
        </Typography>
      )}
      <HomeSummaryHr />
      <FormLabel sx={{ marginBottom: 1, color: palette.white }}>
        Design Notes:
      </FormLabel>
      <TextField
        multiline
        name="designNotes"
        value={form.values.designNotes || ""}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        rows={7}
        fullWidth
        size={"small"}
      />
    </Box>
  );
}
