import { Box, FormLabel, TextField } from "@mui/material";
import { FormikProps } from "formik";
import { FormErrorMsg } from "../../components/forms/FormErrorMessage";
import { SalesIntakeFormValues } from "./SalesIntakeForm";

export function FormTextField(props: {
  form: FormikProps<SalesIntakeFormValues>;
  fieldName: keyof SalesIntakeFormValues;
  fieldType: string;
  placeholder: string;
  label: string;
}) {
  const { fieldName, form, placeholder, fieldType, label } = props;
  const defaultValue = "";
  return (
    <Box sx={{ marginTop: 2 }}>
      <FormLabel>{label}</FormLabel>
      <TextField
        name={fieldName}
        type={fieldType}
        error={!!form.touched[fieldName] && !!form.errors[fieldName]}
        placeholder={placeholder}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        value={form.values[fieldName] || defaultValue}
        size={"small"}
        sx={{
          width: "100%",
          gridColumn: "2/4",
        }}
      />
      {form.touched[fieldName] && form.errors[fieldName] && (
        <FormErrorMsg>{form.errors[fieldName]}</FormErrorMsg>
      )}
    </Box>
  );
}
