/** @jsxImportSource @emotion/react */
import React from "react";
import { Header } from "./Header";
import { RoadmapProgressBar } from "./RoadmapProgressBar";
import { Box } from "@mui/material";

export function Layout({ children }: { children?: React.ReactNode }) {
  return (
    <div css={{ height: "100%" }}>
      <Header>
        <Box
          sx={{
            marginY: { xs: 2, sm: 3 },
            display: "flex",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <RoadmapProgressBar />
        </Box>
      </Header>
      {children}
    </div>
  );
}
