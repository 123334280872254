/** @jsxImportSource @emotion/react */
import { useReactiveVar } from "@apollo/client";
import { Button, Container, Typography } from "@mui/material";
import { addressVar } from "../state/ReactiveVars";
import { palette } from "../theme";

export const EmailConfirmation = () => {
  const inServiceArea =
    useReactiveVar(addressVar).locationDetails
      ?.currentSalesTerritoryContainsLocation;
  const selectBodyText = inServiceArea
    ? "One of our Electrification Consultants will reach out to discuss next steps with you soon."
    : `We’ll reach out when we expand to ${addressVar().address?.city}, ${
        addressVar().address?.state
      }.`;

  const selectHeader = inServiceArea ? "Thank you" : "See You Soon!";

  return (
    <div>
      <Typography
        variant="h2"
        title="email-confirmation-title"
        role="title"
        sx={{
          marginTop: { sm: -10, xs: 6 },
          marginBottom: { xs: 4 },
          paddingRight: { sm: 0, xs: 8 },
          fontSize: { sm: 36, xs: 38 },
          color: palette.darkBlue,
        }}
      >
        {selectHeader}
      </Typography>
      <Typography
        variant="body1"
        role="body"
        sx={{
          marginBottom: 7,
          marginTop: { sm: 4 },
          paddingRight: { lg: 15, sm: 0 },
          color: palette.blueOpaque,
        }}
      >
        {selectBodyText}
      </Typography>
      <Container
        sx={{
          marginY: 4,
          marginLeft: { sm: -5 },
          display: "flex",
          justifyContent: { sm: "center", xs: "flex-end" },
        }}
      >
        <Button
          color="primary"
          css={{ marginTop: 16, padding: `10px 30px` }}
          onClick={() => {
            window.location.href = "https://www.heliohome.io/";
          }}
        >
          Return Home
        </Button>
      </Container>
    </div>
  );
};
