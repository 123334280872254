import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const FormErrorMsg = styled(Typography)(() => ({
  gridColumn: "2/4",
  color: "red",
  fontFamily: "Exo",
  fontSize: 14,
  fontWeight: 600,
}));
