/** @jsxImportSource @emotion/react */
import { useMutation, useReactiveVar } from "@apollo/client";
import { InfoOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormGroup,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import _, { isEqual, pickBy } from "lodash";
import React, { forwardRef, useEffect, useMemo } from "react";
import BatteryIcon from "../../assets/icons/battery_orange_icon.svg";
import ElectricalServiceUpgradeIcon from "../../assets/icons/electrical_service_upgrade_orange_icon.svg";
import EvChargerIcon from "../../assets/icons/ev_charger_orange_icon.svg";
import HeatPumpIcon from "../../assets/icons/heat_pump_orange_icon.svg";
import InductionStoveIcon from "../../assets/icons/induction_stove_orange_icon.svg";
import InsulationIcon from "../../assets/icons/insulation_orange_icon.svg";
import SolarIcon from "../../assets/icons/solar_orange_icon.svg";
import HomeVentilationIcon from "../../assets/icons/ventilation_orange_icon.svg";
import WaterHeaterIcon from "../../assets/icons/water_heater_orange_icon.svg";
import {
  SaveElectrificationEngagementDocument,
  SaveElectrificationEngagementMutation,
} from "../../generated/graphql";
import {
  potentialDiscounts,
  potentialRebates,
  potentialTaxCredits,
  renderCurrencyNumber,
  totalPotentialIncentives,
} from "../../pages/homeSummary/homeSummaryData";
import {
  UpgradeToggleState,
  currentTogglesVar,
  electrificationEngagementVar,
  energyMeasures,
  setToggleReactiveVar,
} from "../../state/ReactiveVars";
import { palette } from "../../theme";
import { Card } from "../Card";
import { LoanCalculator } from "../loanCalculator/LoanCalculator";
import { ToggleSection } from "./ToggleSection";

interface PricingFormValuesItem {
  low: number;
  high: number;
}

interface PricingFormValues {
  heatPumpHvac: PricingFormValuesItem;
  heatPumpWaterHeater: PricingFormValuesItem;
  insulation: PricingFormValuesItem;
  solar: PricingFormValuesItem;
  storageBattery: PricingFormValuesItem;
  vehicleCharger: PricingFormValuesItem;
  homeVentilation: PricingFormValuesItem;
  inductionStove: PricingFormValuesItem;
  electricalServiceUpgrade: PricingFormValuesItem;
}

export function EquipmentPricingForm() {
  const [saveElectrificationEngagement] =
    useMutation<SaveElectrificationEngagementMutation>(
      SaveElectrificationEngagementDocument
    );

  const [projectTotal, setProjectTotal] = React.useState({
    low: 0,
    high: 0,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const currentToggles = useReactiveVar(currentTogglesVar);
  // Use 30,000 as a fallback if we don't have solar potential
  const solarPriceLow = _.round(
    (useReactiveVar(energyMeasures).solarPotential?.totalSolarPriceInCents ||
      0) / 100 || 30000,
    -3
  );
  const solarPriceHigh = solarPriceLow * 1.2;

  function determinePricingFormValues(
    solarPriceLow: number,
    solarPriceHigh: number
  ) {
    return {
      heatPumpHvac: { low: 19000, high: 24000 },
      heatPumpWaterHeater: { low: 7500, high: 8500 },
      insulation: { low: 2500, high: 8000 },
      solar: { low: solarPriceLow, high: solarPriceHigh },
      storageBattery: { low: 19000, high: 27000 },
      vehicleCharger: { low: 1500, high: 1600 },
      homeVentilation: { low: 1400, high: 5000 },
      inductionStove: { low: 1500, high: 1600 },
      electricalServiceUpgrade: { low: 2700, high: 7500 },
    };
  }

  const pricingFormValues: PricingFormValues = useMemo(
    () => determinePricingFormValues(solarPriceLow, solarPriceHigh),
    [solarPriceLow, solarPriceHigh]
  );

  const currentTrueToggles = pickBy(currentToggles, (k, _v) => k === true);
  const togglesList = [
    "heatPumpHvac",
    "heatPumpWaterHeater",
    "insulation",
    "solar",
    "storageBattery",
    "vehicleCharger",
    "homeVentilation",
    "inductionStove",
    "electricalServiceUpgrade",
  ];
  const allTogglesTrue = isEqual(
    Object.keys(currentTrueToggles).sort(),
    togglesList.sort()
  );

  const toggleVariableValues = (desiredToggleState: boolean) =>
    togglesList.reduce(
      (o, t) => ({ ...o, [t]: desiredToggleState }),
      {}
    ) as UpgradeToggleState;

  const toggleAllEquipment = (desiredToggleState: boolean) => {
    const toggleValues = toggleVariableValues(desiredToggleState);
    saveElectrificationEngagement({
      variables: {
        input: {
          electrificationEngagementId: electrificationEngagementVar(),
          ...toggleValues,
        },
      },
    });
    currentTogglesVar(toggleValues);
  };

  const {
    heatPumpHvac,
    heatPumpWaterHeater,
    solar,
    inductionStove,
    insulation,
    electricalServiceUpgrade,
    vehicleCharger,
    storageBattery,
    homeVentilation,
  } = currentToggles;

  const toggledUpgrades = pickBy(currentToggles, (k, _v) => k === true);
  const chosenToggles = Object.keys(toggledUpgrades);

  const totalPotentialIncentivesAmount = totalPotentialIncentives(
    potentialDiscounts(chosenToggles),
    potentialRebates(chosenToggles),
    potentialTaxCredits(chosenToggles)
  );

  useEffect(() => {
    // When any changes are made to the current toggles var
    const activeToggles = Object.keys(currentToggles).filter(
      (key) => currentToggles[key as keyof UpgradeToggleState]
    );
    const projectHighTotal = activeToggles.reduce((sum, toggle) => {
      return sum + pricingFormValues[toggle as keyof PricingFormValues].high;
    }, 0);
    const projectLowTotal = activeToggles.reduce((sum, toggle) => {
      return sum + pricingFormValues[toggle as keyof PricingFormValues].low;
    }, 0);
    setProjectTotal({ high: projectHighTotal, low: projectLowTotal });
  }, [currentToggles, pricingFormValues]);

  const LoanCalculatorComponent = forwardRef(
    function LoanCalculatorComponent() {
      return (
        <Box
          sx={{
            backgroundColor: palette.backgroundGray,
            borderRadius: "30px",
            padding: 2,
          }}
        >
          <Box sx={{ textAlign: "right" }}>
            <CloseIcon sx={{ width: "25px" }} onClick={handleClose} />{" "}
          </Box>
          <LoanCalculator
            projectTotalEstimate={
              projectTotal.low - totalPotentialIncentivesAmount
            }
          />
        </Box>
      );
    }
  );

  return (
    <Box
      sx={{
        flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
        display: "flex",
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          marginRight: { lg: 1, md: 1, sm: 0, xs: 0 },
          marginBottom: 2,
          maxWidth: 500,
        }}
      >
        <Card>
          <FormGroup>
            <ToggleSection
              icon={HeatPumpIcon}
              toggle={"heatPumpHvac"}
              price={`$${pricingFormValues.heatPumpHvac.low.toLocaleString()}-$${pricingFormValues.heatPumpHvac.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "heatPumpHvac",
                  saveElectrificationEngagement
                )
              }
              checked={heatPumpHvac}
              name={"Heat Pump HVAC"}
              label={
                "Heat pump HVAC systems vary in price due to the heat requirements of your home and how, or if, a new heat pump can integrate with your existing duct work."
              }
            />
            <ToggleSection
              icon={WaterHeaterIcon}
              toggle={"heatPumpWaterHeater"}
              price={`$${pricingFormValues.heatPumpWaterHeater.low.toLocaleString()}-$${pricingFormValues.heatPumpWaterHeater.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "heatPumpWaterHeater",
                  saveElectrificationEngagement
                )
              }
              checked={heatPumpWaterHeater}
              name={"Heat Pump Water Heaters"}
              label={
                "Heat pump water heaters vary in price due to tank size. We will work with you to find the HPWH that best suits your needs. A heat pump water heater is equivalent to about seven solar panels in energy savings at one-sixth the price."
              }
            />
          </FormGroup>
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <FormGroup>
            <ToggleSection
              icon={SolarIcon}
              toggle={"solar"}
              price={`$${solarPriceLow.toLocaleString()}-$${solarPriceHigh.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar("solar", saveElectrificationEngagement)
              }
              checked={solar}
              name={"Solar PV System"}
              label={
                "PV System prices depend on the size of the array and any special considerations for your roof."
              }
            />
            <ToggleSection
              icon={BatteryIcon}
              toggle={"storageBattery"}
              price={`$${pricingFormValues.storageBattery.low.toLocaleString()}-$${pricingFormValues.storageBattery.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "storageBattery",
                  saveElectrificationEngagement
                )
              }
              checked={storageBattery}
              name={"Storage Batteries"}
              label={
                "Home storage batteries vary in price depending on capacity."
              }
            />
            <ToggleSection
              icon={EvChargerIcon}
              toggle={"vehicleCharger"}
              price={`$${pricingFormValues.vehicleCharger.low.toLocaleString()}-$${pricingFormValues.vehicleCharger.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "vehicleCharger",
                  saveElectrificationEngagement
                )
              }
              checked={vehicleCharger}
              name={"Electric Vehicle Chargers"}
              label={
                "The price range for wiring for electric vehicle chargers depends on the distance from the electrical panel, and the amperage required by the charger."
              }
            />
            <ToggleSection
              icon={ElectricalServiceUpgradeIcon}
              toggle={"vehicleCharger"}
              price={`$${pricingFormValues.electricalServiceUpgrade.low.toLocaleString()}-$${pricingFormValues.electricalServiceUpgrade.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "electricalServiceUpgrade",
                  saveElectrificationEngagement
                )
              }
              checked={electricalServiceUpgrade}
              name={"Electrical Service Upgrade"}
              label={
                "An electrical load analysis will be performed and determine if a panel upgrade is needed. Price depends on whether your home needs a subpanel, an upgrade to 200A, or smart capabilities."
              }
            />
          </FormGroup>
        </Card>
      </Box>
      <Box
        sx={{
          flexDirection: "column",
          marginLeft: { lg: 1, md: 1, sm: 0, xs: 0 },
          marginBottom: 1,
          maxWidth: 500,
        }}
      >
        <Card>
          <FormGroup>
            <ToggleSection
              icon={HomeVentilationIcon}
              toggle={"insulation"}
              price={`$${pricingFormValues.homeVentilation.low.toLocaleString()}-$${pricingFormValues.homeVentilation.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "homeVentilation",
                  saveElectrificationEngagement
                )
              }
              checked={homeVentilation}
              name={"Ventilation"}
              label={
                "Ventilation system prices vary due to the type of system, your preferences, and ventilation needs of your home."
              }
            />
            <ToggleSection
              icon={InsulationIcon}
              toggle={"insulation"}
              price={`$${pricingFormValues.insulation.low.toLocaleString()}-$${pricingFormValues.insulation.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "insulation",
                  saveElectrificationEngagement
                )
              }
              checked={insulation}
              name={"Insulation & Air Sealing "}
              label={
                "Proper insulation and air sealing are essential for keeping your home comfortable and energy-efficient. The price range varies depending on insulation type, the size of your home, and the level of air sealing required."
              }
            />
            <ToggleSection
              icon={InductionStoveIcon}
              toggle={"inductionStove"}
              price={`$${pricingFormValues.inductionStove.low.toLocaleString()}-$${pricingFormValues.inductionStove.high.toLocaleString()}`}
              onChange={() =>
                setToggleReactiveVar(
                  "inductionStove",
                  saveElectrificationEngagement
                )
              }
              checked={inductionStove}
              name={"Induction Range Wiring"}
              label={
                "The price for induction range wiring is generally a flat rate but may vary depending on distance from panel."
              }
            />
          </FormGroup>
        </Card>
        <Card sx={{ marginTop: 2 }}>
          <ToggleSection
            onChange={() => toggleAllEquipment(!allTogglesTrue)}
            checked={allTogglesTrue}
            name={"Toggle All Equipment"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Potential Upfront Discounts</Typography>
              <Tooltip
                placement="right-start"
                title="These discounts are assumed for Denver residents. Other geographies may have similar programs. Any upfront discounts will be subtracted from the total cost of your project and paid directly to Helio on your behalf."
              >
                <InfoOutlined sx={{ width: "16px", marginLeft: 1 }} />
              </Tooltip>
            </Box>
            <Typography variant="h5" sx={{ color: "#65C728" }}>
              {renderCurrencyNumber(potentialDiscounts(chosenToggles))}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginY: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Potential Tax Credits</Typography>
              <Tooltip
                placement="right-start"
                title="Tax credits are taken off of state and federal income taxes. Credit can be claimed once the customer files for taxes the year the work was completed. Helio will provide you with all the necessary paperwork for filing."
              >
                <InfoOutlined sx={{ width: "16px", marginLeft: 1 }} />
              </Tooltip>
            </Box>
            <Typography variant="h5" sx={{ color: "#65C728" }}>
              {renderCurrencyNumber(potentialTaxCredits(chosenToggles))}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginY: 3,
            }}
          >
            <Typography variant="h5">Total Potential Incentives</Typography>
            <Typography variant="h5" sx={{ color: "#65C728" }}>
              {renderCurrencyNumber(totalPotentialIncentivesAmount)}
            </Typography>
          </Box>

          <hr
            css={{
              borderSize: 1,
              borderStyle: "solid",
              borderColor: palette.grey3,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginY: 3,
            }}
          >
            <Typography variant="h5">Total Before Incentives:</Typography>
            <Typography variant="h5" sx={{ color: palette.starfishOrange }}>
              {`$${projectTotal.low.toLocaleString()}-$${projectTotal.high.toLocaleString()}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "end",
              marginY: 3,
            }}
          >
            <Typography variant="h5">Total After Incentives:</Typography>
            <Typography variant="h2" sx={{ color: palette.starfishOrange }}>
              {`$${(
                projectTotal.low - totalPotentialIncentivesAmount
              ).toLocaleString()}-$${(
                projectTotal.high - totalPotentialIncentivesAmount
              ).toLocaleString()}`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
              }}
            >
              <Typography variant="h5">Project Assumptions</Typography>
              <Tooltip
                placement="right-start"
                title="Project Assumptions: Incentive estimates assume a
            greater than $13,600 heat pump (36-48k BTU), $7,500 heat pump water
            heater, more than $4,000 in insulation, $25,000 in solar, $19,000 in
            storage battery, $1,500 in vehicle charger, and $4,000 in electrical
            service upgrade project costs on average for a median-sized home."
              >
                <InfoOutlined sx={{ width: "16px", marginLeft: 1 }} />
              </Tooltip>
            </Box>
            <Button onClick={handleOpen} sx={{ marginTop: 1 }} color="info">
              Loan Calculator
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="loan-calculator"
              aria-describedby="loan-calculator"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginY: "auto",
                padding: 3,
              }}
            >
              {<LoanCalculatorComponent />}
            </Modal>
          </Box>
        </Card>
      </Box>
    </Box>
  );
}
