/** @jsxImportSource @emotion/react */
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, styled, Tab, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { StyledButtonContainer } from "../proposal/roadmap/ProposalRoadmapCard";
import { palette } from "../theme";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface HomeSummaryProps {
  views: ReactElement[];
  tabIndex: number;
  labels: ReactNode;
  showLabelsOnMobile?: Boolean;
}

export interface TabsProps {
  tabValue: number;
  tabHandleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
export function CustomTabs(props: HomeSummaryProps) {
  const { views, tabIndex, labels, showLabelsOnMobile } = props;

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: {
            xs: showLabelsOnMobile ? "initial" : "none",
            sm: showLabelsOnMobile ? "initial" : "none",
            md: "initial",
          },
        }}
      >
        {labels}
      </Box>
      {views.map((view, index) => (
        <TabPanel key={index} value={tabIndex ? tabIndex : 0} index={index}>
          {view}
        </TabPanel>
      ))}
    </Box>
  );
}

export function TabNavigatorButtonGroup(
  props: TabsProps & { max: number; nextLabels: string[]; backLabels: string[] }
) {
  const { tabValue, tabHandleChange, max, nextLabels, backLabels } = props;
  return (
    <Box
      sx={{
        display: { sm: "flex", xs: "none" },
        width: "100%",
        justifyContent: "space-evenly",
        marginY: 2,
      }}
    >
      <StyledButtonContainer sx={{ opacity: tabValue === 0 ? 0.3 : 1 }}>
        <IconButton
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          disabled={tabValue === 0}
          onClick={(e) => {
            if (tabValue > 0) {
              tabHandleChange(e, tabValue - 1);
            }
          }}
        >
          <ChevronLeft
            sx={{
              color: palette.starfishOrange,
            }}
          />
        </IconButton>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {backLabels[tabValue]}
        </Typography>
      </StyledButtonContainer>
      <StyledButtonContainer sx={{ opacity: tabValue === max ? 0.3 : 1 }}>
        <IconButton
          sx={{ display: "flex", flexDirection: "column" }}
          disabled={tabValue === max}
          onClick={(e) => {
            if (tabValue < max) {
              tabHandleChange(e, tabValue + 1);
            }
          }}
        >
          <ChevronRight
            sx={{
              color: palette.starfishOrange,
            }}
          />
        </IconButton>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          {nextLabels[tabValue]}
        </Typography>
      </StyledButtonContainer>
    </Box>
  );
}

export const CustomTab = styled(Tab)({
  color: palette.darkBlue,
  fontSize: 20,
  fontFamily: "Exo",
  fontWeight: 600,
  textTransform: "capitalize",
});
