import { MenuItem, Select, SelectProps } from "@mui/material";
import { startCase } from "lodash";
interface SelectPropsWithOptions extends SelectProps {
  options: { [key: string]: string };
}

export function TextSingleSelectDropdown(props: SelectPropsWithOptions) {
  const { options } = props;
  return (
    <Select displayEmpty size="small" {...props}>
      <MenuItem disabled={props.value !== ""} value="">
        <em>Please Select an Option</em>
      </MenuItem>
      {Object.keys(options).map((key) => (
        <MenuItem value={key} key={key}>
          {startCase(props.options[key])}
        </MenuItem>
      ))}
    </Select>
  );
}
