// Left side is the values for the option the right is display string

export const HeaterTypeOptions = {
  ElectricResistance: "electric resistance",
  Furnace: "natural gas furnace",
  WallFurnace: "natural gas wall furnace",
  FloorFurnace: "natural gas floor furnace",
  Boiler: "natural gas boiler",
  Stove: "pellet stove",
  PortableHeater: "electric portable heater",
  FixedHeater: "natural gas fixed heater",
  Fireplace: "gas or wood fireplace",
  None: "none",
};

export enum CoolingTypeOptions {
  "central air conditioner" = "central air conditioner",
  "evaporative cooler" = "evaporative cooler",
  "mini-split" = "mini-split",
  "room air conditioner" = "room air conditioner",
  "packaged terminal air conditioner" = "packaged terminal air conditioner",
  "chiller" = "chiller",
  "none" = "none",
}

export enum FoundationOptions {
  basement = "basement",
  crawl_space = "crawl space",
  slab = "concrete slab",
}

export const BuildingTypeOptions: { [key: string]: string } = {
  "apartment unit": "apartment / condo",
  "manufactured home": "manufactured home",
  "single-family attached": "townhouse",
  "single-family detached": "single family home",
};

export const HeaterFuelTypeOptions = {
  electricity: "electricity",
  "natural gas": "natural gas",
  "fuel oil": "fuel oil",
  diesel: "diesel",
  propane: "propane",
  kerosene: "kerosene",
  coal: "coal",
  wood: "wood",
  "wood pellets": "wood pellets",
  NONE: "none",
};

export const StoveFuelTypeOptions: { [key: string]: string } = {
  electricity: "electricity",
  "natural gas": "natural_gas",
  propane: "propane",
  wood: "wood",
};

export const WaterHeaterFuelType = {
  electricity: "electricity",
  "fuel oil": "fuel oil",
  "natural gas": "natural gas",
  propane: "propane",
};

export const basementOptions = {
  no: "no",
  yesUnfinished: "yes, unfinished",
  yesFinished: "yes, finished",
};
