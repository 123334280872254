/** @jsxImportSource @emotion/react */
import { useQuery } from "@apollo/client";
import { Box } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { TabsWithSidebar } from "../components/TabsWithSidebar";
import { ElectrificationSummarySidebar } from "../pages/electrification/ElectrificationSummarySidebar";
import {
  CustomerPortalDocument,
  CustomerPortalQuery,
  ProposalDocument,
  ProposalQuery,
} from "../generated/graphql";
import { CustomerPortalView } from "./CustomerPortalView";

export function CustomerPortal() {
  const [checked, setChecked] = React.useState(true);
  const { customerPortalToken } = useParams();
  const { data } = useQuery<CustomerPortalQuery>(CustomerPortalDocument, {
    variables: { input: { token: customerPortalToken } },
  });

  const { data: proposalData } = useQuery<ProposalQuery>(ProposalDocument, {
    variables: { input: { proposalId: data?.customerPortal.proposalId } },
  });

  if (proposalData) {
    const { retoolDesignEnergyMeasures } = proposalData.proposal.selectedDesign;
    const { upgrade, baseline } = retoolDesignEnergyMeasures || {
      upgrade: null,
      baseline: null,
    };
    const hasSolar =
      proposalData.proposal.selectedDesign.retoolDesignJson.filter(
        (item) => item.productCategory.productCategoryType === "SOLAR"
      ).length > 0;

    return (
      <TabsWithSidebar
        sidebar={
          <ElectrificationSummarySidebar
            energyUsage={checked ? upgrade : baseline}
            displayToggle={true}
            solar={hasSolar}
            checked={checked}
            setChecked={setChecked}
          />
        }
        mainContent={<CustomerPortalView proposal={proposalData} />}
        progressBar={<Box sx={{ height: "50px" }}></Box>}
      />
    );
  }
  return (
    <TabsWithSidebar
      sidebar={<></>}
      mainContent={<CustomerPortalView />}
      progressBar={<Box sx={{ height: "50px" }}></Box>}
    />
  );
}
