/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { Photo } from "../../generated/graphql";

export function CustomerPhotos(props: { photos?: (Partial<Photo> | null)[] | null }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", sm: "column" },
        justifyContent: "center",
        maxWidth: "100%",
        flexWrap: "wrap",
        marginTop: 5,
      }}
    >
      {props.photos?.map((p) => {
        return (
          <img
            src={encodeURI(p?.cloudinaryUrl || "")}
            alt="customer mechanical room"
            css={{ objectFit: "cover", width: 350, margin: 2 }}
          />
        );
      })}
    </Box>
  );
}
