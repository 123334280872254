export function SolarIcon(props: { size: number; color: string; id?: string }) {
  const { size, color, id = "solarIcon" } = props;
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 30"
      width={size}
      height={size}
      fill={color}
    >
      <path d="M36.6227 1.531C36.4444 1.00371 35.9476 0.647074 35.3898 0.644531H7.60907C7.05122 0.647078 6.55449 1.00369 6.37621 1.531L0.703462 18.0754C0.565908 18.4728 0.632135 18.9135 0.876672 19.2573C1.12121 19.5987 1.51604 19.8025 1.93633 19.8025H20.1951V26.583L15.9234 26.5855C15.2025 26.5855 14.6192 27.1689 14.6192 27.8897C14.6192 28.6106 15.2025 29.1939 15.9234 29.1939H27.0756C27.7965 29.1939 28.3798 28.6106 28.3798 27.8897C28.3798 27.1689 27.7964 26.5855 27.0756 26.5855H22.8038V19.8024H41.0626C41.4829 19.8024 41.8777 19.5986 42.1222 19.2573C42.3668 18.9134 42.433 18.4727 42.2955 18.0754L36.6227 1.531ZM16.4408 17.1814L16.935 12.9631H26.0644L26.5458 17.1941L16.4408 17.1814ZM17.2228 10.3546L17.5362 7.59076H25.4631L25.7764 10.3546H17.2228ZM7.05661 7.59076H14.8818L14.594 10.3546H6.11667L7.05661 7.59076ZM24.9691 3.25534L25.1653 4.98236H17.8344L18.0305 3.25534H24.9691ZM28.087 7.59076H35.9428L36.8955 10.3546H28.4182L28.087 7.59076ZM35.0514 4.98236H27.7863L27.5902 3.25534L34.4575 3.2528L35.0514 4.98236ZM15.4095 3.2528L15.2133 4.97982L7.9483 4.98237L8.54182 3.25535L15.4095 3.2528ZM5.1976 12.9632H14.327L13.8456 17.1942H3.76345L5.1976 12.9632ZM29.1701 17.1942L28.6734 12.9632H37.8028L39.2497 17.1942H29.1701Z" />
    </svg>
  );
}
