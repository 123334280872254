/** @jsxImportSource @emotion/react */ import { useReactiveVar } from "@apollo/client";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Switch, Tooltip, Typography } from "@mui/material";
import { EnergyIntensityScoreLinearBar } from "../../components/LinearProgressBar";
import { EnergyUsage, Maybe, SolarPotential } from "../../generated/graphql";
import { addressVar } from "../../state/ReactiveVars";
import { palette } from "../../theme";
import { CircularProgressWithLabel } from "../homeSummary/CircularProgressWithLabel";

export type ElectrificationSummaryEnergyUsageKeys =
  | "co2Tons"
  | "electrifiedEnergyUsageKwh"
  | "energyEfficiencyImprovement"
  | "energyUseIntensityScore"
  | "solarPotential";
export type ElectrificationSummaryEnergyUsage = {
  [key in ElectrificationSummaryEnergyUsageKeys]: number;
};

export const formatSegmentData = (
  energyUsage: EnergyUsage
): ElectrificationSummaryEnergyUsage => {
  const {
    co2Tons,
    electrifiedEnergyUsageKwh,
    energyEfficiencyImprovement,
    energyUseIntensityScore,
    solarPotential,
  } = energyUsage;
  const response: ElectrificationSummaryEnergyUsage = {
    co2Tons: co2Tons || 0,
    electrifiedEnergyUsageKwh: electrifiedEnergyUsageKwh || 0,
    energyEfficiencyImprovement: energyEfficiencyImprovement || 0,
    energyUseIntensityScore: energyUseIntensityScore || 0,
    solarPotential: solarPotential?.estimatedSolarProduction || 0,
  };
  return response;
};

const SOLAR_PRODUCTION_HIGH = 0.59;
const SOLAR_PRODUCTION_LOW = 0.46;
const ElectrificationSummaryHr = () => {
  return (
    <Box sx={{ width: { lg: "80%", md: "100%", sm: "42%", xs: "90%" } }}>
      <hr
        css={{
          marginTop: 20,
          marginBottom: 20,
          width: "100%",
          border: "solid 1px",
          borderColor: palette.blueOpaque,
        }}
      />
    </Box>
  );
};

const ElectrificationAndBaselineToggle = (props: {
  checked?: boolean | undefined;
  setChecked?: (checked: boolean) => void | undefined;
}) => {
  const { checked = false, setChecked = () => {} } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        marginTop: 3,
      }}
    >
      <Typography
        sx={{
          color: palette.white,
          height: "fit-content",
          placeSelf: "center",
        }}
        variant="h6"
      >
        Current
      </Typography>
      <Switch
        color={"secondary"}
        onChange={() => {
          setChecked && setChecked(!checked);
        }}
        checked={checked}
      />
      <Typography
        sx={{
          color: palette.white,
          height: "fit-content",
          placeSelf: "center",
        }}
        variant="h6"
      >
        Electrification Potential
      </Typography>
    </Box>
  );
};

export function ElectrificationSummarySidebar(props: {
  energyUsage: ElectrificationSummaryEnergyUsage;
  displayToggle: boolean;
  checked?: boolean | undefined;
  setChecked?: (checked: boolean) => void | undefined;
  solar: boolean;
}) {
  const address = useReactiveVar(addressVar).address;
  const { displayToggle, checked, setChecked, solar, energyUsage } = props;

  if (!energyUsage || !energyUsage.electrifiedEnergyUsageKwh) {
    return <></>;
  }

  const {
    co2Tons,
    electrifiedEnergyUsageKwh,
    energyUseIntensityScore,
    solarPotential,
    energyEfficiencyImprovement,
  } = energyUsage;
  const co2Low = (co2Tons! * (1 - SOLAR_PRODUCTION_HIGH)).toFixed(1);
  const co2High = (co2Tons! * (1 - SOLAR_PRODUCTION_LOW)).toFixed(1);
  const setAddressText = () => {
    return `${address?.addressLine1}, ${address?.city}, ${address?.state} ${address?.postalCode}`;
  };
  // TODO: HEL-680 (Solar: Show solar range if solar is on but solarPotential in null)

  return (
    <Box
      sx={{
        color: palette.white,
        paddingX: { lg: 2, md: 2, s: 0, xs: 0 },
        display: "flex",
        flexDirection: "column",
        textAlign: { md: "start", sm: "center", xs: "center" },
        alignItems: "center",
        marginTop: { md: 0, xs: 8 },
      }}
    >
      <Typography
        sx={{ color: palette.white, textAlign: "center" }}
        variant="h4"
      >
        {setAddressText()}
      </Typography>
      {displayToggle && (
        <ElectrificationAndBaselineToggle
          checked={checked}
          setChecked={setChecked}
        />
      )}
      <ElectrificationSummaryHr />
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h5" color={palette.white}>
          CO<sub>2</sub> Production (yearly)
        </Typography>
        <Typography sx={{ color: palette.success }} variant="h2">
          {calculateCo2Tons(
            co2Tons,
            co2High,
            co2Low,
            solar,
            electrifiedEnergyUsageKwh,
            solarPotential
          )}
        </Typography>
        <Typography variant="h5" color={palette.white}>
          TONS
        </Typography>
      </Box>
      <ElectrificationSummaryHr />
      <Box>
        <Typography sx={{ color: palette.white }} variant="h5">
          Energy Use Intensity Score
          <Tooltip
            placement="right-start"
            title="Your energy use intensity score is calculated by dividing your home’s kBtus usage by its square footage. A low score means a more energy efficient home."
          >
            <InfoIcon sx={{ width: "15px" }} />
          </Tooltip>
        </Typography>
        <EnergyIntensityScoreLinearBar
          score={energyUseIntensityScore!}
          labelText={energyUseIntensityScore.toString()}
          darkBackground={true}
        />
      </Box>
      <ElectrificationSummaryHr />
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography variant="h5" color={palette.white}>
          Electrified Energy Usage (yearly)
        </Typography>
        <Typography variant="h2" color={palette.white}>
          {Math.round(electrifiedEnergyUsageKwh!).toLocaleString()}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Exo",
            fontSize: 12,
            fontHeight: 600,
            textAlign: "center",
          }}
        >
          KWH PER YEAR
        </Typography>
      </Box>
      <ElectrificationSummaryHr />
      <Box
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" color={palette.white}>
          Est. Solar Production (yearly)
        </Typography>
        <Typography variant="h2">
          {solar && solarPotential
            ? Math.round(solarPotential).toLocaleString()
            : 0}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Exo",
            fontSize: 12,
            fontHeight: 600,
            textAlign: "center",
          }}
        >
          KWH PER YEAR
        </Typography>
      </Box>
      <ElectrificationSummaryHr />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography sx={{ textAlign: "left", width: 60, paddingRight: 15 }}>
          Potential Energy Efficiency Improvement
        </Typography>
        <CircularProgressWithLabel
          variant="determinate"
          value={energyEfficiencyImprovement! * 100}
          labelcolor={`${palette.white}`}
        />
      </Box>
    </Box>
  );
}

function calculateCo2Tons(
  co2Tons?: Maybe<number>,
  co2TonsHigh?: Maybe<string>,
  co2TonsLow?: Maybe<string>,
  solar?: Maybe<boolean>,
  electrifiedEnergyUsageKwh?: Maybe<number>,
  solarPotential?: Maybe<SolarPotential["estimatedSolarProduction"]>
) {
  if (solarPotential && solar && electrifiedEnergyUsageKwh && co2Tons) {
    const productionDifference =
      (electrifiedEnergyUsageKwh - solarPotential) / electrifiedEnergyUsageKwh;
    const afterSolarCo2Tons = productionDifference * co2Tons;
    const currentCo2Tons = Math.max(afterSolarCo2Tons, 0);
    return currentCo2Tons.toFixed(1);
  } else if (solar) {
    return `${co2TonsLow} - ${co2TonsHigh}`;
  } else {
    return `${co2Tons}`;
  }
}
