import React from "react";
import { Box, Typography } from "@mui/material";
import { LoadingAnimation } from "../components/LoadingAnimation";
import { palette } from "../theme";
import LoadingScreenWaterHeater from "../assets/loading_screen_waterheater.svg";
import { Layout } from "../components/Layout";
import { useReactiveVar } from "@apollo/client";
import { addressVar } from "../state/ReactiveVars";

export function LoadingPage() {
  const addressText = useReactiveVar(addressVar).address?.addressLine1;
  return (
    <Box
      sx={{
        backgroundImage: `url(${LoadingScreenWaterHeater})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
        paddingBottom: { xs: "100vh" },
      }}
    >
      <Layout>
        <Box
          sx={{
            textAlign: "center",
            paddingBottom: { xs: "10vh" },
          }}
        >
          <Typography sx={{ marginTop: 5, color: palette.white }}>
            Locating your home data...
          </Typography>
          <Typography variant="h5" sx={{ marginY: 2, color: palette.white }}>
            {addressText}
          </Typography>
          <LoadingAnimation />
        </Box>
      </Layout>
    </Box>
  );
}
