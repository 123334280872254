export const paths = {
  getStarted: () => {
    return {
      root: () => "/getstarted/",
      yourHome: () => "/getstarted/yourhome",
      homeSummary: () => "/getstarted/homesummary",
      electrification: () => "/getstarted/electrification",
      emailConfirmation: () => "/getstarted/emailconfirmation",
      deal: (dealId: string, validatedProfileId: string) =>
        `/getstarted/yourhome/deal/${dealId}/profile/${validatedProfileId}`,
      contact: () => "/getstarted/contact",
      outOfServiceArea: () => "/getstarted/outofservicearea",
      salesProfileConfirmation: (dealId: string, validatedProfileId: string) =>
        `/getstarted/salesprofileconfirmation/deal/${dealId}/profile/${validatedProfileId}`,
      costs: () => "/getstarted/costs",
      systemError: () => "/getstarted/systemerror",
      nextSteps: () => "/getstarted/nextsteps",
    };
  },
  sales: () => {
    return {
      profile: (dealId: string, validatedProfileId: string) =>
        `/getstarted/yourhome/deal/${dealId}/profile/${validatedProfileId}`,
      intakeForm: (dealId: string, validatedProfileId: string) =>
        `/getstarted/sales/intakeform/deal/${dealId}/profile/${validatedProfileId}`,
      profileConfirmation: (dealId: string, validatedProfileId: string) =>
        `/getstarted/sales/profileconfirmation/deal/${dealId}/profile/${validatedProfileId}`,
    };
  },
  proposal: () => {
    return {
      proposal: (token: string) => `/proposal/${token}`,
    };
  },
  helioMarketingHomepage: () => "https://www.heliohome.io/",
};
