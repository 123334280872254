import { Box, BoxProps } from "@mui/material";
import React from "react";

export function Card(props: BoxProps) {
  const { sx, ...otherProps } = props;
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "20px",
        width: "100%",
        height: "fit-content",
        maxHeight: "100%",
        ...sx,
      }}
      {...otherProps}
    >
      {props.children}
    </Box>
  );
}
