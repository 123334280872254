/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { ReactComponent as HomeIllustration } from "../../assets/home_illustration.svg";
import { palette } from "../../theme";
import loadingScreenWaterHeater from "../../assets/loading_screen_waterheater.svg";
import solarBackground from "../../assets/solar_background.svg";
export const MainContent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        backgroundColor: "rgba(245,245,245, 1)",
        borderRadius: "0px 60px 60px 0",
        marginRight: 2,
        padding: { xs: 1, sm: 4 },
        height: { sm: "fit-content", md: "fit-content", lg: "100%" },
        justifyContent: "space-around",
        width: { xs: "fit-content" },
        maxWidth: { xl: "60vw", xs: "98vw" },
      }}
    >
      <Box
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          paddingY: 2,
          flexDirection: "column",
          maxHeight: "100%",
        }}
      >
        <Typography
          variant="h2"
          sx={{ color: palette.darkBlue, marginBottom: { lg: 10 } }}
        >
          Take the Next Step Towards an Electrified Home
        </Typography>
        <HomeIllustration
          title="home_illustration"
          css={{ minWidth: "40vw", maxWidth: "100%" }}
        />
      </Box>
      {/* This is a workaround to preload these images on a page before they are needed.
     These should be preloaded in a better manner  */}
      <div css={{ display: "none" }}>
        <img src={solarBackground} alt="solar panels in sunlight" />
        <img
          src={loadingScreenWaterHeater}
          alt="water heater in utility closet"
        />
      </div>
    </Box>
  );
};
