import { Box, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { PropertyProfileFormValues } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { palette } from "../../theme";

export function TextFieldWithUnitOfMeasurement(props: {
  formik: FormikProps<PropertyProfileFormValues>;
  type: string;
  name: string;
  unitOfMeasurement: string;
}) {
  const { formik, type, name, unitOfMeasurement } = props;
  const formValues = formik.values;

  return (
    <Box
      sx={{
        width: "100%",
        gridColumn: "span 2",
        position: "relative",
      }}
    >
      <TextField
        type={type}
        name={name}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formValues[name as keyof PropertyProfileFormValues]}
        size="small"
        sx={{
          width: "100%",
        }}
      />
      <Typography
        sx={{
          backgroundColor: palette.grey3,
          height: 35.5,
          borderRadius: "4px",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          paddingX: 1,
          bottom: 8.15,
          right: 2,
          width: "fit-content",
        }}
        variant="body2"
      >
        {unitOfMeasurement}
      </Typography>
    </Box>
  );
}
