import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { apolloClient } from "./apolloConfig";
import config from "./config/config";
import "./index.css";
import { featureFlags } from "./state/ReactiveVars";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _env_: any;
  }
}

const client = apolloClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const shouldTrackEngagement = (config.environment === "production");
const shouldTrackGoogleAdsConversions = (config.environment === "production");
const shouldShowHubspotChatBot = !featureFlags().hubspotChatBot.hideIn.includes(config.environment);

root.render(
  <BrowserRouter>
    <Helmet>
      {/* Start Google Analytics */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsCode}`}
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag() {dataLayer.push(arguments);}
        gtag("js", new Date());
        gtag("config", "${config.googleAnalyticsCode}");`}
      </script>
      {/* End Google Analytics */}
      {/* Start Google Tag Manager */}
      {shouldTrackEngagement && (
        <script>
          {`(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-K6V3V47");`}
        </script>
      )}
      {shouldTrackGoogleAdsConversions && (
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','googleAdsDataLayer','GTM-PHTZXXDB');`}</script>
      )}
      {/* End Google Tag Manager  */}
      {shouldShowHubspotChatBot && (
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js-na1.hs-scripts.com/22542630.js"
        ></script>
      )}
    </Helmet>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </React.StrictMode>
  </BrowserRouter>
);
