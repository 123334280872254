/** @jsxImportSource @emotion/react */
import { Box, Button, Typography } from "@mui/material";
import { palette } from "../theme";
import { openNewTab } from "../utils";

export interface EmployeeInfo {
  employeeImage: string;
  name: string;
  title: string;
  description: string;
  phoneNumber: string;
  email: string;
  calendlyLink?: string;
}

export function EmployeeImage(props: {
  employeeName: string;
  employeeImage: string;
}) {
  const { employeeImage } = props;
  return (
    <>
      <Box sx={{ height: 240, marginX: { lg: 2, md: 1 } }}>
        <Box
          sx={{
            backgroundColor: palette.starfishOrange,
            borderRadius: 5,
            height: 200,
            width: 200,
            position: "relative",
          }}
        >
          <div
            css={{
              background: `url(${employeeImage}) no-repeat`,
              backgroundSize: "cover",
              maxWidth: "100%",
              position: "relative",
              top: -16,
              borderRadius: 20,
              width: 200,
              height: 216,
            }}
          >
            <div
              css={{
                background: `linear-gradient(rgba(0,0,0,0) 55%, ${palette.darkBlue})`,
                backgroundSize: "cover",
                maxWidth: "100%",
                position: "relative",
                top: 16,
                borderRadius: 20,
                height: 200,
                width: 200,
              }}
            ></div>
          </div>
        </Box>
      </Box>
    </>
  );
}

export function EmployeeSummary(props: { employeeInformation: EmployeeInfo }) {
  const { employeeInformation } = props;

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { lg: "row", xs: "column" },
          alignItems: { lg: "inherit", xs: "center" },
        }}
      >
        <EmployeeImage
          employeeName={employeeInformation.name}
          employeeImage={employeeInformation?.employeeImage}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { lg: "inherit", xs: "center" },
          }}
        >
          <Typography variant="h4">{employeeInformation.name}</Typography>
          <Typography variant="body1">{employeeInformation.title}</Typography>
          <Typography
            variant="body1"
            noWrap
            sx={{ color: palette.starfishOrange, width: "90%" }}
          >
            <a href={`mailto:${employeeInformation.email}?subject=Helio Home`}>
              {employeeInformation.email}
            </a>
          </Typography>
          <Typography variant="body1">
            {employeeInformation.phoneNumber}
          </Typography>
          {employeeInformation?.calendlyLink && (
            <Button
              onClick={() => {
                openNewTab(employeeInformation?.calendlyLink || "");
              }}
              color="primary"
              sx={{ paddingX: 2, marginTop: 1, marginBottom: 4, width: "90%" }}
            >
              Schedule Appointment
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function EmployeesCard(props: {
  salesEmployee: EmployeeInfo;
  projectManager: EmployeeInfo;
}) {
  const { salesEmployee, projectManager } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <EmployeeSummary employeeInformation={salesEmployee} />
      <Box sx={{ marginTop: 2 }}>
        <EmployeeSummary employeeInformation={projectManager} />
      </Box>
    </Box>
  );
}
