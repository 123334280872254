import { useReactiveVar } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { featureFlags } from "../state/ReactiveVars";
import { ProgressBar } from "./ProgressBar";

export enum StepName {
  START = "Start",
  YOUR_HOME = "Your Home",
  CUSTOMER_CONTACT = "Contact Information",
  ELECTRIFICATION = "Electrification",
  COSTS = "Costs",
  NEXT_STEPS = "Next Steps",
}

type ProgressStep = {
  step: number;
  label: StepName;
};

export const progressSteps: { [key: string]: ProgressStep } = {
  "/yourhome": { step: 1, label: StepName.YOUR_HOME },
  "/homesummary": { step: 1, label: StepName.YOUR_HOME },
  "/emailconfirmation": {
    step: 2,
    label: StepName.CUSTOMER_CONTACT,
  },
  "/contact": { step: 2, label: StepName.ELECTRIFICATION },
  "/electrification": { step: 3, label: StepName.ELECTRIFICATION },
  "/costs": { step: 4, label: StepName.COSTS },
  "/nextsteps": { step: 5, label: StepName.NEXT_STEPS },
};

export function RoadmapProgressBar() {
  const { shouldShowElectrification } = useReactiveVar(featureFlags);
  const location = useLocation();
  const pathMatch = Object.keys(progressSteps).find((path) =>
    location.pathname.includes(path)
  );
  const activeStep = pathMatch ? progressSteps[pathMatch].step : 0;

  function steps() {
    let progressBarSteps = Object.values(StepName);
    const noElectrificationOrCostOrNextSteps = progressBarSteps.filter(
      (label) =>
        label !== "Electrification" &&
        label !== "Costs" &&
        label !== "Next Steps"
    );
    if (!shouldShowElectrification) {
      progressBarSteps = noElectrificationOrCostOrNextSteps;
    }
    return progressBarSteps;
  }

  return <ProgressBar activeStep={activeStep} steps={steps()} />;
}
