/** @jsxImportSource @emotion/react */
import { useReactiveVar } from "@apollo/client";
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  styled,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import HeatPumpIcon from "../../assets/icons/heat_pump_orange_icon.svg";
import InsulationIcon from "../../assets/icons/insulation_orange_icon.svg";
import WaterHeaterIcon from "../../assets/icons/water_heater_orange_icon.svg";
import { Card } from "../../components/Card";
import { ElectrifyViewHomeIllustrationImage } from "../../components/ElectrifyViewHomeIllustration";
import { LinearProgressBar } from "../../components/LinearProgressBar";
import {
  ElectrificationSummaryQuery,
  MutationSaveElectrificationEngagementArgs,
} from "../../generated/graphql";
import {
  currentTogglesVar,
  setToggleReactiveVar,
  visitFormValuesVar,
} from "../../state/ReactiveVars";
import {
  LabelContainer,
  StyledElectrificationCardLabel,
} from "./Electrification";
import { StyledSwitch as Switch } from "./Slider";

export function ElectrifyView(props: {
  validatedBuildingProfile: ElectrificationSummaryQuery["validatedBuildingProfile"];
  saveElectrificationEngagement: (variables: {
    variables: MutationSaveElectrificationEngagementArgs;
  }) => Promise<any>;
}) {
  const CopyTextBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    margin: 8,
  });
  const { saveElectrificationEngagement } = props;
  const [homeIllustrationActiveIcons, setHomeIllustrationActiveIcons] =
    React.useState({ heatPump: false, waterHeater: false, insulation: false });

  useEffect(() => {
    if (
      visitFormValuesVar()?.updatedInsulation &&
      currentTogglesVar().insulation
    ) {
      setToggleReactiveVar("insulation", saveElectrificationEngagement);
    }
    if (
      visitFormValuesVar()?.hasServiceUpgrade &&
      currentTogglesVar().electricalServiceUpgrade
    ) {
      setToggleReactiveVar(
        "electricalServiceUpgrade",
        saveElectrificationEngagement
      );
    }
    if (visitFormValuesVar()?.hasSolar && currentTogglesVar().solar) {
      setToggleReactiveVar("solar", saveElectrificationEngagement);
    }
    if (
      visitFormValuesVar()?.hasStorageBattery &&
      currentTogglesVar().storageBattery
    ) {
      setToggleReactiveVar("storageBattery", saveElectrificationEngagement);
    }
    if (
      visitFormValuesVar()?.hasEvCharger &&
      currentTogglesVar().vehicleCharger
    ) {
      setToggleReactiveVar("vehicleCharger", saveElectrificationEngagement);
    }
  }, [saveElectrificationEngagement]);

  function equipmentAgeProgressBarValue(age: number, lifespan: number) {
    if (age < lifespan) {
      return (age / lifespan) * 100;
    } else {
      return 100;
    }
  }

  const validatedBuildingProfile = props.validatedBuildingProfile;
  const { waterHeater, heatingSystems, coolingSystems } =
    validatedBuildingProfile;
  const CURRENT_YEAR = new Date().getFullYear();
  const heatingSystemAge =
    CURRENT_YEAR - (heatingSystems?.[0]?.yearInstalled || CURRENT_YEAR);
  const coolingSystemAge =
    CURRENT_YEAR - (coolingSystems?.[0]?.yearInstalled || CURRENT_YEAR);
  const waterHeaterAge =
    CURRENT_YEAR - (waterHeater?.yearInstalled || CURRENT_YEAR);

  let hvacSystemAge;
  if (!isEmpty(heatingSystems) && !isEmpty(coolingSystems)) {
    hvacSystemAge = (heatingSystemAge + coolingSystemAge) / 2;
  } else if (!isEmpty(heatingSystems)) {
    hvacSystemAge = heatingSystemAge;
  } else if (!isEmpty(coolingSystems)) {
    hvacSystemAge = coolingSystemAge;
  } else {
    hvacSystemAge = 0;
  }

  const { heatPumpHvac, heatPumpWaterHeater, insulation } =
    useReactiveVar(currentTogglesVar);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Electrify Your Equipment:
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        Electrification can make your home cleaner, healthier, and more
        efficient. Below, you can learn about the benefits of how switching to
        high-efficiency electric systems can improve your home. See the figures
        to the right to discover the potential improvement of your carbon
        footprint and Energy Use Intensity score.
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Box>
          <ElectrifyViewHomeIllustrationImage
            activeIcons={homeIllustrationActiveIcons}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginY: 3,
            alignItems: "center",
            width: { md: "500px", sm: "420px", xs: "350px" },
          }}
        >
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                heatPump: true,
                waterHeater: false,
                insulation: false,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: false,
                insulation: false,
              });
            }}
            css={{ width: "100%", marginBottom: 32 }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    marginRight: 2,
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      sx={{ display: "flex", alignContent: "center" }}
                      control={
                        <Switch
                          sx={{ margin: 1 }}
                          checked={heatPumpHvac}
                          onChange={() =>
                            setToggleReactiveVar(
                              "heatPumpHvac",
                              saveElectrificationEngagement
                            )
                          }
                        />
                      }
                      label={
                        <LabelContainer>
                          <img
                            src={HeatPumpIcon}
                            alt={"Icon representing heat pump"}
                          />{" "}
                          <StyledElectrificationCardLabel>
                            Heat Pump HVAC
                          </StyledElectrificationCardLabel>
                        </LabelContainer>
                      }
                    />
                  </FormGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ margin: 1, marginBottom: 2 }}
                    >
                      Your Current <br />
                      HVAC System
                    </Typography>
                    <LinearProgressBar
                      labelText={`${hvacSystemAge} years`}
                      subLabelText={"Expected Lifespan of 15 Years"}
                      progressPercent={equipmentAgeProgressBarValue(
                        hvacSystemAge,
                        15
                      )}
                    />
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "50%",
                    }}
                  >
                    <CopyTextBox>
                      <Typography variant="h4">3x More Efficient</Typography>{" "}
                      than other heating alternatives
                    </CopyTextBox>
                    <CopyTextBox>
                      <Typography variant="h4">One Appliance</Typography> to
                      cool & heat your home
                    </CopyTextBox>
                    <CopyTextBox>
                      <Typography variant="h4">Runs Quiet</Typography> no loud
                      blasts from your furnace
                    </CopyTextBox>
                  </Box>
                </Box>
              </div>
            </Card>
          </div>
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: true,
                insulation: false,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: false,
                insulation: false,
              });
            }}
            css={{ width: "100%", marginBottom: 32 }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ margin: 1 }}
                          checked={heatPumpWaterHeater}
                          onChange={() =>
                            setToggleReactiveVar(
                              "heatPumpWaterHeater",
                              saveElectrificationEngagement
                            )
                          }
                        />
                      }
                      label={
                        <LabelContainer>
                          <img
                            src={WaterHeaterIcon}
                            alt={"Icon representing heat pump water heater"}
                          />{" "}
                          <StyledElectrificationCardLabel>
                            Heat Pump Water Heater
                          </StyledElectrificationCardLabel>
                        </LabelContainer>
                      }
                    />
                  </FormGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{ margin: 1, marginBottom: 2 }}
                    >
                      Your Current <br />
                      Water Heater
                    </Typography>
                    <LinearProgressBar
                      labelText={`${waterHeaterAge} years`}
                      subLabelText={"Expected Lifespan of 10 Years"}
                      progressPercent={equipmentAgeProgressBarValue(
                        waterHeaterAge,
                        10
                      )}
                    />
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "50%",
                    }}
                  >
                    <CopyTextBox>
                      <Typography variant="h4">3x More Efficient</Typography>{" "}
                      than other fuel alternatives
                    </CopyTextBox>
                    <CopyTextBox>
                      <Typography variant="h4">User-Friendly</Typography> with
                      vacation mode; control your water heater from anywhere
                    </CopyTextBox>
                  </Box>
                </Box>
              </div>
            </Card>
          </div>
          <div
            onMouseOver={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: false,
                insulation: true,
              });
            }}
            onMouseLeave={() => {
              setHomeIllustrationActiveIcons({
                heatPump: false,
                waterHeater: false,
                insulation: false,
              });
            }}
            css={{ width: "100%" }}
          >
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          sx={{ margin: 1 }}
                          checked={insulation}
                          onChange={() =>
                            setToggleReactiveVar(
                              "insulation",
                              saveElectrificationEngagement
                            )
                          }
                        />
                      }
                      label={
                        <LabelContainer>
                          <img
                            src={InsulationIcon}
                            alt={"Icon representing insuation and air sealing"}
                          />{" "}
                          <StyledElectrificationCardLabel>
                            Insulation & Air Sealing
                          </StyledElectrificationCardLabel>
                        </LabelContainer>
                      }
                    />
                  </FormGroup>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Divider orientation="vertical" flexItem />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: "50%",
                    }}
                  >
                    <CopyTextBox>
                      <Typography variant="h4">20-30% Reduction</Typography> of
                      heat loss
                    </CopyTextBox>
                    <CopyTextBox>
                      <Typography variant="h4">Reduced Energy Load</Typography>{" "}
                      to increase energy efficiency
                    </CopyTextBox>
                  </Box>
                </Box>
              </div>
            </Card>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
