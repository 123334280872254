/** @jsxImportSource @emotion/react */
import { Typography, Box } from "@mui/material";

export function NextSteps() {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
          margin: 2,
          height: "fit-content",
          maxHeight: "100%",
        }}
      >
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Energy model
        </Typography>
        <Typography variant="body1">
          We create an energy model of your home to size a quiet, efficient, and
          long lasting heating and cooling solution, water heating, insulation,
          solar...
        </Typography>
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Your electrification roadmap
        </Typography>
        <Typography variant="body1">
          You will receive a complete electrification roadmap with pricing -
          cash and financed - with all local, state, federal rebates listed.
          Also carbon savings and modeled usage for gas and electric
        </Typography>
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Questions
        </Typography>
        <Typography variant="body1">
          We answer any questions - email or phone
        </Typography>
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Deposit
        </Typography>
        <Typography variant="body1">50% deposit for work you want</Typography>
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Schedule work
        </Typography>
        <Typography variant="body1">
          meet at your home for pre-project walk-through with all partners
        </Typography>
        <Typography variant="h4" sx={{ marginTop: 2 }}>
          Change orders
        </Typography>
        <Typography variant="body1">
          If unforeseen details - asbestos, tiny crawl access... - change order.
          We try to keep these to minimum. Deposit is fully refundable so no
          obligation.
        </Typography>
        <Typography variant="h3" color="secondary" sx={{ marginTop: 4, textAlign: "center" }}>
          Electrify Your Life!
        </Typography>
      </Box>
    </Box>
  );
}
