/** @jsxImportSource @emotion/react */
import { Box, Divider, Typography } from "@mui/material";
import { NextStepsQuery } from "../../generated/graphql";
import { palette } from "../../theme";
import { CloudinaryUploadWidget } from "./UploadWidget";

export function PhotoUploadView(props: {
  visit: NextStepsQuery["visit"];
  photos: string[];
  setPhotos: (photo: string[]) => void;
}) {
  const { photos, setPhotos, visit } = props;
  const renderPhotos = () => {
    if (photos.length > 0) {
      return photos.map((photo, index) => (
        <img
          id={`uploadedImage${index}`}
          key={index}
          src={photo}
          alt="User uploaded pic"
          width="210px"
          height="210px"
          css={{ margin: 10 }}
        />
      ));
    }

    return (
      <Box
        sx={{
          height: "230px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Typography
          sx={{ height: "fit-content", placeSelf: "center" }}
          variant="body1"
        >
          Upload photos to see them here
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: palette.starfishOrange,
            margin: 2,
            marginLeft: 0,
          }}
        >
          Mechanical Room - Include your current water heater and your heating &
          cooling equipment.
        </Typography>
        <Box sx={{ marginBottom: 2 }}>
          <CloudinaryUploadWidget
            visit={visit}
            uploadType={"photo"}
            handleSave={setPhotos}
            media={photos}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: palette.starfishOrange,
            margin: 2,
            marginLeft: 0,
          }}
        >
          Main Electrical Panel - Whole panel with the cover closed with panel,
          billing meter, and mast if applicable.
        </Typography>
        <Box sx={{ marginBottom: 2 }}>
          <CloudinaryUploadWidget
            visit={visit}
            uploadType={"photo"}
            handleSave={setPhotos}
            media={photos}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: palette.starfishOrange,
            margin: 2,
            marginLeft: 0,
          }}
        >
          Main Electric Panel - Include legible photo of main breaker amperage
          rating and all breakers.
        </Typography>
        <Box sx={{ marginBottom: 2 }}>
          <CloudinaryUploadWidget
            visit={visit}
            uploadType={"photo"}
            handleSave={setPhotos}
            media={photos}
          />
        </Box>
      </Box>
      <Divider />
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="h4">Saved Photos:</Typography>
        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            display: "flex",
            marginTop: 2,
            height: "100%",
            background: palette.backgroundGray,
            borderRadius: 4,
          }}
        >
          {renderPhotos()}
        </Box>
      </Box>
    </>
  );
}
