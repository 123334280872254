import { Typography, Box } from "@mui/material";
import { palette } from "../../theme";
import { Visit } from "../../generated/graphql";

type SalesProfileFormHeaderProps = {
  visit: Pick<
    Visit,
    | "reasons"
    | "customer"
    | "address"
    | "customerAdditionalInformation"
    | "referral"
    | "electrificationGoals"
    | "leadSource"
  >;
};

export function SalesProfileFormHeader(props: SalesProfileFormHeaderProps) {
  const { visit } = props;
  const { customer, address } = visit;

  return (
    <Box
      sx={{
        backgroundColor: palette.backgroundGray,
        borderRadius: "20px",
        padding: "20px",
        width: { md: "860px", sm: "400px", xs: "350px" },
        margin: 2,
        marginX: "auto",
        height: "fit-content",
        maxHeight: "100%",
      }}
    >
      <Typography
        variant="h2"
        color={palette.darkBlue}
        sx={{ textAlign: "left", fontSize: { xs: "24px" } }}
      >
        Property Profile Sales View
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "grid",
              marginTop: 2,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <Typography variant="h4">Address:</Typography>
            <Typography>
              {address.addressLine1} {address.city}, {address.state}{" "}
              {address.postalCode}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              marginTop: 2,
              gridTemplateColumns: "1fr 1fr",
            }}
          >
            <Typography variant="h4">Customer:</Typography>
            <Typography>
              {customer!.firstName} {customer!.lastName}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            marginX: 2,
            borderLeft: `solid 2px ${palette.white}`,
            paddingLeft: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h4">Referral source:</Typography>
              <Typography>{visit.referral?.referralText}</Typography>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h4">Electrification goals:</Typography>
              <Typography>
                {visit.electrificationGoals
                  ?.map((eg) => eg!.electrificationGoalText)
                  .join(", ")}
              </Typography>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h4">Lead source:</Typography>
              <Typography>{visit.leadSource?.leadSourceText}</Typography>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h4">Scope of interest:</Typography>
              <Typography>
                {visit.reasons?.map((r) => r!.reasonText).join(", ")}
              </Typography>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h4">Additional Information:</Typography>
              <Typography>{visit.customerAdditionalInformation}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
