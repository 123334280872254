/** @jsxImportSource @emotion/react */
import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  styled,
  Typography,
} from "@mui/material";
import VentilationIllustration from "../../assets/illustrations/ventilation_illustration.png";
import InductionStoveIllustration from "../../assets/illustrations/induction_stove_illustration.png";
import InductionStoveIcon from "../../assets/icons/induction_stove_orange_icon.svg";
import VentilationIcon from "../../assets/icons/ventilation_orange_icon.svg";
import { Card } from "../../components/Card";
import { StyledSwitch as Switch } from "./Slider";
import {
  currentTogglesVar,
  setToggleReactiveVar,
} from "../../state/ReactiveVars";
import { useReactiveVar } from "@apollo/client";
import { MutationSaveElectrificationEngagementArgs } from "../../generated/graphql";
import {
  LabelContainer,
  StyledElectrificationCardLabel,
} from "./Electrification";

export function HealthView(props: {
  saveElectrificationEngagement: (variables: {
    variables: MutationSaveElectrificationEngagementArgs;
  }) => Promise<any>;
}) {
  const { saveElectrificationEngagement } = props;
  const CopyTextBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    margin: 8,
  });
  const { homeVentilation, inductionStove } = useReactiveVar(currentTogglesVar);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Improve Your Health:
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        Electrification can make your home cleaner, healthier, and more
        efficient. Below, you can learn about the benefits of how switching to
        high-efficiency electric systems can improve your home. See the figures
        to the right to discover the potential improvement of your carbon
        footprint and Energy Use Intensity score.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          alignItems: { md: "flex-end", xs: "center" },
          width: "100%",
          justifyContent: "space-around",
          marginBottom: 4,
          marginTop: 2,
        }}
      >
        <Box
          sx={{
            marginLeft: { lg: 5, xs: 0 },
            marginY: { md: 0, xs: 4 },
          }}
        >
          <img
            src={VentilationIllustration}
            alt={"Fresh air cycling out stale air in a home"}
            width={250}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { lg: 10 },
            width: { md: "50%", sm: "420px", xs: "350px" },
          }}
        >
          <Card>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ marginRight: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        sx={{ margin: 1 }}
                        checked={homeVentilation}
                        onChange={() =>
                          setToggleReactiveVar(
                            "homeVentilation",
                            saveElectrificationEngagement
                          )
                        }
                      />
                    }
                    label={
                      <LabelContainer>
                        <img
                          src={VentilationIcon}
                          alt={"Icon representing home ventilation"}
                        />
                        <StyledElectrificationCardLabel>
                          Home Ventilation
                        </StyledElectrificationCardLabel>
                      </LabelContainer>
                    }
                  />
                </FormGroup>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginLeft: 10 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <CopyTextBox>
                    <Typography variant="h4">Exhausts Stale Air</Typography>
                    moisture, & indoor pollutants
                  </CopyTextBox>
                  <CopyTextBox>
                    <Typography variant="h4">Bring in Fresh,</Typography> clean,
                    & tempered air from the outside
                  </CopyTextBox>
                  <CopyTextBox>
                    <Typography variant="h4">MERV 13 Filters</Typography> can
                    catch bacteria & viruses (including COVID 19)
                  </CopyTextBox>
                </Box>
              </Box>
            </div>
          </Card>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          alignItems: { md: "flex-start", xs: "center" },
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            marginLeft: { lg: 5, xs: 0 },
            marginBottom: { md: 0, xs: 4 },
          }}
        >
          <img
            src={InductionStoveIllustration}
            alt={
              "Air quality display of range where a gas stove produces multiple toxic pollutants"
            }
            width={250}
          />
        </Box>
        <Box
          sx={{
            marginLeft: { lg: 10 },
            width: { md: "50%", sm: "420px", xs: "350px" },
          }}
        >
          <Card>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ marginRight: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        sx={{ margin: 1 }}
                        checked={inductionStove}
                        onChange={() =>
                          setToggleReactiveVar(
                            "inductionStove",
                            saveElectrificationEngagement
                          )
                        }
                      />
                    }
                    label={
                      <LabelContainer>
                        <img
                          src={InductionStoveIcon}
                          alt={"Icon representing an induction stove top"}
                        />
                        <StyledElectrificationCardLabel>
                          Wiring for Induction Stove Top
                        </StyledElectrificationCardLabel>
                      </LabelContainer>
                    }
                  />
                </FormGroup>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginLeft: 10 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "75%",
                  }}
                >
                  <CopyTextBox>
                    <Typography variant="h4">75% of Methane Leaks</Typography>
                    occurs when the stove is off
                  </CopyTextBox>
                  <CopyTextBox>
                    <Typography variant="h4">
                      Reduced Risk for Asthma
                    </Typography>
                    <a href="https://www.lung.org/media/press-releases/new-report-details-health-and-environmental-impact">
                      American Lung Association
                    </a>
                  </CopyTextBox>
                  <CopyTextBox>
                    <Typography variant="h4">
                      Reduces Time for Boiling Water
                    </Typography>
                    half the time of natural gas
                  </CopyTextBox>
                </Box>
              </Box>
            </div>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
