/** @jsxImportSource @emotion/react */
import { Button, Typography } from "@mui/material";
import { palette } from "../theme";

export const SystemError = () => {
  return (
    <form css={{ marginBottom: 100, wdith: "100%" }} id="systemErrorPage">
      <Typography
        variant="h2"
        role="title"
        sx={{
          fontSize: { sm: 36, xs: 38 },
          paddingRight: { xl: 24, sm: 0, xs: 10 },
          color: palette.darkBlue,
        }}
      >
        Oh no!
      </Typography>
      <Typography
        variant="body1"
        role="body"
        sx={{
          color: palette.blueOpaque,
          paddingRight: { xl: 40, lg: 15, sm: 0 },
          marginY: 6,
        }}
      >
        Our system is currently unavailable. Please check back later or email us
        at <a href="mailto:info@heliohome.io">info@heliohome.io</a>.
      </Typography>
      <Button color="primary" component="a" href="mailto:info@heliohome.io">
        Contact Us
      </Button>
    </form>
  );
};
