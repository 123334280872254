import { SelectChangeEvent } from "@mui/material/Select";
import { palette } from "../../theme";
import { SingleSelectDropdown } from "../inputs/SingleSelectDropdown";
import { LoanData, SetLoanData } from "./LoanCalculator";

export const LoanTermSelect = (props: {
  loanData: LoanData;
  setLoanData: SetLoanData;
}) => {
  const { loanData, setLoanData } = props;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setLoanData({ ...loanData, loanTerm: Number(event.target.value) });
  };

  return (
    <SingleSelectDropdown
      defaultValue={30}
      value={loanData.loanTerm}
      options={[5, 10, 15, 20, 25, 30]}
      onChange={handleChange}
      sx={{
        width: 150,
        backgroundColor: palette.backgroundGray,
      }}
      displaytype="years"
      displaylabel="Term"
    />
  );
};
