import { Box, FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import { FormikProps } from "formik";
import { CustomerContactFormValues } from "../forms/CustomerContactForm";
import { Radio } from "./Radio";

export function RadioGroupForContactMethod(props: {
  value: string;
  label: string;
  formik: FormikProps<CustomerContactFormValues>;
  name: string;
}) {
  const { value, label, formik, name } = props;
  return (
    <Box sx={{ gridColumn: "span 3" }}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        name={name}
        onChange={(e, value) => {
          formik.setFieldValue(name, value);
        }}
        onBlur={formik.handleBlur}
        row
        value={value}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <FormControlLabel
          key={"email"}
          value={"Email"}
          control={<Radio />}
          label={"Email"}
          sx={{ width: "fit-content" }}
        />
        <FormControlLabel
          key={"phoneCall"}
          value={"Phone Call"}
          control={<Radio />}
          label={"Phone Call"}
          sx={{ width: "fit-content" }}
        />
        <FormControlLabel
          key={"text"}
          value={"Text"}
          control={<Radio />}
          label={"Text"}
          sx={{ width: "fit-content" }}
        />
      </RadioGroup>
    </Box>
  );
}
