/** @jsxImportSource @emotion/react */
import { forwardRef } from "react";
import { LoanCalculator } from "../components/loanCalculator/LoanCalculator";
import BankIcon from "./../assets/bank_icon.svg";
import { ProposalCard } from "./ProposalCard";

export function ProposalFinanceCard() {
  // 30000 is a placeholder, we need to pipe in the total project estimate after incentives
  const LoanCalculatorComponent = forwardRef(
    function LoanCalculatorComponent() {
      return <LoanCalculator projectTotalEstimate={30000} />;
    }
  );
  return (
    <ProposalCard
      id={"viewFinanceModal"}
      title="Financing"
      description="Want to finance your home electrification? Calculate your monthly
      payment to help you simulate a loan."
      icon={<img src={BankIcon} alt="Bank Icon" />}
      modalBodyContent={<LoanCalculatorComponent />}
    />
  );
}
