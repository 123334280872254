/** @jsxImportSource @emotion/react */
import { Button, Container, Typography } from "@mui/material";
import { palette } from "../theme";
import { Link } from "react-router-dom";
import { paths } from "../paths";

export const OutOfAreaGetStarted = () => {
  return (
    <div>
      <Typography
        variant="h2"
        title="email-confirmation-title"
        role="title"
        sx={{
          marginTop: { xs: 4 },
          marginBottom: { xs: 4 },
          paddingRight: { xl: 40, lg: 15, sm: 0 },
          fontSize: { sm: 36, xs: 38 },
          color: palette.darkBlue,
        }}
      >
        Helio Home isn’t operating in your area yet.
      </Typography>
      <Typography
        variant="body1"
        role="body"
        sx={{
          marginBottom: 7,
          marginTop: { sm: 4 },
          paddingRight: { xl: 40, lg: 15, sm: 0 },
          color: palette.blueOpaque,
        }}
      >
        To stay in touch, select Get Started and enter your home information.
        We’ll reach out when we expand to your area!
      </Typography>
      <Container
        sx={{
          marginY: 4,
          display: "flex",
          justifyContent: "center",
          paddingRight: { xl: 40, lg: 15, sm: 0 },
          width: "100%",
        }}
      >
        <Link to={paths.getStarted().yourHome()}>
          <Button color="primary">Get Started</Button>
        </Link>
      </Container>
    </div>
  );
};
