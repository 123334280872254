/** @jsxImportSource @emotion/react */
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Card, Collapse, ListItem, Typography } from "@mui/material";
import { Maybe } from "graphql/jsutils/Maybe";
import React from "react";
import BatteryIcon from "../assets/icons/battery_orange_icon.svg";
import ElectricalServiceUpgradeIcon from "../assets/icons/electrical_service_upgrade_orange_icon.svg";
import HeatPumpIcon from "../assets/icons/heat_pump_orange_icon.svg";
import InductionStoveIcon from "../assets/icons/induction_stove_orange_icon.svg";
import InsulationIcon from "../assets/icons/insulation_orange_icon.svg";
import SolarIcon from "../assets/icons/solar_orange_icon.svg";
import HomeVentilationIcon from "../assets/icons/ventilation_orange_icon.svg";
import WaterHeaterIcon from "../assets/icons/water_heater_orange_icon.svg";
import ElectricHomeIllustration from "../assets/illustrations/electric_home_illustration.svg";
import HVACHomeIllustration from "../assets/illustrations/hvac_home_illustration.svg";
import InductionStoveIllustration from "../assets/illustrations/induction_stove_illustration.png";
import VentilationIllustration from "../assets/illustrations/ventilation_illustration.png";
import WaterHeaterIllustration from "../assets/illustrations/water_heater_illustration.png";
import { ProductCategoryType } from "../generated/graphql";
import { renderDetailedCurrencyNumber } from "../pages/homeSummary/homeSummaryData";
import { palette } from "../theme";

export interface ProposalSectionProps {
  // Right now, this is just a json object and is not typed on the backend
  // but eventually this will be something like Partial<ProductCategory>
  category: any;
  solarDesignImageUrl?: Maybe<string>;
}

const categoryIcons: { [key in ProductCategoryType]: string } = {
  [ProductCategoryType.Any]: InductionStoveIcon,
  [ProductCategoryType.BatteryStorage]: BatteryIcon,
  [ProductCategoryType.Electrical]: ElectricalServiceUpgradeIcon,
  [ProductCategoryType.Hvac]: HeatPumpIcon,
  [ProductCategoryType.Insulation]: InsulationIcon,
  [ProductCategoryType.Solar]: SolarIcon,
  [ProductCategoryType.Ventilation]: HomeVentilationIcon,
  [ProductCategoryType.WaterHeater]: WaterHeaterIcon,
};

const categoryIllustrations: { [key in ProductCategoryType]: string } = {
  [ProductCategoryType.Any]: InductionStoveIllustration,
  [ProductCategoryType.BatteryStorage]: ElectricHomeIllustration,
  [ProductCategoryType.Electrical]: ElectricHomeIllustration,
  [ProductCategoryType.Hvac]: HVACHomeIllustration,
  [ProductCategoryType.Insulation]: HVACHomeIllustration,
  [ProductCategoryType.Solar]: ElectricHomeIllustration,
  [ProductCategoryType.Ventilation]: VentilationIllustration,
  [ProductCategoryType.WaterHeater]: WaterHeaterIllustration,
};

const categoryDescriptions: { [key in ProductCategoryType]: string } = {
  [ProductCategoryType.Any]:
    "We will take care of anything else you need for your electrified homes.",
  [ProductCategoryType.BatteryStorage]:
    "We will add a battery backup to store excess energy created.",
  [ProductCategoryType.Electrical]:
    "We will add an electrical service upgrade to provide enough power for your electrified home.",
  [ProductCategoryType.Hvac]:
    "We will replace your current natural gas system with an energy efficient heat pump heating & cooling system.",
  [ProductCategoryType.Insulation]:
    "We will update your current outdated insulation.",
  [ProductCategoryType.Solar]:
    "We will add a solar system sized to offset your electrical needs.",
  [ProductCategoryType.Ventilation]:
    "We will add a ventilation system to circulate fresh air in your home.",
  [ProductCategoryType.WaterHeater]:
    "We will replace your current natural gas water heater, a heat pump water heater.",
};

export function ProposalCollapsibleCard(props: ProposalSectionProps) {
  const { category, solarDesignImageUrl } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const { productCategoryType, name } = category.productCategory;

  const icon = categoryIcons[productCategoryType as ProductCategoryType];
  const iconAlt = `${name} icon`;
  const summary =
    categoryDescriptions[productCategoryType as ProductCategoryType];
  const equipment = category.bundles.map((b: any) => b.equipment).flat();
  const sectionPhoto = solarDesignImageUrl
    ? solarDesignImageUrl
    : categoryIllustrations[productCategoryType as ProductCategoryType];
  const sectionPhotoAlt = solarDesignImageUrl
    ? "Image of rooftop solar design from Aurora Solar by Helio team"
    : `${name} illustration`;
  const title = name;
  const equipmentLabels = equipment.map((e: any, i: number) => 
    <ListItem
      key={`${e.equipmentId}-${i}`}
      sx={{
        ml: 2,
        display: "list-item",
        fontFamily: "Exo",
        fontSize: 18,
        fontWeight: 400,
        color: palette.darkBlue,
        listStylePosition: "outside",
        textIndent: -24,
      }}
    >
      {`${e.make || ""} ${e.series || ""} ${e.model}`}
    </ListItem>)
  const bundlesPrice = category.bundles.reduce(
    (sum: number, bundle: any) =>
      sum + parseFloat(bundle.bundle_price.replace("$", "").replace(",", "")),
    0
  );

  const total = renderDetailedCurrencyNumber(bundlesPrice);

  return (
    <Card
      sx={{
        width: "100%",
        "&:hover": {
          cursor: "pointer",
        },
        boxShadow: "none",
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: 60,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            marginY: 2,
          }}
        >
          <img
            css={{ margin: "0 20px 0 20px", height: 24 }}
            src={icon}
            alt={iconAlt}
          />
          <Typography variant="h5" sx={{ width: "100%" }}>
            {title}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", my: 1, marginRight: 1, alignItems: "center" }}
        >
          <Typography variant="h5" sx={{ mr: 1 }}>
            {total}
          </Typography>
          {open ? (
            <ExpandLess color={"secondary"} fontSize={"large"} />
          ) : (
            <ExpandMore color={"secondary"} fontSize={"large"} />
          )}
        </Box>
      </Box>
      <Collapse in={open}>
        <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
          <Box sx={{ mb: 2 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="bodySmall" sx={{ mb: 1 }}>
                Price Before Incentives
              </Typography>
              <Typography variant="bodySmall" sx={{ mb: 1 }}>
                {total}
              </Typography>
            </Box>
            {category.incentives.length > 0 &&
              category.incentives.map((i: any) => (
                <Box
                  key={`${i.incentive_name}`}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="bodySmall" sx={{ mb: 1 }}>
                    {i.incentive_name}
                  </Typography>
                  <Typography variant="bodySmall" sx={{ mb: 1 }}>
                    -
                    {renderDetailedCurrencyNumber(
                      parseFloat(i.amount.replace("$", "").replace(",", ""))
                    )}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
            }}
          >
            <div
              css={
                solarDesignImageUrl && {
                  display: "inline-block",
                  overflow: "hidden",
                  borderRadius: "20px",
                  marginRight: 20,
                }
              }
            >
              <Box
                sx={{
                  height: { xs: 200 },
                  width: { xs: 200 },
                  minWidth: { md: "30%", xs: "100%" },
                  minHeight: { md: "100%", xs: "100%" },
                  backgroundImage: `url(${sectionPhoto})`,
                  backgroundSize: solarDesignImageUrl ? "cover" : "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  transform: solarDesignImageUrl ? "rotate(90deg)" : null,
                  marginRight: 2,
                }}
                title={sectionPhotoAlt}
              >
                {/* This component is the section photo */}
              </Box>
            </div>
            <Box sx={{ marginTop: { md: 0, xs: 2 } }}>
              <Typography
                sx={{
                  paddingRight: { md: 10, xs: 0 },
                  color: palette.darkBlue,
                }}
                variant="body1"
              >
                {summary}
              </Typography>
              {equipment.length > 0 && (
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Equipment:
                </Typography>
              )}
              {equipmentLabels}
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Card>
  );
}
