/** @jsxImportSource @emotion/react */
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  CustomerInput,
  CustomerInterestsDocument,
  CustomerInterestsQuery,
  ElectrificationGoal,
  Maybe,
  Reason,
  Referral,
  SaveCustomerDocument,
  SaveCustomerMutation,
  SaveVisitDocument,
  SaveVisitMutation,
  VisitInput,
} from "../../generated/graphql";
import {
  addressVar,
  electrificationEngagementVar,
  featureFlags,
  leadSource,
  utmParams,
  validatedBuildingProfileIdVar,
  visitVar,
} from "../../state/ReactiveVars";
import { palette } from "../../theme";
import { Checkbox } from "../inputs/Checkbox";
import { RadioGroupForContactMethod } from "../inputs/RadioGroupForContactMethod";
import { scrollToTop } from "../TabsWithSidebar";
import { paths } from "../../paths";

export type CustomerContactFormValues = Pick<
  CustomerInput,
  "email" | "firstName" | "lastName" | "phone"
> &
  Pick<
    VisitInput,
    | "customerAdditionalInformation"
    | "electrificationGoalIds"
    | "reasonIds"
    | "referralId"
    | "preferredContactMethod"
  >;

const StyledErrorMessage = styled(ErrorMessage)({
  color: "red",
  width: "100%",
  textAlign: "left",
  textTransform: "capitalize",
});

const StyledCustomerContactFormLabel = styled(FormLabel)({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  justifyContent: "center",
  marginBottom: 10,
  marginTop: 25,
  width: "100%",
});

export function CustomerContactForm() {
  const { data: customerInterests } = useQuery<CustomerInterestsQuery>(
    CustomerInterestsDocument
  );

  if (customerInterests) {
    const {
      reasons = [],
      electrificationGoals = [],
      referrals = [],
    } = customerInterests;
    return (
      <CustomerContactFormDataView
        reasons={reasons!}
        electrificationGoals={electrificationGoals!}
        referrals={referrals!}
      />
    );
  } else {
    return <></>;
  }
}

export function CustomerContactFormDataView(props: {
  reasons: Maybe<Reason>[];
  electrificationGoals: Maybe<ElectrificationGoal>[];
  referrals: Maybe<Referral>[];
}) {
  const { reasons, referrals, electrificationGoals } = props;
  const navigate = useNavigate();
  const [saveCustomer] =
    useMutation<SaveCustomerMutation>(SaveCustomerDocument);
  const [saveVisit] = useMutation<SaveVisitMutation>(SaveVisitDocument);
  const visitId = useReactiveVar(visitVar);

  const customerContactFormErrorMessages = {
    required: "This field is required",
    invalidPhoneNumber: "Please enter a valid phone number.",
    invalidEmail: "Please enter a valid email.",
    excessSelections: "Please only select up to 3 options",
  };
  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  const customerContactFormValidation = Yup.object({
    email: Yup.string()
      .email(customerContactFormErrorMessages.invalidEmail)
      .required(customerContactFormErrorMessages.required),
    firstName: Yup.string().required(customerContactFormErrorMessages.required),
    lastName: Yup.string().required(customerContactFormErrorMessages.required),
    phone: Yup.string()
      .matches(phoneRegExp, customerContactFormErrorMessages.invalidPhoneNumber)
      .required(customerContactFormErrorMessages.required),
    preferredContactMethod: Yup.string().required(customerContactFormErrorMessages.required),
    reasonIds: Yup.array().of(Yup.string()).optional(),
    referralId: Yup.string().optional(),
    electrificationGoalIds: Yup.array()
      .max(3, customerContactFormErrorMessages.excessSelections)
      .optional(),
    customerAdditionalInformation: Yup.string().optional(),
  });

  const customerContactFormInitialValues: CustomerContactFormValues = {
    customerAdditionalInformation: "",
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    preferredContactMethod: "",
    reasonIds: [],
    referralId: "",
    electrificationGoalIds: [],
  };
  const { shouldShowElectrification } = useReactiveVar(featureFlags);
  const pickRoute = () => {
    if (shouldShowElectrification) {
      return paths.getStarted().electrification();
    }
    return paths.getStarted().emailConfirmation();
  };

  return (
    <Formik
      initialValues={customerContactFormInitialValues}
      onSubmit={async (values, { setSubmitting }) => {
        const { data } = await saveCustomer({
          variables: {
            input: {
              email: values.email?.toLowerCase(),
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              visitId: visitId,
            },
          },
        });

        const visitInput = {
          customerAdditionalInformation: values.customerAdditionalInformation,
          customerId: data?.saveCustomer.customerId,
          visitId: visitId,
          addressId: addressVar().addressId,
          reasonIds: values.reasonIds,
          electrificationGoalIds: values.electrificationGoalIds,
          referralId: values.referralId || null,
          leadSource: leadSource()?.toLowerCase(),
          validatedProfileId: validatedBuildingProfileIdVar(),
          preferredContactMethod: values.preferredContactMethod,
          ...utmParams(),
        };

        const saveVisitData = await saveVisit({
          variables: {
            input: visitInput,
          },
        });

        const {
          data: {
            // @ts-ignore saveVisit exists on saveVisitData
            saveVisit: { visitId: newVisitId, electrificationEngagement },
          },
        } = saveVisitData!;

        electrificationEngagementVar(
          electrificationEngagement.electrificationEngagementId
        );
        visitVar(visitId || newVisitId);
        navigate(pickRoute());
        setSubmitting(false);
        scrollToTop();
      }}
      validationSchema={customerContactFormValidation}
    >
      {(formik) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xl: "70%", xs: "100%" },
              textAlign: "left",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 1.5,
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xl: "row", xs: "column" },
                  gridColumn: "span 2",
                  columnGap: 1.5,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gridRow: 1,
                    gridColumn: "1/2",
                    width: "100%",
                    marginBottom: { xs: 2 },
                  }}
                >
                  <FormLabel>First Name*</FormLabel>
                  <TextField
                    name="firstName"
                    error={
                      formik.touched.firstName && formik.errors.firstName
                        ? true
                        : false
                    }
                    inputProps={{ "data-testid": "firstNameInput" }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    autoComplete="given-name"
                  />
                  <StyledErrorMessage component={"div"} name={"firstName"} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gridRow: 1,
                    gridColumn: "2/2",
                    width: "100%",
                  }}
                >
                  <FormLabel>Last Name*</FormLabel>
                  <TextField
                    name="lastName"
                    error={
                      formik.touched.lastName && formik.errors.lastName
                        ? true
                        : false
                    }
                    inputProps={{ "data-testid": "lastNameInput" }}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    autoComplete="family-name"
                  />
                  <StyledErrorMessage component={"div"} name={"lastName"} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gridColumn: "span 2",
                  marginY: 2,
                }}
              >
                <FormLabel>Email*</FormLabel>
                <TextField
                  name="email"
                  error={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  inputProps={{ "data-testid": "emailInput" }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="email"
                />
                <StyledErrorMessage component={"div"} name={"email"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gridColumn: "span 2",
                }}
              >
                <FormLabel>Phone Number*</FormLabel>
                <TextField
                  name="phone"
                  error={
                    formik.touched.phone && formik.errors.phone ? true : false
                  }
                  inputProps={{ "data-testid": "phoneInput" }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  autoComplete="tel"
                />
                <StyledErrorMessage component={"div"} name={"phone"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gridColumn: "span 2",
                  marginTop: { xs: 2 },
                }}
              >
                <RadioGroupForContactMethod
                  value={formik.values.preferredContactMethod}
                  label={"Preferred Contact Method*"}
                  formik={formik}
                  name={"preferredContactMethod"}
                />
                <StyledErrorMessage component={"div"} name={"preferredContactMethod"} />
              </Box>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <StyledCustomerContactFormLabel>
                What are you most interested in? Select all that apply.
                (optional)
              </StyledCustomerContactFormLabel>
              <FormGroup
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  marginLeft: { xl: 8, xs: 0 },
                }}
                onBlur={formik.handleBlur}
              >
                {reasons.map((opt) => {
                  return (
                    <FormControlLabel
                      name={"reasonIds"}
                      key={opt!.reasonId}
                      control={<Checkbox />}
                      value={opt!.reasonId}
                      label={opt!.reasonText}
                      onChange={formik.handleChange}
                      sx={{ color: palette.darkBlue }}
                    />
                  );
                })}
              </FormGroup>
              <StyledErrorMessage component={"div"} name={"reasons"} />
              <StyledCustomerContactFormLabel>
                How did you hear about Helio? (optional)
              </StyledCustomerContactFormLabel>
              <Select
                sx={{ width: "100%", height: 50 }}
                name="referralId"
                value={formik.values.referralId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {referrals.map((opt) => (
                  <MenuItem key={opt!.referralId} value={opt!.referralId!}>
                    {opt!.referralText}
                  </MenuItem>
                ))}
              </Select>
              <StyledCustomerContactFormLabel>
                What is your main goal for electrifying your home? (optional)
              </StyledCustomerContactFormLabel>
              <Select
                sx={{ width: "100%", height: 50 }}
                name="electrificationGoalIds"
                value={formik.values.electrificationGoalIds}
                error={
                  !!formik.errors.electrificationGoalIds &&
                  !!formik.touched.electrificationGoalIds
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                multiple
              >
                {electrificationGoals.map((opt) => (
                  <MenuItem
                    key={opt!.electrificationGoalId}
                    value={opt!.electrificationGoalId}
                  >
                    {opt!.electrificationGoalText}
                  </MenuItem>
                ))}
              </Select>
              <StyledErrorMessage
                component={"div"}
                name={"electrificationGoal"}
              />
              <StyledCustomerContactFormLabel>
                Is there anything else you’d like to share with us? (optional)
              </StyledCustomerContactFormLabel>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <TextField
                  name="customerAdditionalInformation"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  sx={{ width: "100%", wordWrap: "break-word" }}
                  inputProps={{
                    maxLength: 500,
                    "data-testid": "customerAdditionalInformation",
                  }}
                  error={
                    formik.touched.customerAdditionalInformation &&
                    formik.errors.customerAdditionalInformation
                      ? true
                      : false
                  }
                />
                <StyledErrorMessage
                  component={"div"}
                  name={"customerAdditionalInformation"}
                />
                <Typography
                  variant="body1"
                  sx={{
                    width: "100%",
                    textAlign: "end",
                    color: palette.darkBlue,
                  }}
                >
                  {formik.values?.customerAdditionalInformation?.length}/500
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
            >
              <Button
                data-testid={"contactFormSubmit"}
                color="primary"
                onClick={formik.submitForm}
                disabled={
                  Object.keys(formik.errors).length > 0 ||
                  Object.keys(formik.touched).length === 0
                }
              >
                Submit
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}
