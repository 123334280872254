import { Box, Typography, Link } from "@mui/material";
import DenverLogo from "../../assets/denver_logo.svg";
import XcelLogo from "../../assets/xcel_logo.svg";
import GoodBusinessColoradoLogo from "../../assets/good_business_colorado_logo.svg";
import LoveElectricLogo from "../../assets/love_electric_logo.svg";

export const FooterLogos = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        maxWidth: 500,
      }}
    >
      <Typography
        variant="body1"
        component={"div"}
        sx={{ color: "white", textDecorationColor: "white", width: "100%" }}
      >
        <Box
          component={"div"}
          sx={{
            display: { md: "flex", sm: "grid", xs: "grid" },
            gridTemplateColumns: {
              sm: "1fr 1fr",
              xs: "1fr 1fr",
            },
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            marginY: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 1.5,
            }}
          >
            <img src={DenverLogo} title="denver_logo" alt="denver_logo" />
            <Typography
              variant="body1"
              component={"div"}
              sx={{
                color: "white",
                fontSize: 7,
              }}
            >
              Approved Vendor
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 1.5,
            }}
          >
            <img src={XcelLogo} title="xcel_logo" alt="xcel_logo" />
            <Typography
              variant="body1"
              component={"div"}
              sx={{
                color: "white",
                fontSize: 7,
              }}
            >
              Registered Contractor
            </Typography>
          </Box>
          <Box
            sx={{
              padding: 1.5,
            }}
          >
            <img
              src={GoodBusinessColoradoLogo}
              title="good_business_colorado_logo"
              alt="good_business_colorado_logo"
            />
          </Box>
          <Box
            sx={{
              padding: 1.5,
            }}
          >
            <img
              src={LoveElectricLogo}
              title="love_electric_logo"
              alt="love_electric_logo"
            />
          </Box>
        </Box>
        <Box
          sx={{
            flexDirection: { md: "row", sm: "column", xs: "column" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Link
            href="https://www.heliohome.io/privacy-policy"
            sx={{ color: "white" }}
            underline="always"
          >
            Privacy Policy,
          </Link>
          &nbsp;
          <Link
            href="https://www.heliohome.io/terms-of-service"
            sx={{ color: "white" }}
            underline="always"
          >
            Terms & Conditions,
          </Link>
          &nbsp;
          <Link href="#" sx={{ color: "white" }} underline="always">
            Copyright Protection
          </Link>
        </Box>
      </Typography>
    </Box>
  );
};
