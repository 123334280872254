/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { Card } from "../../components/Card";
import { palette } from "../../theme";
import MoneyEnvelopeIcon from "../../assets/roadmap_money_envelope_icon.svg";
import RelaxIcon from "../../assets/roadmap_relax_icon.svg";

export const ProposalStep3Tab = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
        position: "relative",
        width: "100%",
      }}
    >
      <Card
        sx={{
          width: { md: "40%", sm: "100%", xs: "100%" },
          m: { xl: 1, lg: 1, md: 1 },
          my: { md: 0, sm: 1, xs: 1 },
          height: { md: 470, xs: "fit-content" },
          display: "flex",
          flexDirection: "column",
          overflowY: { md: "scroll", xs: "hidden" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">What do YOU do?</Typography>
          <img src={RelaxIcon} alt="Relax Icon" css={{ height: "30px" }} />
        </Box>
        <Typography variant="h6" sx={{ pr: 5, marginY: 1 }}>
          Last Step - Final payment, You did it!
        </Typography>
        <Typography variant="body1">
          Once your equipment is installed and fully paid for, you’re all set to
          receive tax credits and rebates. Since Helio asks for half of the
          TOTAL job up front, you’ll only need to pay for the remainder of each
          job (mechanical, electrical and solar) after we complete installation.
          If you opted for Financing, you’ll make regular payments depending on
          the lender agreement.
        </Typography>
      </Card>
      <Card
        sx={{
          width: { md: "60%", sm: "100%", xs: "100%" },
          m: { xl: 1, lg: 1, md: 1 },
          my: { md: 0, sm: 1, xs: 1 },
          height: { md: 470, xs: "fit-content" },
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          overflowY: { md: "scroll", xs: "hidden" },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">What do WE do?</Typography>
            <img
              src={MoneyEnvelopeIcon}
              alt="Money Envelope Icon"
              css={{ height: "30px" }}
            />
          </Box>
          <Typography variant="h6" sx={{ pr: 5, marginY: 1 }}>
            All the paperwork, contracting, permitting, and rebates.
          </Typography>
          <Typography variant="body1">
            As soon as your jobs are fully paid for, Helio will take steps to
            get you your maximum rebates as quickly as possible. We will also
            provide you and your tax professional with all the documentation you
            need to maximize your refunds.
          </Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography variant="h6">
            <span css={{ color: palette.starfishOrange }}>Begins:</span> 6+
            Months
          </Typography>
          <Typography variant="h6">
            <span css={{ color: palette.starfishOrange }}>Task Duration:</span>{" "}
            6-8 Weeks for Rebates
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};
