import { Box, FormLabel, Slider } from "@mui/material";
import { basementOptions } from "../../pages/propertyProfileForm/fieldInputOptions";
import { PropertyProfileSubformProps } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { FormGroup } from "../FormGroup";
import { RadioGroupWithUnsure } from "../inputs/RadioGroupWithUnsure";
import { TextSingleSelectDropdown } from "../inputs/TextSingleSelectDropdown";
import { FormErrorMsg } from "./FormErrorMessage";

export function InsulationAndComfortForm(props: PropertyProfileSubformProps) {
  const tempMarks = [
    { label: "Varying Temperatures", value: 0 },
    { label: "Same Temperature", value: 1 },
  ];

  const insulationMarks = [
    { label: "Very Drafty", value: 0 },
    { label: "Sealed Up Tight", value: 1 },
  ];

  const hasBasementOnchange = (value: string) => {
    if (value === "yesFinished") {
      formik.setFieldValue("hasBasement", true);
      formik.setFieldValue("isBasementFinished", true);
      formik.setFieldValue("basementDropdown", "yesFinished");
    } else if (value === "yesUnfinished") {
      formik.setFieldValue("hasBasement", true);
      formik.setFieldValue("isBasementFinished", false);
      formik.setFieldValue("basementDropdown", "yesUnfinished");
    } else if (value === "no") {
      formik.setFieldValue("hasBasement", false);
      formik.setFieldValue("isBasementFinished", false);
      formik.setFieldValue("basementDropdown", "no");
    } else {
      formik.setFieldValue("basementDropdown", "select");
    }
  };

  const formValues = props.formik.values;
  const { formik } = props;
  return (
    <FormGroup
      sx={{ paddingBottom: 2, width: { sm: 450, xs: "100vw" } }}
      formLabel={"Insulation & Home Comfort:"}
    >
      <RadioGroupWithUnsure
        value={formValues.hasAttic}
        label={"Does your home have an attic?*"}
        formik={formik}
        name={"hasAttic"}
      />
      <RadioGroupWithUnsure
        value={formValues.updatedInsulation}
        label={"Has your insulation been updated in the last 10 years?*"}
        formik={formik}
        name={"updatedInsulation"}
      />
      <RadioGroupWithUnsure
        value={formValues.hasCrawlspace}
        label={"Do you have a crawlspace?*"}
        formik={formik}
        name={"hasCrawlspace"}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: 2,
        }}
      >
        <FormLabel>Do you have a basement:*</FormLabel>
        <Box
          sx={{
            gridColumn: "2/2",
            width: { lg: "50%", xs: "100%" },
          }}
        >
          <TextSingleSelectDropdown
            name="basementDropdown"
            value={formValues.basementDropdown}
            options={basementOptions}
            error={
              formik?.touched?.basementDropdown &&
              formik?.errors?.basementDropdown
                ? true
                : false
            }
            onChange={(e) => {
              hasBasementOnchange(e.target.value as string);
            }}
            onBlur={formik.handleBlur}
            sx={{
              width: "100%",
              gridColumn: "2/4",
            }}
          />
          {formik?.touched?.basementDropdown &&
            formik?.errors?.basementDropdown && (
              <FormErrorMsg>{formik.errors.basementDropdown}</FormErrorMsg>
            )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridTemplateColumns: "1fr 1fr",
          gap: 1.5,
          alignItems: "center",
          marginBottom: 1,
          marginTop: 2,
        }}
      >
        <FormLabel sx={{ alignSelf: "start" }}>
          Use the sliders to rate the comfort of your home.
        </FormLabel>
        <Slider
          color={"secondary"}
          defaultValue={0}
          marks={insulationMarks}
          max={1}
          min={0}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="homeOwnerAssessedLeakage"
          step={0.01}
          value={formValues.homeOwnerAssessedLeakage!}
        />
        <Slider
          color={"secondary"}
          defaultValue={0}
          min={0}
          max={1}
          marks={tempMarks}
          name="homeOwnerAssessedTemp"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          step={0.01}
          sx={{ marginBottom: 3 }}
          value={formValues.homeOwnerAssessedTemp}
        />
      </Box>
    </FormGroup>
  );
}
