/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import React from "react";
import helioLogo from "../assets/heliovector.svg";
import { paths } from "../paths";

export function Header({
  children,
  homePath = paths.getStarted().root(),
}: {
  children?: React.ReactNode;
  homePath?: string;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "row", sm: "column", xs: "column" },
      }}
    >
      <a href={homePath}>
        <Box
          sx={{
            paddingY: { sm: 4, xs: 1 },
            paddingX: { sm: 5, xs: 1 },
            position: { md: "absolute" },
          }}
        >
          <img src={helioLogo} alt="Helio Home logo" />
        </Box>
      </a>
      {children}
    </Box>
  );
}
