/** @jsxImportSource @emotion/react */
import { Box, FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import { FormikErrors } from "formik";
import { HeatingSystem } from "../../generated/graphql";
import { PropertyProfileSubformProps } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { HeaterTypeOptions } from "../../pages/propertyProfileForm/fieldInputOptions";
import { FormGroup } from "../FormGroup";
import { RadioGroupWithUnsure } from "../inputs/RadioGroupWithUnsure";
import { TextSingleSelectDropdown } from "../inputs/TextSingleSelectDropdown";
import { Radio } from "./../inputs/Radio";
import { FormErrorMsg } from "./FormErrorMessage";

export function HeatingForm(props: PropertyProfileSubformProps) {
  const formValues = props.formik.values;
  const { formik } = props;
  return (
    <FormGroup
      sx={{ width: { lg: 550, sm: 450, xs: "100vw" } }}
      formLabel="Heating:"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          marginTop: 1,
        }}
      >
        <FormLabel>Main Heating System:*</FormLabel>
        <Box
          sx={{
            gridColumn: "2/2",
            width: { lg: "50%", xs: "100%" },
          }}
        >
          <TextSingleSelectDropdown
            name={"heatingSystems[0].heatingSystemType"}
            error={
              formik?.touched?.heatingSystems && formik?.errors?.heatingSystems
                ? true
                : false
            }
            data-testid={`fuelType[${0}]`}
            value={formValues.heatingSystems[0].heatingSystemType}
            options={HeaterTypeOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{
              width: "100%",
              gridColumn: "2/4",
            }}
          />
          {formik?.touched?.heatingSystems &&
            formik?.errors?.heatingSystems && (
              <FormErrorMsg>
                {
                  (
                    formik.errors
                      ?.heatingSystems as FormikErrors<HeatingSystem>[]
                  )?.[0]?.["heatingSystemType"]
                }
              </FormErrorMsg>
            )}
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, gridColumn: "span 3" }}>
        <RadioGroupWithUnsure
          value={formValues.updatedHeatingSystem}
          label={"Has your heating system been updated in the last 5 years?"}
          formik={formik}
          name={"updatedHeatingSystem"}
        />
      </Box>
      <Box>
        <FormLabel>Do you have another heating system?</FormLabel>
        <RadioGroup
          name={"additionalHeatingSystem"}
          onChange={(e, value) => {
            formik.setFieldValue("additionalHeatingSystem", JSON.parse(value));
          }}
          onBlur={formik.handleBlur}
          row
          sx={{ display: "flex", justifyContent: "flex-end" }}
          value={formValues.additionalHeatingSystem}
        >
          <FormControlLabel
            key={"yes"}
            value={true}
            control={<Radio />}
            label={"Yes"}
            sx={{ width: "fit-content" }}
          />
          <FormControlLabel
            key={"no"}
            value={false}
            control={<Radio />}
            label={"No"}
            sx={{ width: "fit-content" }}
          />
        </RadioGroup>
      </Box>
    </FormGroup>
  );
}
