/** @jsxImportSource @emotion/react */
import {
  Box,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  PropertyProfileSubformProps,
  setServicePanelSize,
} from "../../pages/propertyProfileForm/PropertyProfileForm";
import {
  BuildingTypeOptions,
  StoveFuelTypeOptions,
} from "../../pages/propertyProfileForm/fieldInputOptions";
import { palette } from "../../theme";
import { FormGroup } from "../FormGroup";
import { Checkbox } from "../inputs/Checkbox";
import { Radio } from "../inputs/Radio";
import { RadioGroupWithUnsure } from "../inputs/RadioGroupWithUnsure";
import { SingleSelectDropdown } from "../inputs/SingleSelectDropdown";
import { TextFieldWithUnitOfMeasurement } from "../inputs/TextFieldWithUnitOfMeasurement";
import { TextSingleSelectDropdown } from "../inputs/TextSingleSelectDropdown";
import { FormErrorMsg } from "./FormErrorMessage";

const homeYearBuiltOptions = [];
for (var i = 1900; i <= 2023; i++) {
  homeYearBuiltOptions.push(i);
}

const waterHeaterInstallYearOptions = [];
for (var ii = 1950; ii <= 2023; ii++) {
  waterHeaterInstallYearOptions.push(ii);
}

export function GeneralHomeInformationForm(props: PropertyProfileSubformProps) {
  const formValues = props.formik.values;
  const { formik } = props;

  const renderSqftErrors = () => {
    if (formik.values.conditionedFloorAreaSqFt > 15000) {
      return (
        <FormErrorMsg>{"We do not support homes this large yet"}</FormErrorMsg>
      );
    } else if (
      !!formik.touched.conditionedFloorAreaSqFt &&
      formik.errors.conditionedFloorAreaSqFt === "Please confirm square footage"
    ) {
      return (
        <FormControlLabel
          label={
            <FormErrorMsg>
              {formik.errors.conditionedFloorAreaSqFt}
            </FormErrorMsg>
          }
          name="confirmSqFt"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          control={<Checkbox />}
          sx={{ gridColumn: "2/4", color: "red" }}
        />
      );
    } else if (
      !!formik.touched.conditionedFloorAreaSqFt &&
      !!formik.errors.conditionedFloorAreaSqFt
    ) {
      return (
        <FormErrorMsg>{formik.errors.conditionedFloorAreaSqFt}</FormErrorMsg>
      );
    }
  };

  return (
    <FormGroup
      sx={{
        paddingBottom: 5,
        width: { sm: 450, xs: "100vw", height: "fit-content" },
      }}
      formLabel="General Home Information:"
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 3,
          rowGap: 1.5,
          alignItems: "center",
          marginTop: 1,
        }}
      >
        <FormLabel>Year Built:*</FormLabel>
        <TextField
          name="yearBuilt"
          type="number"
          error={!!formik.touched.yearBuilt && !!formik.errors.yearBuilt}
          placeholder={"0"}
          onChange={(e) => {
            formik.setFieldValue("yearBuilt", parseInt(e.target.value));
            formik.setFieldValue(
              "servicePanelSize",
              setServicePanelSize(parseInt(e.target.value))
            );
          }}
          onBlur={formik.handleBlur}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          value={formValues.yearBuilt.toString()}
          size={"small"}
          sx={{
            width: "100%",
            gridColumn: "2/4",
          }}
        />
        {formik.touched.yearBuilt && formik.errors.yearBuilt && (
          <FormErrorMsg>{formik.errors.yearBuilt}</FormErrorMsg>
        )}
        <FormLabel>Residential Type:*</FormLabel>
        <TextSingleSelectDropdown
          name="buildingType"
          value={formValues.buildingType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          options={BuildingTypeOptions}
          error={!!formik.touched.buildingType && !!formik.errors.buildingType}
          sx={{
            width: "100%",
            gridColumn: "2/4",
          }}
        />
        {formik.touched.buildingType && formik.errors.buildingType && (
          <FormErrorMsg>{formik.errors.buildingType}</FormErrorMsg>
        )}
        <Tooltip title="Including Basement">
          <FormLabel>Square Footage:*</FormLabel>
        </Tooltip>
        <TextField
          type="number"
          placeholder={"0"}
          name="conditionedFloorAreaSqFt"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          error={
            !!formik.touched.conditionedFloorAreaSqFt &&
            !!formik.errors.conditionedFloorAreaSqFt
          }
          value={formValues.conditionedFloorAreaSqFt}
          size={"small"}
          sx={{
            width: "100%",
            gridColumn: "2/4",
          }}
        />
        {renderSqftErrors()}
        <FormLabel>Floors Above Grade:*</FormLabel>
        <SingleSelectDropdown
          error={
            !!formik.touched.floorsAboveGrade &&
            !!formik.errors.floorsAboveGrade
          }
          name="floorsAboveGrade"
          value={formValues.floorsAboveGrade}
          options={[0, 1, 2, 3, 4]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          sx={{
            width: "100%",
            gridColumn: "2/4",
          }}
        />
        <FormLabel>Stove Top Type:*</FormLabel>
        <TextSingleSelectDropdown
          name="stoveTopType"
          value={formValues.stoveTopType}
          onChange={formik.handleChange}
          options={StoveFuelTypeOptions}
          onBlur={formik.handleBlur}
          error={!!formik.touched.stoveTopType && !!formik.errors.stoveTopType}
          sx={{
            width: "100%",
            gridColumn: "2/4",
          }}
        />
        {formik.touched.stoveTopType && formik.errors.stoveTopType && (
          <FormErrorMsg>{formik.errors.stoveTopType}</FormErrorMsg>
        )}
        <FormLabel>Pool:*</FormLabel>
        <RadioGroup
          name="hasPool"
          onChange={(e, value) => {
            formik.setFieldValue("hasPool", JSON.parse(value));
          }}
          onBlur={formik.handleBlur}
          value={formValues.hasPool}
          aria-labelledby="demo-radio-buttons-group-label"
          row={true}
          sx={{ gridColumn: "2/4" }}
        >
          <FormControlLabel
            sx={{ paddingRight: 2, color: palette.darkBlue }}
            value={true}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            sx={{ color: palette.darkBlue }}
            value={false}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>

        <FormLabel>Hot Tub:*</FormLabel>
        <RadioGroup
          name="hasHottub"
          onChange={(e, value) => {
            formik.setFieldValue("hasHottub", JSON.parse(value));
          }}
          onBlur={formik.handleBlur}
          value={formValues.hasHottub}
          row={true}
          aria-labelledby="demo-radio-buttons-group-label"
          sx={{ gridColumn: "2/4" }}
        >
          <FormControlLabel
            sx={{ paddingRight: 2, color: palette.darkBlue }}
            value={true}
            control={<Radio />}
            label="Yes"
          />
          <FormControlLabel
            sx={{ color: palette.darkBlue }}
            value={false}
            control={<Radio />}
            label="No"
          />
        </RadioGroup>
        <RadioGroupWithUnsure
          value={formValues.hasServiceUpgrade}
          label={"Has your electrical service been upgraded?:*"}
          formik={formik}
          name={"hasServiceUpgrade"}
        />
        {formValues.hasServiceUpgrade && (
          <Box sx={{ gridColumn: "span 3", display: "flex" }}>
            <FormLabel>Current Capacity of Electrical Panel?:*</FormLabel>
            <TextFieldWithUnitOfMeasurement
              formik={formik}
              type={"number"}
              name={"servicePanelSize"}
              unitOfMeasurement={"Amps"}
            />
          </Box>
        )}
        {formik.touched.servicePanelSize && formik.errors.servicePanelSize && (
          <FormErrorMsg>{formik.errors.servicePanelSize}</FormErrorMsg>
        )}
      </Box>
    </FormGroup>
  );
}
