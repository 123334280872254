import React from "react";
import { Slider, Typography, Box } from "@mui/material";

export const SliderComponent = (props: {
  defaultValue: number;
  min: number;
  max: number;
  label: string;
  unit: string;
  onChange: (event: Event, value: number) => void;
  amount: number;
  value: number;
  steps: number;
}) => {
  const {
    defaultValue,
    min,
    max,
    label,
    unit,
    onChange,
    amount,
    value,
    steps,
  } = props;
  return (
    <Box my={1.4}>
      <Box>
        <Typography variant="subtitle2">{label}</Typography>
        <Typography variant="h5">
          {unit} {amount.toLocaleString()}
        </Typography>
      </Box>
      <Slider
        style={{ padding: 0 }}
        min={min}
        max={max}
        defaultValue={defaultValue}
        aria-label="Default"
        valueLabelDisplay="auto"
        onChange={onChange}
        value={value}
        step={steps}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="caption" color="text.secondary">
          {unit} {min}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {unit} {max}
        </Typography>
      </Box>
    </Box>
  );
};
