/** @jsxImportSource @emotion/react */
import { useQuery, useReactiveVar } from "@apollo/client";
import { Box, Tabs } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTab, CustomTabs } from "../../components/Tabs";
import { TabsWithSidebar } from "../../components/TabsWithSidebar";
import {
  ElectrificationSummaryDocument,
  ElectrificationSummaryQuery,
} from "../../generated/graphql";
import { paths } from "../../paths";
import {
  UpgradeToggleState,
  changeToggledUpgradeEnergyMeasures,
  currentTogglesVar,
  validatedBuildingProfileIdVar,
} from "../../state/ReactiveVars";
import {
  ElectrificationSummarySidebar,
  formatSegmentData,
} from "../electrification/ElectrificationSummarySidebar";
import { PricingAndIncentivesView } from "./PricingAndIncentivesView";

export type PricingAndIncentivesLayoutDataViewProps = {
  data: ElectrificationSummaryQuery;
};

export function PricingAndIncentivesLayout() {
  const navigate = useNavigate();
  const validatedBuildingProfileId = useReactiveVar(
    validatedBuildingProfileIdVar
  );
  const { data } = useQuery<ElectrificationSummaryQuery>(
    ElectrificationSummaryDocument,
    {
      variables: { validatedBuildingProfileId },
    }
  );

  if (!validatedBuildingProfileId) {
    navigate(paths.getStarted().root());
  }

  if (data) {
    return <PricingAndIncentivesLayoutDataView data={data} />;
  } else {
    return null;
  }
}

export function PricingAndIncentivesLayoutDataView(
  props: PricingAndIncentivesLayoutDataViewProps
) {
  const { data } = props;

  const upgradeEnergyMeasures =
    data.validatedBuildingProfile!.upgradeEnergyMeasures!;

  const activeToggles = Object.keys(useReactiveVar(currentTogglesVar)).filter(
    (k, i) => currentTogglesVar()[k as keyof UpgradeToggleState] === true
  );

  useEffect(() => {
    changeToggledUpgradeEnergyMeasures(upgradeEnergyMeasures);
  }, [activeToggles, upgradeEnergyMeasures]);

  const headerHeight = 104;
  const navigate = useNavigate();
  const onNext = () => {
    navigate(paths.getStarted().nextSteps());
  };

  const onBack = () => {
    navigate(paths.getStarted().electrification());
  };
  return (
    <TabsWithSidebar
      mainContent={
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: { md: "row", sm: "column", xs: "column" },
            height: { md: `calc(100% - ${headerHeight}px)`, sm: "fit-content" },
          }}
        >
          <CustomTabs
            views={[<PricingAndIncentivesView />]}
            tabIndex={0}
            labels={
              <Tabs
                sx={{ height: 70 }}
                value={0}
                aria-label="basic tabs example"
                indicatorColor="secondary"
                centered
              >
                <CustomTab
                  value={0}
                  label={
                    <p css={{ fontWeight: 700, fontSize: 20 }}>Cost Estimate</p>
                  }
                />
              </Tabs>
            }
          />
        </Box>
      }
      sidebar={
        <ElectrificationSummarySidebar
          energyUsage={formatSegmentData(
            changeToggledUpgradeEnergyMeasures(upgradeEnergyMeasures)
          )}
          displayToggle={false}
          solar={activeToggles.includes("solar")}
        />
      }
      onNext={onNext}
      onBack={onBack}
    />
  );
}
