import { ReactComponent as HelioSun } from "../assets/helio_sun.svg";
import { Box } from "@mui/material";
import React from "react";
import { keyframes } from "@emotion/react";

const flash = keyframes`
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
  `;
type SunLoadingProps = { delay?: string };

const SunLoading = (props: SunLoadingProps) => {
  return (
    <Box
      sx={{ animation: `${flash} 1.5s infinite`, animationDelay: props.delay }}
    >
      <HelioSun />
    </Box>
  );
};

export const LoadingAnimation = () => {
  return (
    <Box sx={{ justifyContent: "center", display: "flex" }}>
      <SunLoading />
      <SunLoading delay=".25s" />
      <SunLoading delay=".5s" />
    </Box>
  );
};
