/** @jsxImportSource @emotion/react */
import InfoIcon from "@mui/icons-material/Info";
import { Box, Tooltip, Typography } from "@mui/material";
import energyConnection from "../../assets/energyConnection.svg";
import OilBarrel from "../../assets/oil_barrel.svg";
import { Card } from "../../components/Card";
import { EnergyIntensityScoreLinearBar } from "../../components/LinearProgressBar";
import {
  CoolingSystem,
  HeatingSystem,
  Maybe,
  ValidatedBuildingProfile,
} from "../../generated/graphql";
import { EnergyMixDoughnutChart } from "./EnergyMixDoughnutChart";
import { HomeEnergyMixBarChart } from "./HomeEnergyMixBarChart";
import {
  calculateEnergyIntensityScore,
  calculateHomeEnergyMix,
  convertTonsToBarrels,
  getThermsAndKwh,
  yearlyCO2ProductionInTons,
} from "./homeSummaryData";
import { round } from "lodash";

interface CurrentEnergyUsageViewProps {
  buildingProfile: Omit<
    Partial<ValidatedBuildingProfile>,
    "coolingSystems" | "heatingSystems"
  > & {
    coolingSystems?: Maybe<Partial<CoolingSystem>>[] | null;
    heatingSystems?: Maybe<Partial<HeatingSystem>>[] | null;
  };
}

export function CurrentEnergyUsageView(props: CurrentEnergyUsageViewProps) {
  const { buildingProfile } = props;
  const {
    stoveTopType,
    heatingSystems,
    waterHeater,
    conditionedFloorAreaSqFt,
    coolingSystems,
    upgradeEnergyMeasures,
  } = buildingProfile;
  const heatingSystemFuelType = heatingSystems[0]?.heatingSystemFuelType;
  const hasCooling = !!coolingSystems?.[0];
  const waterHeaterFuelType = waterHeater?.fuelType;
  const baselineUpgradeEnergyMeasuresCO2Tons =
    upgradeEnergyMeasures?.baseline?.co2Tons;
  const fallbackYearlyCO2Production = yearlyCO2ProductionInTons(
    heatingSystemFuelType,
    waterHeaterFuelType,
    stoveTopType,
    hasCooling,
    false
  );

  const baselineUpgradeEnergyMeasuresEnergyIntensityScore =
    upgradeEnergyMeasures?.baseline?.energyUseIntensityScore;
  const energyIntensityScore = calculateEnergyIntensityScore(
    heatingSystemFuelType,
    waterHeaterFuelType,
    stoveTopType,
    hasCooling,
    false,
    conditionedFloorAreaSqFt
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ padding: 2, textAlign: "center" }}>
        Your Home's Energy Usage
      </Typography>
      <Typography
        sx={{
          maxWidth: { md: "65%", sm: "95%", xs: "95%" },
          padding: 2,
          textAlign: "center",
        }}
      >
        Your current home equipment is aging and will need to be replaced soon.
        Click next to learn more about your home’s energy usage and how
        electrification can improve your home’s carbon footprint.
      </Typography>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: { md: "row", sm: "column", xs: "column" },
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "fit-content",
            flexDirection: "column",
            alignItems: "center",
            backgroundImage: `url(${energyConnection})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "108px 270px",
            backgroundSize: "55%",
          }}
        >
          <Box>
            <HomeEnergyMixBarChart
              energyMix={calculateHomeEnergyMix(
                heatingSystemFuelType,
                waterHeaterFuelType,
                stoveTopType,
                hasCooling,
                false
              )}
              energyType={getThermsAndKwh(
                heatingSystemFuelType === "natural gas",
                waterHeaterFuelType === "natural gas",
                stoveTopType === "natural gas",
                hasCooling,
                false
              )}
            />
          </Box>
          <EnergyMixDoughnutChart />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: 8,
            width: { md: "50%", sm: "420px", xs: "350px" },
          }}
        >
          <div css={{ marginBottom: 32 }}>
            <Card>
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <h3>
                    CO<sub>2</sub> Production (yearly)
                  </h3>
                  <Box sx={{ display: "flex", marginTop: 2 }}>
                    <img
                      src={OilBarrel}
                      alt={"Icon representing a black oil barrel"}
                    />
                    <Typography sx={{ marginLeft: 1 }}>
                      Equal to{" "}
                      {convertTonsToBarrels(
                        round(
                          baselineUpgradeEnergyMeasuresCO2Tons ||
                            fallbackYearlyCO2Production,
                          1
                        )
                      )}{" "}
                      barrels of oil
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h2" sx={{ color: "#F62447" }}>
                    {round(
                      baselineUpgradeEnergyMeasuresCO2Tons ||
                        fallbackYearlyCO2Production,
                      1
                    )}
                  </Typography>
                  TONS
                </Box>
              </div>
            </Card>
          </div>
          <Card>
            <h3>
              Energy Use Intensity Score
              <Tooltip
                placement="right-start"
                title="Your energy use intensity score is calculated by dividing your home’s kBtus usage by its square footage. A low score means a more energy efficient home."
              >
                <InfoIcon sx={{ width: "15px" }} />
              </Tooltip>
            </h3>
            <EnergyIntensityScoreLinearBar
              score={
                baselineUpgradeEnergyMeasuresEnergyIntensityScore ||
                energyIntensityScore
              }
              labelText={`${
                baselineUpgradeEnergyMeasuresEnergyIntensityScore ||
                energyIntensityScore
              }`}
            />
            <Typography sx={{ marginTop: 2 }}>
              Your energy use intensity score is calculated by dividing your
              home's{" "}
              <span>
                kBtus
                <Tooltip
                  placement="right-start"
                  title="One thousand British thermal units, or kBTU for short, is a common unit of energy. It’s used to convert and combine other energy measurements, such as kilowatt-hours (kWh) of electricity, therms of natural gas, and pounds of steam."
                >
                  <InfoIcon sx={{ width: "15px" }} />
                </Tooltip>
              </span>
              usage by its square footage. A low score means a more energy
              efficient home.
            </Typography>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
