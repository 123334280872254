import { MenuItem, Select, SelectProps, Typography } from "@mui/material";

interface SelectPropsWithOptions extends SelectProps {
  options: number[];
  displaytype?: string;
  displaylabel?: string;
}

//TODO: We need to figure out a way to simplify the types here so that this component can be used for any data types
// this should take in an array and format it so that we use {label: string, value: <data>} in the dropdown
// currently using a conditional for value always returns opt when 0(falsy)

export function SingleSelectDropdown(props: SelectPropsWithOptions) {
  return (
    <>
      <Typography variant="subtitle2">{props?.displaylabel}</Typography>
      <Select size="small" {...props}>
        {props?.options?.map((opt) => (
          <MenuItem value={opt} key={opt}>
            {opt} {props?.displaytype}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
