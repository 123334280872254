/** @jsxImportSource @emotion/react */
import { Box, Typography } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { ProposalElectrificationCard } from "./ProposalElectrificationCard";
import { ProposalFinanceCard } from "./ProposalFinanceCard";
import { ProposalTeamCard } from "./ProposalTeamCard";
import { ProposalRoadmapCard } from "./roadmap/ProposalRoadmapCard";

export function ProposalLayout({ children }: React.PropsWithChildren) {
  const cardContainerHeight = useRef({ clientHeight: 0 });
  const [height, setHeight] = useState(0);
  useEffect(() => {
    if (!cardContainerHeight?.current?.clientHeight) {
      return;
    }
    setHeight(cardContainerHeight?.current?.clientHeight);
  }, [cardContainerHeight]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h2" sx={{ marginY: 3, textAlign: "center" }}>
        Your Proposal
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            lg: "row",
            xs: "column-reverse",
          },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: { lg: 2, md: 1, sm: 0, xs: 0 },
            width: { lg: "40%", md: "100%", sm: "100%", xs: "100%" },
            justifyContent: "space-between",
          }}
          ref={cardContainerHeight}
        >
          <ProposalFinanceCard />
          <ProposalTeamCard />
          <ProposalRoadmapCard />
          <ProposalElectrificationCard />
        </Box>
        <Box
          sx={{
            display: "flex",
            marginRight: { lg: 2, md: 1, sm: 0, xs: 0 },
            marginBottom: 2,
            width: {
              lg: "60%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            flexGrow: 1,
            justifyContent: {
              lg: "flex-start",
              md: "center",
              sm: "center",
              xs: "center",
            },
            height: {
              md: `calc(${height}px)`,
              sx: null,
            },
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
