/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import HomeIllustration from "../assets/home_illustration.svg";
import HomeIllustrationWaterHeater from "../assets/home_illustration_water_heater.svg";
import HomeIllustrationHeatPump from "../assets/home_illustration_heat_pump.svg";
import ActiveHomeIllustrationWaterHeater from "../assets/home_illustration_water_heater_active.svg";
import ActiveHomeIllustrationHeatPump from "../assets/home_illustration_heat_pump_active.svg";

export function HomeIllustrationImage(props: {
  activeIcons: { heatPump: boolean; waterHeater: boolean };
}) {
  const { heatPump, waterHeater } = props.activeIcons;
  return (
    <Box sx={{ position: "relative" }}>
      <img
        alt="Heat pump icon"
        src={
          heatPump ? ActiveHomeIllustrationHeatPump : HomeIllustrationHeatPump
        }
        css={{
          zIndex: 1,
          position: "absolute",
          top: "54%",
          left: "83%",
          width: "20%",
        }}
      />
      <img
        alt="Water heater icon"
        src={
          waterHeater
            ? ActiveHomeIllustrationWaterHeater
            : HomeIllustrationWaterHeater
        }
        css={{
          zIndex: 1,
          position: "absolute",
          top: "85%",
          left: "59%",
          width: "20%",
        }}
      />
      <img
        css={{ zIndex: 0, width: "95%" }}
        alt="A home with interactive icons"
        src={HomeIllustration}
      />
    </Box>
  );
}
