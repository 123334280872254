import { Box, Typography } from "@mui/material";
import { palette } from "../../theme";

export function CustomerProfileFormHeader() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: 2,
      }}
    >
      <Typography
        variant="h2"
        color={palette.white}
        sx={{ textAlign: "center", fontSize: { xs: "24px" } }}
      >
        Let’s Get to Know Your Home
      </Typography>
      <Typography
        variant="body1"
        color={palette.white}
        sx={{
          marginTop: 1,
          marginX: 2,
          textAlign: "center",
          width: "60%",
          maxWidth: "1000px",
        }}
      >
        Helio gathers information about your home to create a personalized
        roadmap for your electrification journey. Review the information below
        and update it to the best of your ability. It’s okay if you don’t have
        exact measurements – your best estimate will do!
      </Typography>
    </Box>
  );
}
