/** @jsxImportSource @emotion/react */
import { VictoryPie } from "victory-pie";
import { VictoryLabel } from "victory";
import { palette } from "../../theme";
import { Typography, Box } from "@mui/material";

export const EnergyMixDoughnutChart = () => {
  const coloradoEnergyMix = {
    coal: 32,
    naturalGas: 28,
    otherCarbonBased: 1,
    otherCarbonFree: 1,
    solar: 5,
    wind: 33,
  };

  const data = [
    {
      x: `${coloradoEnergyMix.naturalGas}%\nNatural\nGas`,
      y: coloradoEnergyMix.naturalGas,
      fontFill: `${palette.darkBlue}`,
      labelPlacement: "vertical",
      labelRadius: 100,
    },
    {
      x: `${coloradoEnergyMix.coal}% Coal`,
      y: coloradoEnergyMix.coal,
      fontFill: "white",
      labelPlacement: "vertical",
      labelRadius: 100,
    },
    {
      x: `${coloradoEnergyMix.otherCarbonBased}% Other`,
      y: coloradoEnergyMix.otherCarbonBased,
      fontFill: `${palette.darkBlue}`,
      labelPlacement: "parallel",
      labelRadius: 160,
    },
    {
      x: `${coloradoEnergyMix.otherCarbonFree}% Hydro`,
      y: coloradoEnergyMix.otherCarbonFree,
      fontFill: `${palette.darkBlue}`,
      labelPlacement: "parallel",
      labelRadius: 160,
    },
    {
      x: `${coloradoEnergyMix.solar}% Solar`,
      y: coloradoEnergyMix.solar,
      fontFill: `${palette.darkBlue}`,
      labelPlacement: "parallel",
      labelRadius: 160,
    },
    {
      x: `${coloradoEnergyMix.wind}% Wind`,
      y: coloradoEnergyMix.wind,
      fontFill: "white",
      labelPlacement: "vertical",
      labelRadius: 100,
    },
  ];

  return (
    <Box sx={{ maxWidth: "90%" }}>
      <div
        style={{
          filter: "drop-shadow(0 2mm 1mm #b3b5b8)",
          marginBottom: -20,
        }}
      >
        <VictoryPie
          style={{ parent: { paddingBottom: 0 } }}
          padAngle={0.5}
          startAngle={300}
          endAngle={-60}
          width={400}
          cornerRadius={5}
          labelComponent={
            <VictoryLabel
              renderInPortal
              style={{ fill: ({ datum }) => datum.fontFill }}
              angle={0}
            />
          }
          colorScale={[
            palette.gold,
            palette.darkBlue,
            palette.amberOrange,
            palette.starfishOrange,
            palette.polishedPine,
            palette.secondaryRed,
          ]}
          labelPosition={({ datum }) => {
            if (datum.x.includes("Other")) {
              return "startAngle";
            }
          }}
          labelPlacement={({ datum }) => datum.labelPlacement}
          labelRadius={({ datum }) => datum.labelRadius}
          innerRadius={75}
          data={data}
        />
      </div>
      <Typography sx={{ minWidth: 350 }} variant="h4">
        Colorado Electrical Grid Energy Mix
      </Typography>
    </Box>
  );
};
