import { Typography, Box } from "@mui/material";
import { SalesIntakeFormValues } from "./SalesIntakeForm";
import { Card } from "../../components/Card";
import { FormikProps } from "formik";
import { FormTextField } from "./FormTextField";
import { RadioRow } from "./RadioRow";

export function BasementAndCrawlspace(props: {
  form: FormikProps<SalesIntakeFormValues>;
}) {
  const { form } = props;
  return (
    <Box sx={{ display: "flex", flexDirection: "row", padding: 2 }}>
      <Box sx={{ marginRight: 2 }}>
        <Card>
          <Typography variant="h4">Crawlspace</Typography>
          {[
            ["Does your home have a crawlspace?", "hasCrawlspace"],
            ["Is the crawlspace conditioned?", "isCrawlspaceConditioned"],
            ["Is the crawlspace vented?", "isCrawlspaceVented"],
          ].map(([name, field]) => RadioRow(name, field as keyof SalesIntakeFormValues, form))}
          <FormTextField
            label="Crawlspace Square Footage:*"
            form={form}
            fieldName="crawlspaceSqFt"
            fieldType="number"
            placeholder="200"
          />
        </Card>
      </Box>
      <Box>
        <Card>
          <Typography variant="h4">Basement</Typography>
          {[
            ["Does your home have a basement?", "hasBasement"],
            ["Is the basement conditioned?", "isBasementConditioned"],
            ["Is the basement finished?", "isBasementFinished"],
          ].map(([name, field]) => RadioRow(name, field as keyof SalesIntakeFormValues, form))}
          <FormTextField
            label="Basement Square Footage:*"
            form={form}
            fieldName="basementSqFt"
            fieldType="number"
            placeholder="500"
          />
        </Card>
      </Box>
      <Box sx={{ marginLeft: 2 }}>
        <Card>
          <Typography variant="h4">Attic</Typography>
          {RadioRow("Does your home have an attic?", "hasAttic", form)}
        </Card>
      </Box>
    </Box>
  );
}
