import { Box, FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import { FormikProps } from "formik";
import { PropertyProfileFormValues } from "../../pages/propertyProfileForm/PropertyProfileForm";
import { Radio } from "./Radio";

const CURRENT_YEAR = new Date().getFullYear();

const setInstallYear = (
  field: string,
  value: string,
  formik: FormikProps<PropertyProfileFormValues>
) => {
  if (value === "true") {
    formik.setFieldValue(field, CURRENT_YEAR - 5);
  } else {
    formik.setFieldValue(field, CURRENT_YEAR - 10);
  }
};

export const setTogglesWithUnsureOption = (value, formik, formikField) => {
  if (value === "true" || value === "false") {
    formik.setFieldValue(formikField, JSON.parse(value));
  } else {
    formik.setFieldValue(formikField, "");
  }
  if (formikField === "hasServiceUpgrade" && value === "true") {
    formik.setFieldValue("servicePanelSize", 200);
  }
  if (formikField === "updatedHeatingSystem") {
    setInstallYear("heatingSystems[0][yearInstalled]", value, formik);
  }
  if (formikField === "updatedCoolingSystem") {
    setInstallYear("coolingSystems[0][yearInstalled]", value, formik);
  }
  if (formikField === "updatedWaterHeater") {
    setInstallYear("waterHeater[yearInstalled]", value, formik);
  }
};

export function RadioGroupWithUnsure(props: {
  value: boolean;
  label: string;
  formik: FormikProps<PropertyProfileFormValues>;
  name: string;
}) {
  const { value, label, formik, name } = props;
  return (
    <Box sx={{ gridColumn: "span 3" }}>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        name={name}
        onChange={(e, value) => {
          setTogglesWithUnsureOption(value, formik, name);
        }}
        onBlur={formik.handleBlur}
        row
        value={value}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <FormControlLabel
          key={"yes"}
          value={true}
          control={<Radio />}
          label={"Yes"}
          sx={{ width: "fit-content" }}
        />
        <FormControlLabel
          key={"no"}
          value={false}
          control={<Radio />}
          label={"No"}
          sx={{ width: "fit-content" }}
        />
        <FormControlLabel
          key={"unsure"}
          value={""}
          control={<Radio />}
          label={"Unsure"}
          sx={{ width: "fit-content" }}
        />
      </RadioGroup>
    </Box>
  );
}
