import { useMutation } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { startCase } from "lodash";
import {
  NextStepsQuery,
  SavePhotosDocument,
  SavePhotosMutation,
  SaveVideosDocument,
  SaveVideosMutation,
} from "../../generated/graphql";

interface UploadTypes {
  video: string;
  photo: string;
}

export const clientAllowedVideoFormats = ["mp4", "movie"];
export const clientAllowedPhotoFormats = [
  "jpg",
  "jpeg",
  "png",
  "HEIC",
  "pdf",
  "svg",
];
export const allowedMediaUploadSource = [
  "local",
  "url",
  "camera",
  "google_drive",
  "dropbox",
];

interface CloudinaryResult {
  event: string;
  info: {
    format: string;
    secure_url: string;
  };
}

export function CloudinaryUploadWidget(props: {
  visit: NextStepsQuery["visit"];
  uploadType: keyof UploadTypes;
  media: string[];
  handleSave: (mediaArray: string[]) => void;
}) {
  const { visit, uploadType, media, handleSave } = props;
  const [savePhotos] = useMutation<SavePhotosMutation>(SavePhotosDocument);
  const [saveVideos] = useMutation<SaveVideosMutation>(SaveVideosDocument);
  const cloudName = "dmrrmx9dc";
  const uploadPreset = "fyirowdi";
  const customer = visit?.customer;
  const clientAllowedFormats =
    uploadType === "photo"
      ? clientAllowedPhotoFormats
      : clientAllowedVideoFormats;
  const myWidget = (window as any)?.cloudinary?.createUploadWidget(
    {
      cloudName: cloudName,
      uploadPreset: uploadPreset,
      sources: allowedMediaUploadSource,
      folder: `${customer?.customerId}_${customer?.firstName}_${customer?.lastName}`,
      tags: [`CustomerId:${customer?.customerId}`, `VisitId:${visit?.visitId}`],
      clientAllowedFormats: clientAllowedFormats,
    },
    async (error: boolean, result: CloudinaryResult) => {
      if (!error && result && result?.event === "success") {
        if (!clientAllowedVideoFormats.includes(result.info.format)) {
          handleSave([...media, result.info.secure_url]);
          await savePhotos({
            variables: {
              input: [
                {
                  cloudinaryUrl: result.info.secure_url,
                  visitId: visit.visitId,
                },
              ],
            },
          });
        } else {
          handleSave([...media, result.info.secure_url]);
          await saveVideos({
            variables: {
              input: [
                {
                  cloudinaryUrl: result.info.secure_url,
                  visitId: visit.visitId,
                },
              ],
            },
          });
        }
      }
    }
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <Button
        id="uploadWidget"
        className="cloudinary-button"
        color="info"
        onClick={myWidget.open}
        sx={{ width: 130, height: "fit-content" }}
      >
        Select {startCase(uploadType)}
      </Button>
    </Box>
  );
}
